import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Const from "../common/constant";
import CryptoJS from "crypto-js";

const styles = (theme) => ({});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const getDate = (date) => {
  return moment(date).format("DD-MMM-YYYY");
};

const getTotalDaysOfLeave = (fDate, tDate) => {
  let fromDate = moment(fDate);
  let toDate = moment(tDate);
  return moment.duration(toDate.diff(fromDate)).asDays();
};

const getleavesCount = async (empCode) => {
  let body = {
    OrganizationID: JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).OrganizationID,
    EmpCode: empCode,
  };
  const response = await fetch(Const.API_ROOT + Const.GET_LEAVES_COUNT, {
    method: "POST",
    headers: Const.API_HEADER,
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();
  const leaveCount = responseJson.map((data) => {
    const allleaves = data.FullDayLeaves + data.HalfDayLeaves / 2;
    return allleaves;
  });
  const leave = leaveCount[0];
  return leave;
};

function leavesDetailsModal(props) {
  const { classes, modalContent } = props;
  const [leaveCount, setLeaveCount] = useState(null);

  useEffect(() => {
    async function fetchLeavesCount() {
      const count = await getleavesCount(props.modalContent.EmpCode);
      setLeaveCount(count);
    }
    fetchLeavesCount();
  }, [props.modalContent.EmpCode]);

  return (
    <div style={{ opacity: 0.5 }}>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Grid container>
          <Grid item xs={12} sm={10}>
            <BootstrapDialogTitle>LEAVE DETAIL</BootstrapDialogTitle>
          </Grid>
          <Grid item xs={12} sm={2}>
            <BootstrapDialogTitle>
              <IconButton
                edge="end"
                aria-label="close"
                onClick={props.onClose}
                sx={{
                  borderRadius: "10px",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </BootstrapDialogTitle>
          </Grid>
        </Grid>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.head}>
                <b>Applied By</b>: {modalContent.EmpCodeAppliedByName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.head2}>
                <b>Type Of Leave</b> : {modalContent.LeaveName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.head1}>
                <b>Dates</b> : {getDate(modalContent.FromDate)} <b>To</b>{" "}
                {getDate(modalContent.ToDate)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.head3}>
                <b>Applied To</b> : {modalContent.EmpCodeAppliedToName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.head1}>
                <b>Leaves Applied</b> :{" "}
                {modalContent.LeaveName === "Half Day"
                  ? 1 / 2
                  : getTotalDaysOfLeave(
                      modalContent.FromDate,
                      modalContent.ToDate
                    ) + 1}{" "}
                {modalContent.LeaveName === "Half Day" ||
                getTotalDaysOfLeave(
                  modalContent.FromDate,
                  modalContent.ToDate
                ) +
                  1 ===
                  1 ? (
                  <b>Day</b>
                ) : (
                  <b>Days</b>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.head3}>
                <b>Overall Leaves Taken</b> : {leaveCount}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.head5}>
                <b>Action Taken By</b> : {props.actionTakenByFunc(modalContent)}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ paddingTop: "50px" }}>
            <Typography className={classes.head4}>
              <b>Reason For Leave</b> :
            </Typography>
            <div style={{ paddingTop: "15px" }}>
              <Typography className={classes.content3}>
                {modalContent.Description}
              </Typography>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default withStyles(styles)(leavesDetailsModal);
