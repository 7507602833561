import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Avatar,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import imageUrl from '../images/logo-400.png'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '1320px',
    width: '100%'
  },
  header: {
    backgroundColor: "#FFFEFE",
    opacity: 0.9,
    "@media (max-width: 1050px)": {
      paddingLeft: 0,
    },
    display: 'flex'
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#000",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
    color: "#000"
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export default function Header(props) {
  const { ref1, ref2, ref3, ref4 } = props;
  const { header,menuButton, toolbar, drawerContainer, container } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  const handleClickref1 = () =>
    ref1.current && ref1.current.scrollIntoView({ behavior: 'smooth' });
  const handleClickref2 = () =>
    ref2.current && ref2.current.scrollIntoView({ behavior: 'smooth' });
  const handleClickref3 = () =>
    ref3.current && ref3.current.scrollIntoView({ behavior: 'smooth' });
  const handleClickref4 = () =>
    ref4.current && ref4.current.scrollIntoView({ behavior: 'smooth' });

  const headersData = [
    {
      label: 'Home',
      reference: handleClickref1,
    },
    {
      label: 'About Us',
      reference: handleClickref2,
    },
    {
      label: 'Business Outcomes',
      reference: handleClickref3,
    },
    {
      label: 'Features',
      reference: handleClickref4,
    },
  ];

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1050
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {protraqLogo}
        <div>{getMenuButtons()}</div>
        {loginButton}
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{

            color: "#000",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <Box display="flex" p={1} bgcolor="background.paper" width='100%'>
          <Box flexGrow={1}>
            {protraqLogo}
          </Box>
          <Box alignSelf="center">
            {loginButton}
          </Box>
        </Box>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, reference }) => {
      return (
        <Box>
          <Button
            {...{
              key: label,
              color: "inherit",
              className: menuButton,
            }}
            style={{
              marginLeft: 0,
            }}
            onClick={reference}
          >
            {label}
          </Button>
        </Box>
      );
    });
  };

  const protraqLogo = (
    <Avatar variant={"rounded"} alt="The image" src={imageUrl} style={{
      width: 120,
      height: 49,
    }} />
  )

  const loginButton = (
    <Button
      {...{
        key: 'login',
        to: '/login',
        component: RouterLink,
        variant: "contained",
      }}
      style={{ backgroundColor: '#00a1d4', color: '#FFF' }}
    >
      Login
    </Button>
  )
  const getMenuButtons = () => {
    return (
      <>
        {headersData.map(({ label, reference }) => {
          return (
            <Button
              {...{
                key: label,
                color: "inherit",
                className: menuButton,
              }}
              onClick={reference}
            >
              {label}
            </Button>
          )
        }
        )}

      </>
    );
  };

  return (
    <header>
      <AppBar className={header}>
        <Container className={container}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </header>
  );
}