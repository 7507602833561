import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Footer from "../../common/footer";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Const from "../../common/constant";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CryptoJS from "crypto-js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 25,
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  heading: {
    marginTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  bigAvatar: {
    marginLeft: 80,
    marginTop: 15,
    width: 140,
    height: 140,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
});
var controller;
var signal;

class AddProject extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      ProjectName: "",
      ProjectDescription: "",
      StartDate: "",
      EstimatedBudget: "",
      ConsumedBudget: "",
      EndDate: "",
      EstimatedHours: "",
      ConsumedHours: "",
      Status: "",
      StatusList: [],
      TimeSheetInterval: "",
      TimeSheetIntervalList: [],
      loader: true,
      tabValue: 0,
      SelectedEmployeeName: "",
      SelectedEmployeeId: "",
      filterValue: "",
      filterBy: "Name",
      AllEmployees: [],
      EmployeeList: [],
      StatusID: "",
      TimesheetIntervalID: "",
      projectForEdit: this.props.match.params.id,
      action: this.props.match.params.id ? "update" : "add",
      buttonText: this.props.match.params.id ? "Update Project" : "Add Project",
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      Manager: [],
      Team: [],
      TeamArrayWithHours: [],
    };
  }

  componentWillMount() {
    console.clear();
    if (this.state.projectForEdit) {
      this.props.headerTitle("Update project", 4, true);
      this.setDataForUpdate();
    } else {
      this.props.headerTitle("Add a new project", 4, true);
    }
    this.getAllEmployees();
    this.getProjectStatusList();
    this.getProjectTimesheetList();
    this.props.loader(false);
    this.setState({
      loader: false,
    });
  }

  getAllEmployees = () => {
    controller = new AbortController();
    signal = controller.signal;
    var body = {
      OrganizationID: this.state.OrganizationID,
    };
    fetch(Const.API_ROOT + Const.GET_USERS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.loader(false);
        this.setState({
          AllEmployees: responseJson.employees,
          EmployeeList: responseJson.employees,
        });
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  };
  getProjectStatusList = () => {
    controller = new AbortController();
    signal = controller.signal;
    var body = {
      OrganizationID: this.state.OrganizationID,
    };
    fetch(Const.API_ROOT + Const.GET_PROJECT_STATUS_LIST, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    })
      .then((response) => response.json())
      .then((resp) => {
        this.props.loader(false);
        this.setState({
          StatusList: resp,
        });
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  };

  getProjectTimesheetList = () => {
    controller = new AbortController();
    signal = controller.signal;
    var body = {
      OrganizationID: this.state.OrganizationID,
    };
    fetch(Const.API_ROOT + Const.GET_PROJECT_TIMESHEET_INTERVAL, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    })
      .then((response) => response.json())
      .then((resp) => {
        this.props.loader(false);
        this.setState({
          TimeSheetIntervalList: resp,
        });
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  };

  handleSubmit = (e) => {
    if (!this.state.StartDate) {
      NotificationManager.error("Error", "Please select Start Date");
      e.preventDefault();
      return false;
    }

    if (!this.state.EndDate) {
      NotificationManager.error("Error", "Please select End Date");
      e.preventDefault();
      return false;
    }

    if (this.state.Team.length == 0) {
      NotificationManager.error("Error", "Please select Team Members");
      e.preventDefault();
      return false;
    }

    if (this.state.ProjectName.length > 200) {
      NotificationManager.error(
        "Error",
        "Project name can be only 200 character long"
      );
      e.preventDefault();
      return false;
    }

    if (this.state.ProjectDescription.length > 500) {
      NotificationManager.error(
        "Error",
        "Project description can be only 500 character long"
      );
      e.preventDefault();
      return false;
    }
    this.props.loader(true);
    var body = {
      Name: this.state.ProjectName,
      Description: this.state.ProjectDescription,
      StartDate: this.state.StartDate,
      EndDate: this.state.EndDate,
      Status: this.state.StatusID,
      EstimatedBudget: this.state.EstimatedBudget
        ? parseInt(this.state.EstimatedBudget)
        : 0,
      EstimatedHours: this.state.EstimatedHours,
      TimesheetInterval: this.state.TimesheetIntervalID,
      OrganizationID: this.state.OrganizationID,
      Action: this.state.action,
      ProjectId: this.state.projectForEdit,
    };

    fetch(Const.API_ROOT + Const.ADD_NEW_PROJECT, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (this.state.projectForEdit) {
          this.handleProjectTeamData(this.state.projectForEdit);
        } else {
          const latestProjectId =
            responseJson.recordset[0].LatestInsertedProjectId;
          this.handleProjectTeamData(latestProjectId);
        }
        this.props.loader(false);
        if (responseJson.rowsAffected) {
          if (this.state.projectForEdit) {
            NotificationManager.success(
              "Project updated successfully",
              "Success",
              2000
            );
          } else {
            NotificationManager.success(
              "Project Added successfully",
              "Success",
              2000
            );
          }
          setTimeout(
            function () {
              this.props.history.push("/dashboard/projects");
            }.bind(this),
            2000
          );
        } else {
          NotificationManager.error("Error", "please try again later.");
        }
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
    e.preventDefault();
  };

  handleTabChange = (event, value) => {
    this.setState({
      tabValue: value,
      filterValue: "",
      AllEmployees: this.state.EmployeeList,
      Manager: this.state.Manager,
    });
  };

  handleHourlyRate = (hours, teamMemberEmpCode) => {
    this.state.TeamArrayWithHours.push({
      HourlyRate: parseInt(hours),
      EmpCode: teamMemberEmpCode,
    });
  };

  handleProjectTeamData = (projectId) => {
    var body = {
      ProjectId: parseInt(projectId),
      Managers: this.state.Manager,
      Team: this.state.Team,
      TeamArrayWithHours: this.state.TeamArrayWithHours,
      Action: this.state.action,
    };
    fetch(Const.API_ROOT + Const.ADD_PROJECT_TEAM_DATA, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response)
      .then((responseJson) => {})
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  };

  setDataForUpdate = () => {
    var body = {
      ProjectId: this.state.projectForEdit,
    };
    fetch(Const.API_ROOT + Const.GET_PROJECT_DETAILS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson = responseJson.projects[0];
        this.setState({
          ProjectName: responseJson.Name,
          ProjectDescription: responseJson.Description,
          StartDate: responseJson.StartDate.split("T")[0],
          EstimatedBudget: responseJson.EstimatedBudget,
          ConsumedBudget: responseJson.consumedBudget,
          EndDate: responseJson.EndDate.split("T")[0],
          EstimatedHours: responseJson.EstimatedHours,
          ConsumedHours: responseJson.consumedWork,
          StatusID: responseJson.Status,
          TimesheetIntervalID: responseJson.TimeSheetInterval,
          loader: false,
        });
        this.setProjectTeamData();
        this.props.loader(false);
      })
      .catch((error) => {
        this.props.loader(false);
        this.setState({
          loader: false,
        });
        console.error(error);
      });
  };

  setProjectTeamData = () => {
    var body = {
      ProjectId: this.state.projectForEdit,
    };
    fetch(Const.API_ROOT + Const.GET_PROJECT_TEAM_DATA, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          Manager: responseJson.filter((x) => x.IsManager == true),
          Team: responseJson.filter((x) => x.IsManager == false),
        });
      })
      .catch((error) => {
        this.props.loader(false);
        this.setState({
          loader: false,
        });
        console.error(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {!this.state.loader ? (
          <Paper className={classes.root}>
            <ValidatorForm
              className={classes.form}
              onSubmit={this.handleSubmit}
            >
              <Typography
                component="h1"
                variant="h6"
                className={classes.heading}
              >
                Project Title
              </Typography>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={7}>
                  <TextValidator
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      this.setState({ ProjectName: e.target.value })
                    }
                    name="projectName"
                    value={this.state.ProjectName}
                    validators={["required"]}
                    errorMessages={["Project Name is required"]}
                  />
                </Grid>
              </Grid>
              <Typography
                component="h1"
                variant="h6"
                className={classes.heading}
              >
                Description
              </Typography>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={10}>
                  <TextValidator
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      this.setState({ ProjectDescription: e.target.value })
                    }
                    name="projectDescription"
                    value={this.state.ProjectDescription}
                    validators={["required"]}
                    errorMessages={["Project Description is required"]}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={12} sm={4}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="date"
                      label="Start Date"
                      type="date"
                      value={this.state.StartDate}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        this.setState({ StartDate: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <TextValidator
                      margin="normal"
                      fullWidth
                      onChange={(e) =>
                        this.setState({ EstimatedBudget: e.target.value })
                      }
                      name="EstimatedBudget"
                      label="Estimated Budget"
                      value={this.state.EstimatedBudget}
                      validators={["required"]}
                      errorMessages={["Estimated Budget is required"]}
                    />
                  </FormControl>

                  <FormControl disabled margin="normal" fullWidth>
                    <InputLabel>Consumed Budget</InputLabel>
                    <Input value={this.state.ConsumedBudget} />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="date"
                      label="End Date"
                      type="date"
                      value={this.state.EndDate}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        this.setState({ EndDate: e.target.value })
                      }
                    />
                  </FormControl>

                  <FormControl margin="normal" fullWidth>
                    <TextValidator
                      margin="normal"
                      fullWidth
                      onChange={(e) =>
                        this.setState({ EstimatedHours: e.target.value })
                      }
                      name="EstimatedHours"
                      label="Estimated Hours"
                      value={this.state.EstimatedHours}
                      validators={["required"]}
                      errorMessages={["Estimated Hours is required"]}
                    />
                  </FormControl>
                  <FormControl disabled margin="normal" fullWidth>
                    <InputLabel>Consumed Hours</InputLabel>
                    <Input value={this.state.ConsumedHours} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <SelectValidator
                      fullWidth
                      style={{ marginBottom: "13px" }}
                      label="Status"
                      value={this.state.StatusID}
                      onChange={(e) =>
                        this.setState({
                          StatusID: e.target.value,
                        })
                      }
                      inputProps={{
                        name: "StatusID",
                        id: "StatusID",
                      }}
                      validators={["required"]}
                      errorMessages={["Status is required"]}
                    >
                      {this.state.StatusList.map((data) => {
                        return (
                          <MenuItem key={data.StatusID} value={data.StatusID}>
                            {data.StatusType}
                          </MenuItem>
                        );
                      })}
                    </SelectValidator>
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <SelectValidator
                      fullWidth
                      style={{ marginBottom: "13px" }}
                      value={this.state.TimesheetIntervalID}
                      label="Timesheet Generation Interval"
                      onChange={(e) =>
                        this.setState({
                          TimesheetIntervalID: e.target.value,
                        })
                      }
                      inputProps={{
                        name: "TimesheetIntervalID",
                        id: "TimesheetIntervalID",
                      }}
                      validators={["required"]}
                      errorMessages={["Time Interval is required"]}
                    >
                      {this.state.TimeSheetIntervalList.map((data) => {
                        return (
                          <MenuItem
                            key={data.TimesheetIntervalID}
                            value={data.TimesheetIntervalID}
                          >
                            {data.TimesheetType}
                          </MenuItem>
                        );
                      })}
                    </SelectValidator>
                  </FormControl>
                </Grid>
              </Grid>

              <div className={classes.root}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Managers" />
                    <Tab label="Teams" />
                  </Tabs>
                </AppBar>
                {this.state.tabValue === 0 ? (
                  <Paper className={classes.root}>
                    <Grid container item xs={12} sm={10} style={{ padding: 0 }}>
                      <Grid item xs={12} sm={10}>
                        <Autocomplete
                          multiple
                          id="tags-standard-manager"
                          value={this.state.Manager}
                          options={this.state.AllEmployees}
                          getOptionLabel={(option) => option.Name}
                          onChange={(event, newValue) => {
                            this.setState({ Manager: newValue });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Select Managers"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Paper className={classes.root}>
                    <Grid container item xs={12} sm={10} style={{ padding: 0 }}>
                      <Grid item xs={12} sm={10}>
                        <Autocomplete
                          multiple
                          id="tags-standard-team"
                          value={this.state.Team}
                          options={this.state.AllEmployees}
                          getOptionLabel={(option) => option.Name}
                          onChange={(event, newValue) => {
                            this.setState({ Team: newValue });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Select Team Members"
                            />
                          )}
                        />
                        <Table className={classes.table}>
                          <TableHead></TableHead>
                          <TableBody>
                            {this.state.Team.map((n) => {
                              return (
                                <TableRow key={n.EmployeeId}>
                                  <TableCell>{n.Name}</TableCell>
                                  <TableCell>
                                    {n.Currency ? n.Currency : "₹"}
                                    <TextField
                                      style={{ verticalAlign: "baseline" }}
                                      margin="normal"
                                      required
                                      defaultValue={n.HourlyRate}
                                      onBlur={(e) =>
                                        this.handleHourlyRate(
                                          e.target.value,
                                          n.EmpCode
                                        )
                                      }
                                      name="Hourly Rate"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </div>
              <Grid container spacing={5} className={classes.heading}>
                <Grid item xs={12} className={classes.paper1}>
                  <Button
                    onClick={() =>
                      this.props.history.push("/dashboard/projects")
                    }
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <i className={`${classes.leftIcon} material-icons`}>
                      arrow_back
                    </i>{" "}
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <i className={`${classes.leftIcon} material-icons`}>
                      {this.props.match.params.id ? "update" : "person_add"}
                    </i>{" "}
                    {this.state.buttonText}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
        ) : (
          ""
        )}
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(AddProject);
