import React from "react";
import { Link, HashRouter, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  withStyles,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
} from "@material-ui/core";
import { NotificationContainer } from "react-notifications";
import { io } from "socket.io-client";
import Const from "./components/common/constant";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import UserDashboard from "./components/dashboard";
import ProtectedRoutes from "./utils/routes/ProtectedRoutes";
import { routes } from "./utils/routes/Routes";
import NotificationMenu from "./components/notification";
import CryptoJS from "crypto-js";

const drawerWidth = 260;

var isMobile = window.innerWidth <= 1024;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  bigAvatar: {
    margin: 10,
    width: "70%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0097a7",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  active: {
    background: "#0097a7 !important",
  },
  activeIcon: {
    color: "#fff !important",
  },
  progress: {
    marginLeft: 530,
    marginTop: 300,
    position: "absolute",
  },
  userName: {
    marginRight: 10,
  },
  notificationIcon: {
    // marginRight: 10
    display: "flex",
    padding: "20px",
  },
  creditsArea: {
    bottom: 0,
    position: "absolute",
    fontWeight: 500,
    paddingLeft: 10,
  },
  hideCredits: {
    display: "none",
  },
  techbitLogo: {
    width: 35,
  },
  credits: {
    paddingTop: 8,
    position: "absolute",
    paddingLeft: 5,
  },
});
class Dashboard extends React.Component {
  state = {
    open: true,
    openLogoutDialog: false,
    RoleId: 0,
    curruntTab: "",
    backIcon: false,
    loader: false,
    socketHandler: "",
  };

  resize = () => {
    isMobile = window.innerWidth <= 1024;
    this.setState({ open: false });
  };
  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleOpen = () => {
    this.setState({ openLogoutDialog: true });
  };
  handleClose = () => {
    this.setState({ openLogoutDialog: false });
  };
  Transition(props) {
    return <Slide direction="up" {...props} />;
  }
  handleLogout = () => {
    localStorage.removeItem("Item");
    localStorage.removeItem("SelectedEmployeeIdForCart");
    localStorage.removeItem("SelectedEmployeeNameForCart");
    this.props.history.push("/");

    this.state.socketHandler.on("disconnect", function () {});
    this.state.socketHandler.disconnect();
  };

  componentWillMount() {
    let item = CryptoJS.AES.decrypt(
      localStorage.getItem("Item"),
      process.env.REACT_APP_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
    if (!item) {
      this.props.history.push("/");
      return false;
    }
    const socket = io.connect(Const.API_ROOT);
    socket.emit("join", {
      EmpCode: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).EmpCode,
    });

    /**
     * Remember to add function(date) {}
     * Remember to add a socket emit-on combination which has an interval set to it
     * */

    socket.on("SocketListening", function (data) {});

    this.setState({
      RoleId: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).RoleId,
      userName: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).Name,
      socketHandler: socket,
    });
  }
  headerTitle(title, activeTab, backIcon) {
    this.setState({
      curruntTab: title,
      activeTab: activeTab,
      backIcon: backIcon,
    });
  }
  loader(action) {
    this.setState({
      loader: action,
    });
  }
  goBack() {
    this.props.history.goBack();
  }

  check(array) {
    return array.includes(JSON.stringify(this.state.RoleId));
  }
  routePath(index, path) {
    if (index === 5) {
      return JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).RoleId === 2
        ? "/dashboard/time-sheet-details"
        : path;
    } else if (index === 7) {
      return JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).RoleId === 1
        ? "/dashboard/attendance"
        : path;
    } else return path;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                this.state.open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="Back"
              onClick={this.goBack.bind(this)}
              className={classNames(
                !this.state.backIcon && classes.menuButtonHidden
              )}
            >
              <ArrowBack />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {this.state.curruntTab}
            </Typography>
            <NotificationMenu socketHandler={this.state.socketHandler} />
            <Typography
              component="h3"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.userName}
            >
              {this.state.userName}
            </Typography>
            <div onClick={this.handleOpen}>
              <IconButton color="inherit">
                <i className="material-icons">power_settings_new</i>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Dialog
          open={this.state.openLogoutDialog}
          onClose={this.handleClose}
          TransitionComponent={this.Transition}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Logout!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleLogout} color="primary" autoFocus>
              Logout
            </Button>
          </DialogActions>
        </Dialog>
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose
            ),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <img
              src={require("../src/components/images/logo-400.png")}
              className={classes.bigAvatar}
              alt=""
            />
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {routes.map((data, index) => {
              if (this.check(data.authorisedUsers)) {
                if (data.id) {
                  return (
                    <ListItem
                      style={{ marginLeft: data.name == "ToDo" ? "3px" : "" }}
                      key={data.name}
                      button
                      component={Link}
                      to={this.routePath(index, data.path)}
                      className={
                        this.state.activeTab === data.id ? classes.active : ""
                      }
                    >
                      <ListItemIcon>
                        <i
                          style={{
                            fontSize: data.name == "ToDo" ? "20px" : "",
                          }}
                          className={
                            this.state.activeTab === data.id
                              ? `${classes.activeIcon} material-icons`
                              : "material-icons"
                          }
                          aria-hidden="true"
                        >
                          {data.icon}
                        </i>
                      </ListItemIcon>
                      <ListItemText
                        classes={{
                          primary:
                            this.state.activeTab === data.id
                              ? classes.activeIcon
                              : "",
                        }}
                        primary={data.name}
                      />
                    </ListItem>
                  );
                }
              }
            })}
          </List>

          <div
            className={classNames(
              this.state.open && classes.creditsArea,
              !this.state.open && classes.hideCredits
            )}
          ></div>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.tableContainer}>
            {this.state.loader ? (
              <CircularProgress className={classes.progress} />
            ) : (
              ""
            )}
            <HashRouter>
              <Switch>
                <Route
                  exact
                  path="/dashboard"
                  render={(props) => (
                    <UserDashboard
                      loader={this.loader.bind(this)}
                      headerTitle={this.headerTitle.bind(this)}
                      {...props}
                    />
                  )}
                />
                {routes.map((item) => (
                  <ProtectedRoutes
                    path={item.path}
                    Cmp={item.comp}
                    exact={item.exact}
                    protectedRoute={item.protectedRoute}
                    RoleId={this.state.RoleId}
                    loader={this.loader.bind(this)}
                    headerTitle={this.headerTitle.bind(this)}
                  />
                ))}
              </Switch>
            </HashRouter>
          </div>
        </main>
        <NotificationContainer />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
