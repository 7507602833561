import React, { Component } from "react";
import Const from "../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CardActions from "@material-ui/core/CardActions";
import Footer from "../common/footer";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";
import CryptoJS from "crypto-js";
import $ from "jquery";
var controller;
var signal;
var moment = require("moment");
const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },

  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  action: {
    cursor: "pointer",
    margin: 2,
  },
  deleteAction: {
    color: "#666",
  },
  editAction: {
    color: "#666",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  button: {
    backgroundColor: "#fb7813 !important",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  di: {
    display: "inline",
    float: "left",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: "100%",
    // marginTop: 16
  },
  EmployeesFormControl176: {
    marginTop: 100,
  },
  sortableIcon: {
    position: "relative",
    paddingRight: "30px !important",
  },
  sortableIconContiner: {
    margin: 0,
    //position: 'absolute',
    top: "50%",
    display: "inline-block",
    //right: 0,
    cursor: "pointer",
  },
  sortableIconItem: {
    fontWeight: "bold",
    fontSize: 15,
    verticalAlign: "middle",
  },

  paddingRight10: {
    paddingRight: 10,
  },
  sortingDetail: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  textLeft: {
    textAlign: "left",
  },
  marginLeft20: {
    marginLeft: 20,
  },
  custFlex: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  inlineSpan: {
    display: "inline-block",
    width: "32%",
    marginRight: "1%",
    textAlign: "center",
    verticalAlign: "top",
    padding: 5,
    fontSize: 13,
  },
  seprator: {
    textAlign: "center",
    margin: "15px 0",
    position: "relative",
  },
  sepratorSpan: {
    color: "#fff",
    background: "#fb7813",
    padding: " 0px 10px",
    borderRadius: "8px",
    height: "25px",
    display: "inline-block",
    lineHeight: "24px",
    position: "relative",
    zIndex: 1,
    fontSize: 14,
    marginLeft: "auto",
    marginRight: "auto",
  },
  sepratorBorder: {
    background: "#fb7813",

    position: "absolute",
    height: "1px",
    left: "15px",
    right: "15px",
    top: "50%",
  },
  positionR: { position: "relative" },
  alignItemsCenter: { alignItems: "center" },
  avatarImage: { width: "100% !important", height: "auto !important" },
  customCard: { height: "auto", minHeight: 150 },
  proName: {
    fontSize: 16,
    color: "#3b6978",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "16px",
    textAlign: "center",
  },
  statusStyle: { fontSize: 16, textAlign: "left" },
  estimated: {
    fontSize: 14,
    color: "#3b6978",
    fontWeight: "bold",
  },
  consumed: {
    fontSize: 14,
    color: "#3b6978",
    fontWeight: "bold",
  },
  seprator: {
    textAlign: "center",
    margin: "15px 0",
    position: "relative",
  },
  sepratorSpan: {
    color: "#fff",
    background: "#fb7813",
    padding: " 0px 10px",
    borderRadius: "8px",
    height: "25px",
    display: "inline-block",
    lineHeight: "24px",
    position: "relative",
    zIndex: 1,
    fontSize: 14,
    marginLeft: "auto",
    marginRight: "auto",
  },
  sepratorBorder: {
    background: "#fb7813",
    height: "1px",
    display: "block",
    marginTop: "-12px",
  },
  nm: { margin: "0 !important" },
  values: { position: "relative", marginTop: "10px" },
  custCardAction: {
    padding: "0 !important",
    borderTop: "1px solid #fb7813",
  },
  custCardActionButton: {
    width: "50% !important",
    borderRadius: 0,
    margin: 0,
    color: "#444",
  },
  rightSepratorContainer: {
    position: "relative",
    display: "inline-block",
    verticalAlign: "middle",
  },
  rightSeprator: {
    position: "absolute",
    width: 2,
    height: 50,
    background: "#c0c0c0",
    top: "50%",
    transform: "translateY(-50%)",
  },
  valueTitle: { fontWeight: 500 },
  valueData: {
    // maxWidth: 100,
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap'
  },
  selectorElement: { position: "relative" },
  bgroup: { position: "absolute", top: 5, left: 10, fontSize: 15 },
  heightFix: { minHeight: 200, position: "relative" },
  bgroupImg: {
    width: 10,
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: 5,
  },
  bgroupData: {
    display: "inline-block",
    verticalAlign: "middle",
    fontSize: 14,
  },
  valueData: {
    color: "#6f6f6f",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
});
const selectList = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "To be Started",
  },
  {
    id: 3,
    name: "In Progress",
  },
  {
    id: 4,
    name: "On Hold",
  },
  {
    id: 5,
    name: "Completed",
  },
];
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllProjects: [],
      Projects: [],
      columns: [],
      labelWidth: 0,
      filterBy: "",
      filterByStatus: "All",
      filterValue: "",
      sortBy: "",
      sortOrder: "asc",
      sortIcon: "arrow_downward",
      confirmDeleteDialog: false,
      projectIdForDelete: "",
      listView: false,
      currency: "",
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      roleId: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).RoleId,
    };
  }
  componentWillUnmount() {
    controller.abort();
  }

  componentWillMount() {
    controller = new AbortController();
    signal = controller.signal;
    this.props.loader(true);

    this.props.headerTitle("Project List", 3);
    const code = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).EmpCode;
    let endPoints;
    var body;
    if (this.state.roleId === 1) {
      body = {
        OrganizationID: this.state.OrganizationID,
      };
      endPoints = Const.GET_PROJECTS;
    } else {
      body = {
        EmpCode: code,
      };
      endPoints = Const.GET_USER_PROJECTS;
    }
    setTimeout(
      function () {
        console.clear();
        fetch(Const.API_ROOT + endPoints, {
          method: "POST",
          headers: Const.API_HEADER,
          body: JSON.stringify(body),
          signal: signal,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson.projects);
            console.log(responseJson.defaultColumns);
            this.props.loader(false);
            this.setState({
              Projects: responseJson.projects,
              AllProjects: responseJson.projects,
              columns: responseJson.defaultColumns,
              filterBy: responseJson.defaultColumns[0].ColumnName,
              sortBy: responseJson.defaultColumns[0].ColumnName,
            });
          })
          .catch((error) => {
            this.props.loader(false);
            console.error(error);
          });
      }.bind(this),
      1000
    );
    this.getCurrency();
  }
  getCurrency() {
    var body = {
      OrganizationID: this.state.OrganizationID,
    };
    fetch(Const.API_ROOT + Const.GET_CURRENCY, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          currency: responseJson[0].Currency,
        });
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  }

  Transition(props) {
    return <Slide direction="up" {...props} />;
  }
  openProjectToDos = (projectId) => {
    this.props.history.push("/dashboard/todos/" + projectId);
  };
  editProject = (projectId) => {
    this.props.history.push("/dashboard/add-project/" + projectId);
  };
  addProject() {
    this.props.history.push("/dashboard/add-project");
  }
  getDate(date) {
    return moment(date).format("MM/DD/YYYY");
  }
  handleFilterByChange = (event) => {
    this.setState({
      filterByStatus: event.target.value,
    });
    if (
      this.state.filterValue != "" &&
      event.target.value != "" &&
      this.state.filterBy !== ""
    )
      this.filterAllRecords(this.state.filterValue, event.target.value);
    else {
      if (event.target.value !== "") {
        this.filterOnlyStatus(event.target.value);
      }
    }
  };
  filterOnlyStatus(value) {
    var val = value;
    var filteredRecords;
    if (val) {
      if (val == "To be Started") {
        filteredRecords = this.state.AllProjects.filter(
          (project) => project.Status == 1
        );
      } else if (val == "In Progress") {
        filteredRecords = this.state.AllProjects.filter(
          (project) => project.Status == 2
        );
      } else if (val == "On Hold") {
        filteredRecords = this.state.AllProjects.filter(
          (project) => project.Status == 3
        );
      } else if (val == "Completed") {
        filteredRecords = this.state.AllProjects.filter(
          (project) => project.Status == 4
        );
      } else {
        filteredRecords = this.state.AllProjects;
      }
    }

    this.setState({
      Projects: filteredRecords,
      filterByStatus: value,
    });
  }
  handlefilterResults(e) {
    this.setState({
      filterValue: e.target.value,
    });
    if (
      this.state.filterBy !== "" &&
      e.target.value != "" &&
      this.state.filterByStatus != ""
    ) {
      this.filterAllRecords(e.target.value, this.state.filterByStatus);
    } else if (this.state.filterBy != "" && e.target.value != "") {
      this.filterRecords(this.state.filterBy, e.target.value);
    } else if (this.state.filterByStatus != "") {
      this.filterOnlyStatus(this.state.filterByStatus);
    }
  }

  filterRecords = (filterBy, filterValue) => {
    var filteredRecords = this.state.AllProjects.filter(function (emp) {
      var val = emp[filterBy];
      if (val) {
        if (val.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) {
          emp.name = "abc";
          return emp;
        }
      }
      return null;
    });
    this.setState({
      Projects: filteredRecords,
      filterBy: filterBy,
      filterValue: filterValue,
    });
  };

  filterAllRecords = (filterValue, filterByStatus) => {
    var filteredRecords;
    if (filterByStatus) {
      if (filterByStatus == "To be Started") {
        filteredRecords = this.state.AllProjects.filter(
          (project) =>
            project.Status == 1 &&
            project.Name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
      } else if (filterByStatus == "In Progress") {
        filteredRecords = this.state.AllProjects.filter(
          (project) =>
            project.Status == 2 &&
            project.Name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
      } else if (filterByStatus == "On Hold") {
        filteredRecords = this.state.AllProjects.filter(
          (project) =>
            project.Status == 3 &&
            project.Name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
      } else if (filterByStatus == "Completed") {
        filteredRecords = this.state.AllProjects.filter(
          (project) =>
            project.Status == 4 &&
            project.Name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
      } else if (filterByStatus == "All") {
        filteredRecords = this.state.AllProjects.filter(
          (project) =>
            project.Name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
        );
      }
    }
    this.setState({
      Projects: filteredRecords,
      filterByStatus: filterByStatus,
      filterValue: filterValue,
    });
  };

  handleSortByChange = (event) => {
    this.setState({
      sortBy: event.target.value,
    });
    if (this.state.sortValue !== "")
      this.sortRecords(event.target.value, this.state.sortOrder);
  };

  checkValidDate = (val) => {
    var timestamp = Date.parse("foo");

    if (isNaN(timestamp) === false) {
      return true;
    } else return false;
  };
  checkData = (data, column) => {
    var val = data[column];
    if (column === "ConsumedHours") {
      if (
        typeof data.consumedWork !== "undefined" &&
        data.consumedWork !== null &&
        data.consumedWork !== 0
      ) {
        return data.consumedWork.toFixed(4);
      } else {
        return 0;
      }
    } else if (column === "ConsumedBudget") {
      if (
        typeof data.consumedBudget !== "undefined" &&
        data.consumedBudget !== null &&
        data.consumedBudget !== 0
      ) {
        return data.consumedBudget.toFixed(4);
      } else {
        return 0;
      }
    } else {
      if (!val) {
        return "N/A";
      } else if (column === "Status") {
        if (val == 1) {
          return "To be started";
        } else if (val == 2) {
          return "In Progress";
        } else if (val == 3) {
          return "On Hold";
        } else if (val == 4) {
          return "Completed";
        }
      } else return val;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} className={classes.textLeft}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} className={classes.textLeft}>
                  <FormControl margin="normal">
                    <InputLabel>Search a Project </InputLabel>
                    <Input
                      id="filterValue"
                      name="filterValue"
                      autoComplete="filterValue"
                      value={this.state.filterValue}
                      onChange={(e) => this.handlefilterResults(e)}
                      placeholder="Search"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={3} className={classes.textLeft}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel htmlFor="filter-by-simple"></InputLabel>
                    <Select
                      value={this.state.filterByStatus}
                      onChange={this.handleFilterByChange}
                      input={
                        <FilledInput name="filter-by" id="filter-by-simple" />
                      }
                      className={classes.textLeft}
                    >
                      {selectList.map((col, ind) => {
                        return (
                          <MenuItem value={col.name} key={ind}>
                            {col.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.custFlex}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.listView}
                    onChange={(e) =>
                      this.setState({
                        listView: !this.state.listView,
                      })
                    }
                    value={this.state.listView}
                  />
                }
                label={!this.state.listView ? "Tiles View" : "List View"}
              />
              {this.state.roleId === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.addProject()}
                >
                  <i className={`${classes.leftIcon} material-icons `}>web</i> +
                  Add Project
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
        {this.state.listView ? (
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.state.columns.map((col, i) => {
                    return (
                      <TableCell align="center" key={i}>
                        {col.ColumnName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.Projects.map((n) => {
                  return (
                    <TableRow
                      className={classes.tableRowHover}
                      key={n.ProjectId}
                    >
                      {this.state.columns.map((col, i) => {
                        return (
                          <TableCell align="center" key={i}>
                            {this.checkData(n, col.ColumnName)}
                          </TableCell>
                        );
                      })}
                      {this.state.roleId === 1 && (
                        <TableCell align="center">
                          <span
                            className={classes.action}
                            onClick={() => this.editProject(n.ProjectId)}
                          >
                            <i
                              className={`${classes.editAction} material-icons`}
                              aria-hidden="true"
                            >
                              edit
                            </i>
                          </span>
                          <span
                            className={classes.action}
                            onClick={() => this.openProjectToDos(n.ProjectId)}
                          >
                            <i
                              className={`${classes.deleteAction} material-icons`}
                              /*onClick={() => this.confirmDelete(n.EmployeeId)}*/ aria-hidden="true"
                            >
                              grading
                            </i>
                          </span>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Grid container spacing={2}>
            {this.state.Projects.map((data, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={i}
                  className={classes.selectorElement}
                >
                  <Card
                    className={`${classes.card}  ${classes.heightFix}  ${classes.cardContainer}`}
                  >
                    <CardContent>
                      <Grid container className={classes.alignItemsCenter}>
                        <div className={classes.bgroup}>
                          {data.Status == 1 ? (
                            <span style={{ color: "#F6AA0A" }}>
                              To be Started
                            </span>
                          ) : data.Status == 2 ? (
                            <span style={{ color: "#07B430" }}>
                              In Progress
                            </span>
                          ) : data.Status == 3 ? (
                            <span style={{ color: "#FF0C01" }}>On Hold</span>
                          ) : data.Status == 4 ? (
                            <span style={{ color: "#3b6978" }}>Completed</span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div style={{ padding: 5, width: "100%" }}>
                          <Typography
                            variant="title"
                            component="h2"
                            className={classes.proName}
                          >
                            {data.Name}
                          </Typography>
                          <div className={classes.seprator}>
                            <span className={classes.sepratorSpan}>
                              Hours Vs Budget
                            </span>
                            <span className={classes.sepratorBorder}></span>
                          </div>
                        </div>
                        <div style={{ margin: "auto" }}>
                          <div>
                            <Typography
                              variant="caption"
                              className={classes.estimated}
                              color="textSecondary"
                            >
                              Estimated: {data.EstimatedHours}hours |{" "}
                              {this.state.currency}
                              {data.EstimatedBudget}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              variant="caption"
                              className={classes.consumed}
                              color="textSecondary"
                            >
                              Consumed:{" "}
                              <span
                                style={{
                                  color:
                                    data.EstimatedHours < data.consumedWork
                                      ? "#F73A2A"
                                      : data.Status == 4
                                      ? "#07B430"
                                      : "#F6BC12",
                                }}
                              >
                                {typeof data.consumedWork !== "undefined" &&
                                data.consumedWork !== null &&
                                data.consumedWork !== 0
                                  ? data.consumedWork.toFixed(4)
                                  : 0}{" "}
                                hours
                              </span>{" "}
                              |
                              <span
                                style={{
                                  marginLeft: "2px",
                                  color:
                                    data.EstimatedBudget < data.consumedBudget
                                      ? "#F73A2A"
                                      : data.Status == 4
                                      ? "#07B430"
                                      : "#F6BC12",
                                }}
                              >
                                {" "}
                                {this.state.currency}
                                {typeof data.consumedBudget !== "undefined" &&
                                data.consumedBudget !== null &&
                                data.consumedBudget !== 0
                                  ? data.consumedBudget.toFixed(4)
                                  : 0}{" "}
                              </span>
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </CardContent>
                    {this.state.roleId === 1 && (
                      <CardActions className={classes.custCardAction}>
                        <Button
                          className={classes.custCardActionButton}
                          size="small"
                          color="primary"
                          onClick={() => this.editProject(data.ProjectId)}
                        >
                          <i
                            className={`${classes.editAction} material-icons`}
                            aria-hidden="true"
                          >
                            edit
                          </i>
                          &nbsp;Edit
                        </Button>
                        <Button
                          className={classes.custCardActionButton}
                          size="small"
                          color="primary"
                          onClick={() => this.openProjectToDos(data.ProjectId)}
                        >
                          <i
                            className={`${classes.deleteAction} material-icons`}
                            aria-hidden="true"
                          >
                            grading
                          </i>
                          &nbsp;ToDos
                        </Button>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Footer />
      </div>
    );
  }
}
export default withStyles(styles)(Projects);
