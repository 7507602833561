import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Pdf from "react-to-pdf";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
var moment = require("moment");

const styles = (theme) => ({
  salaryTable: {
    border: "1px solid #ccc",
    margin: "0px 10px 10px 10px",
    overflow: "unset",
    padding: "10px",
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
  customDialogWidth: {
    maxWidth: "790px",
    margin: "auto",
  },
  iconClose: {
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
});

function MyDocument(props) {
  const { classes, modalContent ,year ,month} = props;
  const ref = React.createRef();
  const options = {
    unit: "px",
  };

 
  const workingDays  =()=>{
    if (modalContent && month && year){
      let daysInMonth = moment(`${year}-${month}`, "YYYY-MMMM").daysInMonth()
      let workingDaysInMonth =  daysInMonth - modalContent.NumberOfLeaves
      return workingDaysInMonth
    }
  return 0
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 20px",
            }}
          >
            <Pdf
              targetRef={ref}
              filename="SalarySheet.pdf"
              options={options}
            >
              {({ toPdf }) => (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={toPdf}
                  className={classes.button}
                >
                  Download Slip
                </Button>
              )}
            </Pdf>
            <IconButton
              edge="end"
              aria-label="close"
              onClick={props.onClose}
              sx={{
                borderRadius: "10px",
                color: (theme) => theme.palette.grey[500],
              }}
              style={{ padding: "0px 10px 0px 0px" }}
              className={classes.iconClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div ref={ref} className={classes.customDialogWidth}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <h2 style={{ textAlign: "center" }}>SALARY SLIP</h2>
              </Grid>
            </Grid>
            <Grid spacing={5}>
              <div
                style={{
                  border: "1px solid #ccc",
                  margin: "10px",
                  padding: "16px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../../Logo-dark.png")}
                  style={{
                    width: "180px",
                    marginRight: "40px",
                    marginLeft: "60px",
                  }}
                ></img>
                <div>
                  <p style={{ margin: "0", fontSize: "30px" }}>
                    Techbit Solutions Pvt. Ltd.
                  </p>
                  <h4 style={{ margin: "0" }}>
                    Pay Slip for the period of {month} {year}
                  </h4>
                </div>
              </div>
              <div className={classes.salaryTable} style={{ display: "flex" }}>
                <div style={{ flex: "1" }}>
                  <div style={{ display: "flex", padding: "0 10px" }}>
                    <h5 style={{ margin: "0", flex: "1" }}>Employee ID : </h5>
                    <p style={{ margin: "0" }}>
                      {modalContent && modalContent.EmployeeID}
                    </p>
                  </div>
                  <div style={{ display: "flex", padding: "0 10px" }}>
                    <h5 style={{ margin: "0", flex: "1" }}>Name : </h5>
                    <p style={{ margin: "0" }}>
                      {modalContent && modalContent.EmpName}
                    </p>
                  </div>
                  <div style={{ display: "flex", padding: "0 10px" }}>
                    <h5 style={{ margin: "0", flex: "1" }}>Department : </h5>
                    <p style={{ margin: "0" }}>
                      {modalContent && modalContent.DepartmentName}
                    </p>
                  </div>
                </div>
                <div style={{ flex: "1" }}>
                  <div style={{ display: "flex", padding: "0 10px" }}>
                    <h5 style={{ margin: "0", flex: "1" }}>Designation : </h5>
                    <p style={{ margin: "0" }}>
                      {modalContent && modalContent.DesignationName}
                    </p>
                  </div>
                  <div style={{ display: "flex", padding: "0 10px" }}>
                    <h5 style={{ margin: "0", flex: "1" }}>
                      Number of Leaves :{" "}
                    </h5>
                    <p style={{ margin: "0" }}>
                      {modalContent && modalContent.NumberOfLeaves}
                    </p>
                  </div>
                  <div style={{ display: "flex", padding: "0 10px" }}>
                    <h5 style={{ margin: "0", flex: "1" }}>Working Days : </h5>
                    <p style={{ margin: "0" }}>{workingDays()}</p>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", marginBottom: "30px" }}>
                <div
                  style={{
                    flex: "1",
                    border: "1px solid #ccc",
                    margin: "10px 0 10px 10px",
                  }}
                >
                  <div style={{ borderBottom: "1px solid #ccc" }}>
                    <div style={{ display: "flex", padding: "10px" }}>
                      <h3 style={{ margin: "0", flex: "1" }}>Allowances</h3>
                      <h3 style={{ margin: "0" }}>Amount</h3>
                    </div>
                  </div>
                  <div style={{ padding: "10px", height: "188px" }}>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>Basic</p>
                      <p style={{ margin: "0" }}>
                        {modalContent && modalContent.BasicSalary}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>HRA</p>
                      <p style={{ margin: "0" }}>
                        {modalContent && modalContent.HRA}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>GA</p>
                      <p style={{ margin: "0" }}>
                        {modalContent && modalContent.GA}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>Other Allowance</p>
                      <p style={{ margin: "0" }}>
                        {modalContent && modalContent.OtherAllowance}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>Bonus</p>
                      <p style={{ margin: "0" }}>0</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>
                        Any Adjustment (+)
                      </p>
                      <p style={{ margin: "0" }}>0</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>
                        Night Shift Allowance
                      </p>
                      <p style={{ margin: "0" }}>
                        {modalContent && modalContent.NightShiftAllowance}
                      </p>
                    </div>
                  </div>
                  <div style={{ borderTop: "1px solid #ccc" }}>
                    <div style={{ display: "flex", padding: "10px" }}>
                      <h3 style={{ margin: "0", flex: "1" }}>Gross Salary</h3>
                      <h3 style={{ margin: "0" }}>{modalContent && modalContent.ActualSalary}</h3>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: "1",
                    border: "1px solid #ccc",
                    margin: "10px 10px 10px 0px",
                  }}
                >
                  <div style={{ borderBottom: "1px solid #ccc" }}>
                    <div style={{ display: "flex", padding: "10px" }}>
                      <h3 style={{ margin: "0", flex: "1" }}>Deductions</h3>
                      <h3 style={{ margin: "0" }}>Amount</h3>
                    </div>
                  </div>
                  <div style={{ padding: "10px", height: "188px" }}>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>ESI (Employee Con)</p>
                      <p style={{ margin: "0" }}>{modalContent && modalContent.ESIEmployeePart}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>ESI (Employer Con)</p>
                      <p style={{ margin: "0" }}>{modalContent && modalContent.ESIEmployerPart}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>PF (Employee Con)</p>
                      <p style={{ margin: "0" }}>{modalContent && modalContent.EPFEmployeePart}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>PF (Employer Con)</p>
                      <p style={{ margin: "0" }}>{modalContent && modalContent.EPFEmployerPart}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>Professional Tax</p>
                      <p style={{ margin: "0" }}>
                        {modalContent && modalContent.ProfessionalTax}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>TDS or Tax</p>
                      <p style={{ margin: "0" }}>
                        {modalContent && modalContent.TDS}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p style={{ margin: "0", flex: "1" }}>Leaves Deduction</p>
                      <p style={{ margin: "0" }}>
                        {modalContent && modalContent.LeaveDeductionAmount}
                      </p>
                    </div>
                  </div>
                  <div style={{ borderTop: "1px solid #ccc" }}>
                    <div style={{ display: "flex", padding: "10px" }}>
                    <h3 style={{ margin: "0", flex: "1" }}>Net Salary</h3>
                      <h3 style={{ margin: "0" }}>{modalContent && modalContent.GrossSalary}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", padding: "20px" }}>
                <div style={{ flex: "1" }}>
                  <div>Employer Signature</div>
                </div>
                <div style={{ flex: "1" }}>
                  <div>Employee Signature</div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #ccc",
                  margin: "10px",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <div>
                  <p style={{ fontStyle: "italic", margin: "auto" }}>
                    C-203, Phase 8-B, Industrial Area, Mohali, Punjab, India -
                    160055 | Phone : 0172-5099507 | Email :
                    contact@techbitsolution.com
                  </p>
                </div>
              </div>
              <div
                style={{ padding: "0px 10px 30px 10px", textAlign: "center" }}
              >
                <div>
                  <p style={{ fontStyle: "italic", margin: "auto" }}>
                    It’s a computer generated soft copy that doesn't require
                    signature or stamp
                  </p>
                </div>
              </div>
            </Grid>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default withStyles(styles)(MyDocument);
