import React, { useEffect, useState } from 'react';
import constant from '../common/constant';
import { withStyles } from "@material-ui/core/styles";

import {
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@material-ui/core";
import { NotificationManager } from 'react-notifications';

const styles = (theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
            cursor: 'pointer',
        },
    },
});

function CompanyPolicy(props) {
    const { classes, history } = props;
    const [companyPolicyList, setCompanyPolicyList] = useState([]);

    useEffect(() => {
        props.headerTitle("Company Policy", 13, true);
        getCompanyPolicy();
    }, []);

    const getCompanyPolicy = async () => {
        try {
            const companyPolicy = await fetch(constant.API_ROOT + constant.GET_COMPANY_POLICY, {
                method: 'GET',
                headers: constant.API_HEADER,
            });
            const companyPolicyData = await companyPolicy.json();
            if (companyPolicyData) {
                setCompanyPolicyList(companyPolicyData);
            }
        } catch (error) {
        }
    };



    const generateDownloadLink = (fileName) => {
        return `${constant.API_ROOT}${constant.DOWNLOAD_COMPANY_POLICY}?fileName=${encodeURIComponent(fileName)}`;
    };

    const handleLinkClick = async (fileName) => {
        const downloadLink = generateDownloadLink(fileName);
        try {
            const response = await fetch(downloadLink);
            if (!response.ok) {
                const errorData = await response.json();
                console.log(errorData)
                throw new Error(`${errorData.message}`);
            }
            window.open(downloadLink, '_blank');
        } catch (error) {
            console.log(error.message)
            NotificationManager.error(error.message);
            history.push('/dashboard/companyPolicy');
        }
    };

    return (
        <Paper style={{ padding: 25 }}>
            <Typography component="h1" variant="h5">
                Company Policy
            </Typography>
            <List>
                {companyPolicyList.map((data) => (
                    <ListItem key={data.id} style={{ borderBottom: "1px solid #ddd" }}>
                        <ListItemText>
                            <a
                                onClick={() => handleLinkClick(data.PolicyFileName)}
                                className={classes.link}
                            >
                                {data.PolicyFileName}
                            </a>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
}

export default withStyles(styles)(CompanyPolicy);