import React from 'react'
import {
  Box,
  Grid,
  makeStyles
} from '@material-ui/core';
import { SectionFourGrid } from './SectionsGrid';


import staticData from './static_data.json';

const useStyles = makeStyles(() => ({
  section_heading: {
    fontSize: "32px",
    color: '#000',
    margin: '2em 0'
  },
  section_four_heading: {
    width: '100%',
    textAlign: 'center',
  },
  section_four_grid: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '1320px',
  },
}))

const SectionFour = () => {
  const {
    section_heading,
    section_four_heading,
  } = useStyles();

  return (
    <Box style={{ paddingBottom: '8em' }}>
      <Grid container style={{ overflow: 'auto', display: 'block' }}>
        <h4 className={`${section_heading} ${section_four_heading}`}>
          {staticData.section_4.heading}
        </h4>
        <Box sx={{
          flexGrow: 1, 
          display: 'flex', 
          justifyContent: 'center', 
          maxWidth: '1320px',
          padding: '0 44px'
        }}>
          <Grid
            container
            spacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            {staticData.section_4.content.map((info, index) => (
              <Grid item xs={12} sm={12} md={4} key={index}>
                <SectionFourGrid info={info} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}

export default SectionFour