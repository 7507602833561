import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import HomePage from "./components/homePage";
import DashBoard from "./Dashboard";
import SignIn from "./SignIn";
import SessionTimeout from "./utils/SessionTimeout";

class App extends Component {
  render() {
    return (
      <>
        <SessionTimeout />
        <HashRouter>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/login" component={SignIn} />
            <Route path="/dashboard" component={DashBoard} />
          </Switch>
        </HashRouter>
      </>
    );
  }
}

export default App;
