const tableHeadRow = [
    "Sr. No.",
    "EmployeeID",
    "Employee Name",
    "Gross Salary",
    "Account No",
    "IFSC Code",
    "Actual Salary",
    "Basic",
    "HRA",
    "GA",
    "Other Allowance",
    "Number of Leaves",
    "Night Shift Allowance (+)",
    "Professional Tax",
    "TDS/Adj (-)",
    "ESI (3.25%)",
    "ESI (.75%)",
    "PF (Employer Con) - 12%",
    "PF (Employee Con) - 12%",
    "Emails",
    "Department",
    "Designation",
    "Leaves Deduction (Amt)"
];

export default tableHeadRow;
