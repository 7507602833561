const getMasterLeave = (masterLeaves) => {
  const currentYear = () => {
    return new Date().getFullYear();
  };
  let obj1 = masterLeaves.find((e) => {
    return e.Year === currentYear();
  });
  let obj2 = masterLeaves.find((e) => {
    return e.Year === currentYear() - 1;
  });
  if (!obj1) {
    if (!obj2) {
      return;
    }
    return obj2.NumberOfLeaves;
  } else {
    if (!obj1) {
      return;
    }
    return obj1.NumberOfLeaves;
  }
};

const calProbationEndDate = (joiningDate) => {
  let probationEndDate = new Date(joiningDate);
  let offset = probationEndDate.getDate() > 15 ? 4 : 3;
  if (probationEndDate.getDate() === 31) {
    probationEndDate.setDate(probationEndDate.getDate() - 1)
    probationEndDate.setMonth(probationEndDate.getMonth() + offset);
  }
  else {
    probationEndDate.setMonth(probationEndDate.getMonth() + offset);
  }
  return probationEndDate;
};

const calcInProb = (probationEndDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (probationEndDate < today) {
    return false;
  }
  if (probationEndDate.getMonth() === today.getMonth()) {
    return false;
  }
  return true;
};

const leaveCalc = (inProb, probationEndDate, activeStatus, allMasterLeaves) => {
  const masterLeaves = allMasterLeaves;
  if (inProb || activeStatus === 0) {
    return { fullLeave: 0, halfLeave: 0 };
  }
  const today = new Date();
  if (probationEndDate.getFullYear() < today.getFullYear()) {
    return { fullLeave: masterLeaves, halfLeave: 6 };
  }
  const eligibleLeaveMonths = 12 - probationEndDate.getMonth();
  const eligibleLeave = eligibleLeaveMonths * (masterLeaves / 12);
  const fullLeave = eligibleLeave;
  const halfLeave = eligibleLeave / 2;
  return { fullLeave, halfLeave };
};

const getLeavesRemaining = (joiningDate, type, activeStatus, masterLeaves) => {
  if (joiningDate === null || joiningDate === undefined) {
    return;
  }
  let allMasterLeaves = getMasterLeave(masterLeaves);
  let probationEndDate = calProbationEndDate(joiningDate.split("T")[0]);
  const inProb = calcInProb(probationEndDate);
  const data = leaveCalc(
    inProb,
    probationEndDate,
    activeStatus,
    allMasterLeaves
  );
  if (type === "full") return data.fullLeave;
  else return data.halfLeave;
};
export default getLeavesRemaining;
