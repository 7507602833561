import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import constant from "../../common/constant";
import { NotificationManager } from "react-notifications";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { format } from "date-fns";
import { ContactSupportOutlined } from "@material-ui/icons";
const styles = () => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 25,
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  heading: {
    marginTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
});
const AddAppraisal = (props) => {
  const {
    classes,
    appraisalData,
    DesignationId,
    DesignationList,
    EmpCode,
    redirectToAppraisal,
    hideAppraisalForm,
    loadAppraisalData,
    editAppraisalData,
  } = props;
  const getEmployeeDesignation = (desId) => {
    const designation = DesignationList.find(
      ({ DesignationId }) => DesignationId === desId
    );
    return designation.Name;
  };
  const getDesignation = (desData) => {
    console.log(desData)
    const designation = DesignationList.find(
      ({ DesignationId }) =>
        DesignationId === desData[desData.length - 1].NewDesignation
    );
    return designation.Name;
  };
  const getDesignationName = (id) => {
    const a = DesignationList.find((desObj) => {
      if (desObj.DesignationId === id) {
        return desObj.Name;
      }
    });
    return a.Name;
  };
  const [newDesignation, setnewDesignation] = useState(
    Object.keys(editAppraisalData).length &&
      getEmployeeDesignation(editAppraisalData.NewDesignation)
  );
  const [currentSalary, setcurrentSalary] = useState();
  const [newSalary, setnewSalary] = useState();
  const [date, setdate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [currentDesignation, setcurrentDesignation] = useState(
    Object.keys(editAppraisalData).length &&
      getDesignationName(editAppraisalData.CurrentDesignation)
  );
  useEffect(() => {
    if (editAppraisalData && Object.keys(editAppraisalData).length) {
      setcurrentSalary(editAppraisalData.CurrentSalary);
      setnewSalary(editAppraisalData.NewSalary);
      setdate(editAppraisalData.AppraisalDate.split("T")[0]);
    }
  }, [editAppraisalData.NewDesignation]);
  useEffect(() => {
    if (!Object.keys(editAppraisalData).length) {
      if (appraisalData && appraisalData.length) {
        setdate(getDate(appraisalData));
        setcurrentDesignation(getDesignation(appraisalData));
        setcurrentSalary(getCurrentSalary(appraisalData));
      } else {
        setcurrentDesignation(getEmployeeDesignation(DesignationId));
      }
    }
  }, [appraisalData]);

  const getDesignationId = (desName) => {
    const desObj = DesignationList.find(({ Name }) => Name === desName);
    return desObj.DesignationId;
  };
  const changeDesignation = (e) => {
    setnewDesignation(e.target.value);
  };
  const changeCurrentDesignation = (e) => {
    setcurrentDesignation(e.target.value);
  };
  const getCurrentSalary = (salaryData) => {
    const salary = salaryData[salaryData.length - 1].NewSalary;
    return salary;
  };
  const getDate = (dateData) => {
    const date = dateData[dateData.length - 1].AppraisalDate.split("T")[0];
    return date;
  };
  const cancelPannel = () => {
    loadAppraisalData();
    redirectToAppraisal();
    hideAppraisalForm();
  };
  const handleSubmit = (e) => {
    if (!Object.keys(editAppraisalData).length) {
      if (appraisalData.length !== 0) {
        if (
          date <=
          appraisalData[appraisalData.length - 1].AppraisalDate.split("T")[0]
        ) {
          NotificationManager.error("Error", "Please select new Date");
          e.preventDefault();
          return true;
        }
      }
    }
    if (
      (currentSalary && isNaN(currentSalary)) ||
      (newSalary && isNaN(newSalary))
    ) {
      NotificationManager.error("Error", "Please enter salary in numbers");
      e.preventDefault();
      return true;
    }
    if(newDesignation === 0){
      NotificationManager.error("Error", "Appraised Designation is required");
      e.preventDefault();
      return true;
    }
    const body = {
      ID: editAppraisalData.ID,
      EmpCode: EmpCode,
      CurrentDesignation:DesignationId.toString(),
      NewDesignation: getDesignationId(newDesignation).toString(),
      CurrentSalary: currentSalary,
      NewSalary: newSalary,
      AppraisalDate: date,
      type: Object.keys(editAppraisalData).length === 0 ? "add" : "edit",
    };
    fetch(constant.API_ROOT + constant.ADD_EMPLOYEE_APPRAISAL, {
      method: "POST",
      headers: constant.API_HEADER,
      body: JSON.stringify(body),
    }).then((response) => response.json());
    hideAppraisalForm();
    loadAppraisalData();
  };

  return (
    <div>
      <Paper className={classes.root}>
        <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={2}></Grid>
            <Grid item xs={12} sm={7}>
              <FormControl margin="normal" fullWidth>
                <SelectValidator
                  fullWidth
                  style={{ marginBottom: "13px" }}
                  label="Current Designation"
                  id="currentDesignation"
                  name="currentDesignation"
                  disabled={
                    editAppraisalData && !Object.keys(editAppraisalData).length
                  }
                  value={currentDesignation}
                  onChange={changeCurrentDesignation}
                  validators={["required"]}
                  errorMessages={["Current Designation is required"]}
                >
                  {DesignationList.map((data) => {
                    return <MenuItem value={data.Name}>{data.Name}</MenuItem>;
                  })}
                </SelectValidator>
              </FormControl>
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={2}></Grid>
            <Grid item xs={12} sm={7}>
              <FormControl margin="normal" fullWidth>
                <SelectValidator
                  fullWidth
                  style={{ marginBottom: "13px" }}
                  label="Appraised Designation"
                  value={newDesignation}
                  onChange={changeDesignation}
                  validators={["required"]}
                  errorMessages={["Appraised Designation is required"]}
                >
                  {DesignationList.map((data) => {
                    return <MenuItem value={data.Name}>{data.Name}</MenuItem>;
                  })}
                </SelectValidator>
              </FormControl>
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={2}></Grid>
            <Grid item xs={12} sm={7}>
              <FormControl margin="normal" fullWidth>
                <TextValidator
                  margin="normal"
                  fullWidth
                  name="Current Salary"
                  label="Current Salary"
                  disabled={
                    appraisalData.length === 0
                      ? false
                      : !Object.keys(editAppraisalData).length
                      ? true
                      : false
                  }
                  value={currentSalary}
                  onChange={(e) => setcurrentSalary(e.target.value)}
                  validators={["required"]}
                  errorMessages={[" Current Salary is required"]}
                />
              </FormControl>
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={2}></Grid>
            <Grid item xs={12} sm={7}>
              <FormControl margin="normal" fullWidth>
                <TextValidator
                  margin="normal"
                  fullWidth
                  onChange={(e) => {
                    setnewSalary(e.target.value);
                  }}
                  name="New Salary"
                  label="New Salary"
                  value={newSalary}
                  validators={["required"]}
                  errorMessages={["New Salary is required"]}
                />
              </FormControl>
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={2}></Grid>
            <Grid item xs={12} sm={7}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  id="date"
                  label="Appraisal Date"
                  type="date"
                  value={date}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>
          <Grid container spacing={3} className={classes.heading}>
            <Grid item xs={12} className={classes.paper1}>
              <Button
                onClick={cancelPannel}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(AddAppraisal);
