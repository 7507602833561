import React, { useRef } from 'react';
import {
  Container,
  CssBaseline,
  Grid,
  withStyles
} from '@material-ui/core';
import 'react-notifications/lib/notifications.css';
import maria_stewart_section_3 from '../images/maria-stewart-p4tj0g-_aMM-unsplash.jpg';
import bg_main_section_1 from '../images/bg-main.jpg';
import Header from './Header';
import Footer from './Footer';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import Home from './home'


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    // marginTop: theme.spacing.unit * 4,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  mainContainer: {
    marginTop: theme.spacing.unit * 13,
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    maxWidth: '100%',
    padding: 0,
    transition: 'all 0.3s ease-out',
    background: 'rgba(255, 255, 255, 0.8)',
  },
  header: {
    position: 'fixed',
    backgroundColor: 'white',
    top: 'inherit',
    right: 0,
    left: 0,
    zIndex: 1030,
    height: theme.spacing.unit * 13,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  menuLeft: {
    display: 'flex',
    justifyContent: 'end',
    // flexDirection: 'column',
    // textAlign: 'end',
    alignItems: 'center',
    height: theme.spacing.unit * 6,
  },
  bigAvatar: {
    borderRadius: 0,
    marginLeft: 20,
    marginTop: 15,
    width: '50%',
    height: theme.spacing.unit * 9,
  },
  menuMid: {
    justifyContent: 'space-evenly',
    margin: '0 60px',
  },
  menuMid_title: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontWeight: 400,
    fontSize: '18px',
    textDecoration: 'none',
    color: 'rgb(81 81 81);',
    padding: '0 25px',
  },
  menuRight: {
    justifyContent: 'center',
  },
  sectionContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    maxWidth: '100%',
    padding: 0,
  },
  section: {
    maxWidth: '100%',
    minHeight: 'max-content',
  },
  section_heading: {
    fontSize: "32px",
    color: '#FFF'
  },
  section_one: {
    backgroundImage: `url(${bg_main_section_1})`,
    backgroundRepeat: 'no-repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  section_two: {
    display: 'flex',
    paddingTop: '60px',
    backgroundColor: '#FFF',
    justifyContent: 'space-around',
    minHeight: 'max-content',
    marginBottom: '34px',
  },
  section_three: {
    backgroundImage: `url(${maria_stewart_section_3})`,
    position: 'relative',
  },
  section_four: {
    backgroundColor: '#f4f4f4',
    height: 'max-content',
    display: 'flex',
    justifyContent: 'center',
  },
  footer: {
    height: '150px',
    position: 'relative',
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '100%',
    backgroundColor: '#07262e',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    boxShadow: 'none',
    color: '#FFF',
    backgroundColor: 'inherit',
  },
  button: {
    margin: theme.spacing.unit,
  },
});
const HomePage = props => {
  const { classes } = props;
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);


  return (
    <main className={classes.main}>
      {/* <CssBaseline />
      <Header ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} />

      <Container className={classes.sectionContainer}>
        <Grid container className={`${classes.section} ${classes.section_one}`} ref={ref1}>
          <SectionOne />
        </Grid>

        <Grid container className={`${classes.section} ${classes.section_two}`} ref={ref2}>
          <SectionTwo />
        </Grid>

        <Grid container className={`${classes.section} ${classes.section_three}`} ref={ref3}>
          <SectionThree />
        </Grid>

        <Grid container className={`${classes.section} ${classes.section_four}`} ref={ref4}>
          <SectionFour />
        </Grid>

        <Grid container className={classes.footer}>
          <Footer />
        </Grid>
      </Container> */}
      <Home />
    </main >
  );
}


export default withStyles(styles)(HomePage);
