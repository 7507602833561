import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Footer from "../../common/footer";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Const from "../../common/constant";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import moment from "moment";
import CryptoJS from "crypto-js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 25,
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  heading: {
    marginTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  bigAvatar: {
    marginLeft: 80,
    marginTop: 15,
    width: 140,
    height: 140,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
});
var controller;
var signal;

class AddLeave extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      LeaveDescription: "",
      StartDate: moment().format("YYYY-MM-DD"),
      StartDateLL: moment().format("ll"),
      EndDate: moment().format("YYYY-MM-DD"),
      EndDateLL: moment().format("ll"),
      Status: "",
      LeaveTypes: [],
      LeaveTypeID: "",
      LeaveTypeName: "",
      loader: true,
      tabValue: 0,
      open: false,
      SelectedEmployeeName: "",
      SelectedEmployeeId: "",
      filterValue: "",
      filterBy: "Name",
      AllEmployees: [],
      EmployeeList: [],
      AllAdmins: [],
      suggestions: [],
      leaveTableList: [
        "EmployeeLeaves",
        "LeaveApplication",
        "LeaveNotifications",
      ],
      leaveTableListRowLength: {},
      loggedInUser: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ),
      EmpCodeAppliedBy: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).EmpCode,
      EmpCodeAppliedByName: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).Name,
      EmpCodeAppliedByEmail: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OfficialEmailId,
      projectForEdit: this.props.match.params.id,
      buttonText: "Apply",
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      Manager: [],
      endDateManipulation: new Date().toISOString().split("T")[0],
      // leaveNotificationsTableLength: '',
    };
  }

  componentWillMount() {
    console.clear();

    this.props.headerTitle("Add Leave", 7, true);
    // this.getAllEmployees();
    this.getLeaveTypes();
    this.getLeaveTableLength();
    this.props.loader(false);
    this.setState({
      loader: false,
    });
  }

  getAllEmployees = () => {
    controller = new AbortController();
    signal = controller.signal;
  };
  getLeaveTypes = () => {
    controller = new AbortController();
    signal = controller.signal;
    fetch(Const.API_ROOT + Const.GET_LEAVE_TYPES, {
      method: "POST",
      headers: Const.API_HEADER,
      signal: signal,
    })
      .then((response) => response.json())
      .then((resp) => {
        this.props.loader(false);
        this.setState({
          LeaveTypes: resp,
        });
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  };
  getLeaveTableLength = () => {
    let leave_table_list_row_length = {};
    this.state.leaveTableList.map((tableName) => {
      const body = {
        tableName: tableName,
      };
      fetch(Const.API_ROOT + Const.GET_LEAVE_TABLE_LENGTH, {
        method: "POST",
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          leave_table_list_row_length[tableName] = responseJson[0].tableLength;
          this.props.loader(false);
        })
        .catch((error) => {
          this.props.loader(false);
          console.error(error);
        });
    });
    this.setState({
      leaveTableListRowLength: leave_table_list_row_length,
    });
  };
  handleSubmit = (e) => {
    let all_admins_except_managers = this.state.AllAdmins;
    // .filter(ae => ae.RoleId === 1);
    if (!this.state.StartDate) {
      NotificationManager.error("Error", "Please select Start Date");
      e.preventDefault();
      return false;
    }

    if (!this.state.EndDate) {
      NotificationManager.error("Error", "Please select End Date");
      e.preventDefault();
      return false;
    }

    if (this.state.Manager.length === 0) {
      NotificationManager.error("Error", "Please select your Managers");
      e.preventDefault();
      return false;
    }

    if (this.state.LeaveDescription.length > 500) {
      NotificationManager.error(
        "Error",
        "Leave description can be only 500 character long"
      );
      e.preventDefault();
      return false;
    }
    this.props.loader(true);

    this.state.Manager.forEach((m) => {
      if (m.RoleId === 1) {
        all_admins_except_managers = all_admins_except_managers.filter(
          (e) => m.EmpCode !== e.EmpCode
        );
      }
    });
    const body = {
      AllAdminsExceptManagers: all_admins_except_managers,
      EmpCodeAppliedByFullObject: this.state.loggedInUser,
      EmpCodeAppliedBy: this.state.EmpCodeAppliedBy,
      EmpCodeAppliedByName: this.state.EmpCodeAppliedByName,
      EmpCodeAppliedByEmail: this.state.EmpCodeAppliedByEmail,
      EmpCodeAppliedTo: this.state.Manager,
      Description: this.state.LeaveDescription.replaceAll("'", "''"),
      FromDate: this.state.StartDate,
      ToDate: this.state.EndDate,
      TypeOfLeave: this.state.LeaveTypeID,
      prevDataLength: this.state.leaveTableListRowLength,
    };

    fetch(Const.API_ROOT + Const.ADD_LEAVE, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.loader(false);
        if (responseJson) {
          NotificationManager.success("Leave Applied", "Success", 2000);
          setTimeout(
            function () {
              this.props.history.push("/dashboard/leaves");
            }.bind(this),
            2000
          );
        } else {
          NotificationManager.error("Error", "please try again later.");
        }
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });

    let adminNotificationDescription = "";
    let managerNotificationDescription = "";

    if (this.state.LeaveTypeID === 1) {
      adminNotificationDescription = `${this.state.EmpCodeAppliedByName} has requested for a ${this.state.LeaveTypeName} leave from ${this.state.StartDateLL} to ${this.state.EndDateLL}.`;

      managerNotificationDescription = `${this.state.EmpCodeAppliedByName} has requested your approval for a ${this.state.LeaveTypeName} leave from ${this.state.StartDateLL} to ${this.state.EndDateLL}.`;
    } else if (this.state.LeaveTypeID === 2) {
      adminNotificationDescription = `${this.state.EmpCodeAppliedByName} has requested for a ${this.state.LeaveTypeName} leave for ${this.state.StartDateLL}.`;

      managerNotificationDescription = `${this.state.EmpCodeAppliedByName} has requested your approval for a ${this.state.LeaveTypeName} leave for ${this.state.StartDateLL}.`;
    }

    const LeaveNotificationsBody = {
      AllAdminsExceptManagers: all_admins_except_managers,
      prevDataLength: this.state.leaveTableListRowLength.LeaveNotifications,
      AppliedDate: moment().format("ll"),
      AppliedTime: moment().format("LT"),
      SentByEmpCode: this.state.EmpCodeAppliedBy,
      SentByName: this.state.EmpCodeAppliedByName,
      SentToEmpDetails: this.state.Manager,
      adminNotificationDescription: adminNotificationDescription,
      managerNotificationDescription: managerNotificationDescription,
    };

    fetch(Const.API_ROOT + Const.SAVE_LEAVE_NOTIFICATIONS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(LeaveNotificationsBody),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.loader(false);
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
    e.preventDefault();
  };

  filterOptions = () =>
    createFilterOptions({
      matchFrom: "start",
      stringify: (option) => option.Name,
    });
  handleInputChange = async (event, value) => {
    if (value.length === 0) {
      if (this.state.open) this.setState({ open: false });
    } else {
      if (!this.state.open) this.setState({ open: true });
    }
    if (value.length !== 0) {
      this.props.loader(true);
      var body = {
        OrganizationID: this.state.OrganizationID,
      };
      fetch(
        `${Const.API_ROOT}` + `${Const.GET_AUTOCOMPLETE_USERS}?query=${value}`,
        {
          method: "POST",
          headers: Const.API_HEADER,
          body: JSON.stringify(body),
          signal: signal,
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          let decryptData = CryptoJS.AES.decrypt(
            responseJson,
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8);
          const parseData = JSON.parse(decryptData);
          const onlyAdmins = parseData.employees.filter((e) => e.RoleId === 1);
          this.props.loader(false);
          this.setState({
            AllEmployees: parseData.employees.filter((emp) => {
              return emp.Name && emp.EmpCode !== this.state.EmpCodeAppliedBy;
            }),
            AllAdmins: onlyAdmins,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.props.loader(false);
          console.error(error);
        });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {!this.state.loader ? (
          <Paper className={classes.root}>
            <ValidatorForm
              className={classes.form}
              onSubmit={this.handleSubmit}
            >
              <Typography
                component="h1"
                variant="h6"
                className={classes.heading}
              >
                Leave Description
              </Typography>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={10}>
                  <TextValidator
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      this.setState({ LeaveDescription: e.target.value })
                    }
                    name="leaveDescription"
                    value={this.state.LeaveDescription}
                    validators={["required"]}
                    errorMessages={["Leave Description is required"]}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={12} sm={4}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="date"
                      label="Start Date"
                      type="date"
                      value={this.state.StartDate}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: moment().startOf("year").format("YYYY-MM-DD"),
                      }}
                      onChange={(e) => {
                        if (e.target.value >= this.state.EndDate) {
                          this.setState({
                            EndDate: e.target.value,
                            EndDateLL: moment(
                              e.target.value,
                              "YYYY-MM-DD"
                            ).format("ll"),
                          });
                        }
                        this.setState({
                          StartDate: e.target.value,
                          StartDateLL: moment(
                            e.target.value,
                            "YYYY-MM-DD"
                          ).format("ll"),
                          endDateManipulation: e.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="date"
                      label="End Date"
                      type="date"
                      value={this.state.EndDate}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: moment().startOf("year").format("YYYY-MM-DD"),
                      }}
                      onChange={(e) => {
                        this.setState({
                          EndDate: e.target.value,
                          EndDateLL: moment(
                            e.target.value,
                            "YYYY-MM-DD"
                          ).format("ll"),
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl margin="normal" fullWidth>
                    <SelectValidator
                      fullWidth
                      style={{ marginBottom: "13px" }}
                      label="Leave Type"
                      value={this.state.LeaveTypeID}
                      onChange={(e) =>
                        this.setState({
                          LeaveTypeID: e.target.value,
                        })
                      }
                      inputProps={{
                        name: "LeaveTypeID",
                        id: "LeaveTypeID",
                      }}
                      validators={["required"]}
                      errorMessages={["Leave type is required"]}
                    >
                      {this.state.LeaveTypes.map((data) => {
                        return (
                          <MenuItem
                            key={data.ID}
                            value={data.ID}
                            onClick={(e) => {
                              this.setState({
                                LeaveTypeName: data.Name,
                              });
                            }}
                          >
                            {data.Name}
                          </MenuItem>
                        );
                      })}
                    </SelectValidator>
                  </FormControl>
                </Grid>
              </Grid>

              <div className={classes.root}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Managers" />
                  </Tabs>
                </AppBar>
                {
                  <Paper className={classes.root}>
                    <Grid container item xs={12} sm={10} style={{ padding: 0 }}>
                      <Grid item xs={12} sm={10}>
                        <Autocomplete
                          multiple
                          filterOptions={this.filterOptions()}
                          open={this.state.open}
                          id="tags-standard-manager"
                          value={this.state.Manager}
                          options={this.state.AllEmployees}
                          onInputChange={this.handleInputChange}
                          onClose={() => this.setState({ open: false })}
                          getOptionLabel={(option) => option.Name}
                          onChange={(event, newValue) => {
                            this.setState({ Manager: newValue });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Type Name of Your Manager"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                }
              </div>
              <Grid container spacing={5} className={classes.heading}>
                <Grid item xs={12} className={classes.paper1}>
                  <Button
                    onClick={() => this.props.history.push("/dashboard/leaves")}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <i className={`${classes.leftIcon} material-icons`}>
                      arrow_back
                    </i>{" "}
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <i className={`${classes.leftIcon} material-icons`}>
                      directions_run
                    </i>{" "}
                    {this.state.buttonText}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
        ) : (
          ""
        )}
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(AddLeave);
