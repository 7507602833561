const {
  REACT_APP_PROJECT_NAME,
  REACT_APP_ENVIROMENT,
  REACT_APP_HOST,
  REACT_APP_PORT,
  REACT_APP_NODE_ENV,
  REACT_APP_NODE_HOST,
  REACT_APP_NODE_PORT,
  REACT_APP_DOT_NET_HOST,
  REACT_APP_DOT_NET_PORT,
  REACT_APP_SOCKET_IO_HOST,
  REACT_APP_SOCKET_IO_PORT,
  REACT_APP_IN_SITE_MAINTENANCE
} = process.env;

const config = {
  REACT_APP_PROJECT_NAME,
  REACT_APP_ENVIROMENT,
  REACT_APP_URL: `${REACT_APP_HOST}:${REACT_APP_PORT}`,
  REACT_APP_NODE_ENV,
  REACT_APP_NODE_URL: `${REACT_APP_NODE_HOST}:${REACT_APP_NODE_PORT}`,
  REACT_APP_DOT_NET_HOST,
  REACT_APP_DOT_NET_URL: `${REACT_APP_DOT_NET_HOST}:${REACT_APP_DOT_NET_PORT}`,
  REACT_APP_SOCKET_IO_URL: `${REACT_APP_SOCKET_IO_HOST}:${REACT_APP_SOCKET_IO_PORT}`,
  REACT_APP_IN_SITE_MAINTENANCE
}

export default config;