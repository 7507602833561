import React, { Component } from "react";
import Const from "../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import { CKEditor } from "ckeditor4-react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Footer from "../common/footer";
import checkIfImageExists from "../../utils/imageExistenceChecker";
import UploadPhotograph from "../add-employee/UploadPhotograph";
import { CircularProgress } from "@material-ui/core";
import CryptoJS from "crypto-js";
let moment = require("moment");
const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 25,
  },
  table: {
    minWidth: 700,
  },
  space: {
    marginTop: 30,
    border: "none",
  },

  tabPanel: {
    backgroundColor: "#0097a7 !important",
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  heading: {
    marginTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  bigAvatar: {
    marginLeft: 20,
    marginTop: 15,
    width: 140,
    height: 140,
    "&:hover": {
      backgroundColor: "grey",
      cursor: "pointer",
      opacity: 0.6,
      transition: "opacity 0.3s",
    },
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  imageShow: {
    padding: "12px 30px",
    border: "1px solid rgba(0, 0, 0, 0.42)",
  },
  imageAttach: {
    marginTop: 100,
    height: 30,
    width: 130,
  },

  inputImg: {
    marginTop: 100,
    display: "none",
  },

  bannerText: {
    fontSize: "18px",
  },
  imgView: {},
  iconClose: {
    position: "absolute",
    top: "8px",
    right: "8px",
    color: "#000000",
    background: "#fff",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    lineHeight: "45px",
    textAlign: "center",
  },

  docName: {
    color: "black",
    padding: "6px 16px",
    fontSize: "18px",
    background: "#0097a736",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    marginBottom: "20px",
  },

  docIconClose: {
    color: "#000000",
    marginLeft: "20px",
    marginTop: "8px",
  },

  cardStyle: {
    marginTop: 50,
    border: "1px solid rgba(0, 0, 0, 0.42)",
    display: "flex",
    justifyContent: "center",
    alignItemsCenter: "center",
    textAlign: "center",
    padding: "30px",
    boxShadow: "unset !important",
  },

  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
  progress: {
    marginLeft: 530,
    marginTop: 300,
    position: "absolute",
  },
});

class AddLiveNewsUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: "",
      Description: "",
      Title: "",
      EmployeeCode: "",
      isSendMail: "",
      Status: "",
      DateOfEntry: "",
      LiveNewsUpdatesList: [],
      liveNewsUpdatesIdForEdit: this.props.match.params.id,
      action: this.props.match.params.id ? "update" : "add",
      buttonText: this.props.match.params.id ? "Save" : "Save",
      loader: true,
      emailStatus: true,
      profilePicture: "",
      previewimg: "",
      croppedImage: "",
      attachment: [],
      showBannerImageView: false,
      selectedFileName: [],
      imageToCrop: undefined,
    };

    this.inputHandler = this.inputHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onBeforeLoad = this.onBeforeLoad.bind(this);
  }

  componentDidMount() {
    if (this.state.liveNewsUpdatesIdForEdit) {
      this.setUserDetails();
      this.setUserAttachments();
      this.setBannerImage();
    } else {
      this.props.loader(false);
      this.props.headerTitle("Publish new update", 9, true);
      this.setState({
        loader: false,
      });
    }
  }

  setUserAttachments = () => {
    fetch(
      `${Const.API_ROOT}${Const.GET_NEWS_UPDATE_ATTACHMENTS}/${this.state.liveNewsUpdatesIdForEdit}`
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.message) {
          setTimeout(() => {
            this.props.loader(false);
            this.setState({
              loader: false,
            });
          }, 500);
        } else {
          const uploaded = [];
          response.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
              uploaded.push(file);
            }
          });
          for (const key of Object.keys(response)) {
            if (response[key].name) {
              this.state.selectedFileName.push(response[key].name);
            }
          }
          this.setState({ attachment: uploaded });
          setTimeout(() => {
            this.props.loader(false);
            this.setState({
              loader: false,
            });
          }, 500);
        }
      });
  };

  setUserDetails = () => {
    let body = {
      LiveNewsUpdatesId: this.state.liveNewsUpdatesIdForEdit,
    };
    fetch(Const.API_ROOT + Const.GET_LIVE_NEWSUPDATES_DETAILS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson = responseJson.liveNewsUpdatesDetails[0];
        this.setState({
          Id: responseJson.Id,
          Title: responseJson.Title,
          Status: responseJson.Status,
          isSendMail: responseJson.isSendMail,
          DateOfEntry: this.getDate(responseJson.DateOfEntry.split("T")[0]),
          Description: responseJson.Description,
        });

        this.props.headerTitle("Live NewsUpdates Details", 9, true);
      })
      .catch((error) => {
        this.props.loader(false);
        this.setState({
          loader: false,
        });
      });
  };

  setBannerImage = () => {
    checkIfImageExists(
      `${Const.API_ROOT}${Const.GET_BANNER_IMAGE}/${this.state.liveNewsUpdatesIdForEdit}/${this.state.liveNewsUpdatesIdForEdit}.jpeg`,
      (exists) => {
        if (exists) {
          this.setState({
            previewimg: `${Const.API_ROOT}${Const.GET_BANNER_IMAGE}/${this.state.liveNewsUpdatesIdForEdit}/${this.state.liveNewsUpdatesIdForEdit}.jpeg`,
            isPhotoUploaded: false,
            photoUploaded: "",
            showBannerImageView: true,
          });
        }
      }
    );
  };

  getDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  handleSubmit = (e) => {
    if (!this.state.Description) {
      NotificationManager.error("Error", "Description is required");
      e.preventDefault();
      return false;
    }
    if (!this.state.DateOfEntry) {
      NotificationManager.error("Error", "Please select Entry Date");
      e.preventDefault();
      return false;
    }
    if (!this.state.showBannerImageView) {
      NotificationManager.error("Error", "Please Upload Banner Image");
      e.preventDefault();
      return false;
    }
    this.props.loader(true);
    let body = {
      idForSave: this.state.Id,
      Title: this.state.Title,
      employeeCode: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).EmpCode,
      Description: this.state.Description,
      Status: this.state.Status,
      isSendMail: this.state.isSendMail,
      DateOfEntry: this.state.DateOfEntry,
      previewimg: this.state.previewimg,
      action: this.state.action,
    };
    fetch(Const.API_ROOT + Const.ADD_LIVE_NEWSUPDATES, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.loader(false);
        if (responseJson.newsupdateID) {
          if (this.state.liveNewsUpdatesIdForEdit) {
            NotificationManager.success(
              "Live News updated successfully",
              "Success",
              2000
            );
          } else {
            NotificationManager.success(
              "Live News Updates Added successfully",
              "Success",
              2000
            );
          }
          this.state.attachment.length &&
            this.uploadAttactment(responseJson.newsupdateID);
          this.state.croppedImage &&
            this.uploadBannerImage(responseJson.newsupdateID);
          setTimeout(
            function () {
              this.props.history.push("/dashboard/LiveNewsUpdates");
            }.bind(this),
            2000
          );
        } else {
          NotificationManager.error("Error", "please try again later.");
        }
      })
      .catch((error) => {
        this.props.loader(false);
      });
  };

  uploadBannerImage = async (newsupdateID) => {
    let body = {
      croppedImage: {
        base64: this.state.croppedImage.base64,
        fileName: newsupdateID,
      },
    };
    await fetch(
      `${Const.API_ROOT}${Const.UPLOAD_BANNER_IMAGE}/${newsupdateID}`,
      {
        method: "POST",
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {});
  };

  uploadAttactment = async (newsupdateID) => {
    const formData = new FormData();
    try {
      for (const key of Object.keys(this.state.attachment)) {
        formData.append("files", this.state.attachment[key]);
      }
    } catch (ex) {
      console.log("[EXCEPTION] Formdata: ", ex);
    }
    let requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    await fetch(
      `${Const.API_ROOT}${Const.UPLOAD_ATTACTMENTS}/${newsupdateID}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };

  handleUploadFiles = (files) => {
    const MAX_FILE_SIZE = 10e6; // 1GB
    let initialFileSize = 0;
    const uploaded = [...this.state.attachment];
    if (files) {
      files.some((file) => {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          initialFileSize += file.size;
          uploaded.push(file);
        }
      });
      if (initialFileSize > MAX_FILE_SIZE) {
        window.alert("Please upload a file smaller than 5 MB");
        files = "";
        return;
      }
      this.setState({ attachment: uploaded });
      for (const key of Object.keys(files)) {
        this.state.selectedFileName.push(files[key].name);
      }
    }
  };
  selectedAttachment = (e) => {
    e.preventDefault();
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    this.handleUploadFiles(chosenFiles);
  };

  removeAttachment = async (index) => {
    this.state.selectedFileName.splice(index, 1);
    let fileArray = Array.from(this.state.attachment);
    fileArray.splice(index, 1);
    this.setState({ reload: true, attachment: fileArray });
    if (this.state.attachment[index].url) {
      let fileName = this.state.attachment[index].name;
      const requestOptions = {
        method: "DELETE",
      };
      await fetch(
        `${Const.API_ROOT}${Const.DELETE_NEWS_UPDATE_UPLOAD_ATTACHMENT}/${this.state.liveNewsUpdatesIdForEdit}/${fileName}`,
        requestOptions
      )
        .then((response) => {})
        .catch((error) => {});
    }
  };

  imgselectHandler = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;
        this.setState({
          isPhotoUploaded: true,
          photoUploaded: image,
        });
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  handleClose = (image) => {
    this.setState({
      isPhotoUploaded: false,
      photoUploaded: "",
      showBannerImageView: true,
      previewimg: image.base64,
      croppedImage: image,
    });
  };

  uploadFiles() {
    document.getElementById("selectFile").click();
  }

  onBeforeLoad(e) {
    this.setState({ Description: e.editor });
  }

  inputHandler(event) {
    let value = event.editor.getData();
    this.setState({ Description: value });
  }

  render() {
    const { showBannerImageView } = this.state;
    const { classes } = this.props;
    return (
      <div>
        {!this.state.loader ? (
          <Paper className={classes.root}>
            <div>
              <ValidatorForm
                className={classes.form}
                onSubmit={this.handleSubmit}
              >
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={10}>
                    <TextValidator
                      className={classes.space}
                      inputProps={{ maxLength: 500 }}
                      multiline={true}
                      margin="normal"
                      fullWidth
                      label="Title"
                      onChange={(e) => this.setState({ Title: e.target.value })}
                      name="title"
                      value={this.state.Title}
                      validators={["required"]}
                      errorMessages={[" Title is required"]}
                    />
                    <div>
                      <InputLabel
                        style={{ padding: "20px 0", fontSize: "14px" }}
                      >
                        Description
                      </InputLabel>

                      <CKEditor
                        style={{ resize: 100 }}
                        initData={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.Description,
                            }}
                          />
                        }
                        onChange={this.inputHandler}
                      />
                    </div>
                    <FormControl
                      margin="normal"
                      required
                      fullWidth
                      className={classes.space}
                    >
                      <TextField
                        id="date"
                        label="Date"
                        type="date"
                        defaultValue={this.state.DateOfEntry}
                        value={this.state.DateOfEntry}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          this.setState({ DateOfEntry: e.target.value })
                        }
                      />
                    </FormControl>
                    <div>
                      <Card className={classes.cardStyle}>
                        <UploadPhotograph
                          isPhotoUploaded={this.state.isPhotoUploaded}
                          photoUploaded={this.state.photoUploaded}
                          Id={"NewsUpdate"}
                          handleClose={(e) => this.handleClose(e)}
                        />
                        {!showBannerImageView ? (
                          <div>
                            <p className={classes.bannerText}>
                              Banner Image Upload Here
                            </p>
                            <Button
                              className={classes.imageShow}
                              onClick={(e) => this.uploadFiles(e)}
                            >
                              Upload
                            </Button>

                            <input
                              accept="image/*"
                              id="selectFile"
                              type="file"
                              name="file"
                              onChange={this.imgselectHandler.bind(this)}
                              className={classes.inputImg}
                            ></input>
                          </div>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <img
                              src={this.state.previewimg}
                              className={classes.imgView}
                              alt="Uploads Files"
                            ></img>
                            <span className={classes.iconClose}>
                              <i
                                className="material-icons"
                                onClick={() =>
                                  this.setState({ showBannerImageView: false })
                                }
                              >
                                close
                              </i>
                            </span>
                          </div>
                        )}
                      </Card>
                    </div>
                    <div>
                      <Card
                        style={{
                          marginTop: 50,
                          border: "1px solid rgba(0, 0, 0, 0.42)",
                          textAlign: "center",
                          padding: "30px",
                          boxShadow: "unset !important",
                        }}
                      >
                        <div>
                          <p className={classes.bannerText}>
                            File Attachments with this News Updates
                          </p>
                          <Button
                            className={classes.imageShow}
                            onClick={(e) =>
                              document
                                .getElementById("selectAttachments")
                                .click()
                            }
                          >
                            Upload Files
                          </Button>
                          <input
                            id="selectAttachments"
                            type="file"
                            name="file"
                            multiple
                            onChange={(e) => this.selectedAttachment(e)}
                            className={classes.inputImg}
                          ></input>
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {this.state.selectedFileName.map((value, index) => (
                            <span key={index} className={classes.docName}>
                              {value}
                              <span className={classes.docIconClose}>
                                <i
                                  className="material-icons"
                                  onClick={() => this.removeAttachment(index)}
                                >
                                  close
                                </i>
                              </span>
                            </span>
                          ))}
                        </div>
                      </Card>
                    </div>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={2} className={classes.space}>
                        <FormControl margin="normal">
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Status"
                            onChange={(e) =>
                              this.setState({ Status: e.target.checked })
                            }
                            checked={this.state.Status}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.space}>
                        <FormControl margin="normal">
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Send Email"
                            onChange={(e) =>
                              this.setState({ isSendMail: e.target.checked })
                            }
                            onInstanceReady={() => {}}
                            checked={this.state.isSendMail}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={5} className={classes.heading}>
                  <Grid item xs={12} className={classes.paper1}>
                    <Button
                      onClick={() =>
                        this.props.history.push("/dashboard/LiveNewsUpdates")
                      }
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {this.state.buttonText}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </Paper>
        ) : (
          <CircularProgress className={classes.progress} />
        )}
        <Footer />
      </div>
    );
  }
}
export default withStyles(styles)(AddLiveNewsUpdates);
