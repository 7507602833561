import React, { Component } from "react";
import Const from "../common/constant";
import {
  withStyles,
  Paper,
  Button,
  Grid,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { DateRangePicker } from "react-dates";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { NotificationManager } from "react-notifications";
import Footer from "../common/footer";
import moment from "moment";
import LeavesModal from "../leaves/leavesDetailsModal";
import AlertComponent from "./alert";
import CryptoJS from "crypto-js";

let controller;
let signal;
const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 25,
  },
  yRoot: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tabPanel: {
    backgroundColor: "#0097a7 !important",
  },
  heading: {
    marginTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  dateTimePaper: {
    marginBottom: 30,
  },
  daterange: {
    position: "relative",
    left: "100px",
  },
  bigAvatar: {
    marginLeft: 20,
    marginTop: 15,
    width: 140,
    height: 140,
    "&:hover": {
      backgroundColor: "grey",
      cursor: "pointer",
      opacity: 0.6,
      transition: "opacity 0.3s",
    },
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
  approveButton: {
    backgroundColor: "#008000 !important",
    color: "white",
    height: "25px",
    marginRight: "4px",
    fontSize: "small",
    textTransform: "capitalize",
  },
  rejectButton: {
    backgroundColor: "#FF0000 !important",
    color: "white",
    height: "25px",
    marginRight: "4px",
    fontSize: "small",
    textTransform: "capitalize",
  },
  viewDetailtButton: {
    backgroundColor: "#00bfff !important",
    color: "white",
    height: "25px",
    width: "max-content",
    fontSize: "small",
    textTransform: "capitalize",
  },
  card: {
    cursor: "pointer",
    height: 150,
  },
  title: {
    fontSize: 14,
  },
  noData: {
    fontSize: 30,
  },
  paperMain: {
    marginBottom: 20,
    padding: 20,
    textAlign: "left",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  select: {
    width: "80%",
  },
  textField: {
    width: 200,
  },
  records: {
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
    padding: 5,
    fontSize: 13,
    marginLeft: "3%",
  },
  recordsfont: {
    display: "inline-block",
    marginLeft: "10%",
    textAlign: "center",
    verticalAlign: "middle",
    padding: 5,
    fontSize: 13,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  paddingTop: {
    paddingTop: 29,
  },
  padding5: {
    padding: "5px !important",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  sortableIcon: {
    position: "relative",
    paddingRight: "30px !important",
  },
  sortableIconContiner: {
    margin: 0,
    top: "50%",
    display: "inline-block",
    cursor: "pointer",
  },
  sortableIconItem: {
    fontWeight: "bold",
    fontSize: 15,
    verticalAlign: "middle",
  },
  displayFlex: {
    display: "flex",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  alignItemsCenter: { alignItems: "center" },
  avatarImage: { width: "100% !important", height: "auto !important" },
  customCard: {
    height: "auto",
    minHeight: 150,
    position: "relative",
    zIndex: 0,
  },
  empName: { fontSize: 16, color: "#3b6978" },
  pos: {
    fontSize: 13,
    fontWeight: "500",
    color: "#999",
  },
  seprator: {
    textAlign: "center",
    margin: "15px 0",
    position: "relative",
  },
  sepratorSpan: {
    color: "#fff",
    background: "#fb7813",
    padding: " 0px 10px",
    borderRadius: "8px",
    height: "25px",
    display: "inline-block",
    lineHeight: "24px",
    position: "relative",
    zIndex: 1,
    fontSize: 14,
  },
  sepratorBorder: {
    background: "#fb7813",

    position: "absolute",
    height: "1px",
    left: "15px",
    right: "15px",
    top: "50%",
  },
  nm: { margin: "0 !important" },
  values: {
    position: "relative",
    marginTop: "10px",
  },
  rightSepratorContainer: {
    position: "relative",
    display: "inline-block",
    verticalAlign: "middle",
  },
  rightSeprator: {
    position: "absolute",
    width: 2,
    height: 15,
    background: "#c0c0c0",
    marginLeft: 4,
    top: "30%",
    transform: "translateY(-50%)",
  },
  rightSepratorHalf: {
    position: "absolute",
    width: 2,
    height: 15,
    background: "#c0c0c0",
    marginLeft: 5,
    top: "70%",
    transform: "translateY(-50%)",
  },
  valueTitle: { fontWeight: 500 },
  actionButton: {
    "&:hover": {
      fontWeight: 500,
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  select: {
    paddingTop: "0px",
  },
});
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
const LeaveStatus = {
  1: {
    status: "Pending",
    color: "#ED7014",
  },
  2: {
    status: "Approved",
    color: "#228C22",
  },
  3: {
    status: "Rejected",
    color: "#3C3C3C",
  },
  4: {
    status: "Cancelled",
    color: "#212121",
  },
  5: {
    status: "Marked Absent",
    color: "#ED7014",
  },
  6: {
    status: "Marked Present",
    color: "#228C22",
  },
};
class Leaves extends Component {
  constructor(props) {
    super(props);
    this.allLeavesloadingRef = React.createRef();
    this.appliedLeavesLoadingRef = React.createRef();
    this.approvalLeavesLoadingRef = React.createRef();
    this.state = {
      AllEmployees: [],
      AllAdmins: [],
      EmployeeLeaves: [],
      FullDayLeaveCount: [],
      HalfDayLeaveCount: [],
      Employees: [],
      columns: [],
      filterBy: "",
      filterValue: "",
      sortBy: "",
      sortOrder: "asc",
      sortIcon: "arrow_downward",
      confirmDeleteDialog: false,
      employeeIdForDelete: "",
      listView: false,
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      allProfilePicture: {},
      isComponentUpdating: false,
      tabValue: this.props.location.state
        ? "holidayList"
        : JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("Item"),
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8)
        ).RoleId === 1
          ? "employeeLeaves"
          : "yourAppliedLeaves",
      focusedInput: "",
      eStartDate: moment().startOf("year"),
      eEndDate: moment().endOf("year"),
      yStartDate: moment().startOf("year"),
      yEndDate: moment().endOf("year"),
      lStartDate: moment().startOf("year"),
      lEndDate: moment().endOf("year"),
      isTabOneSelected: true,
      EmployeeLeaveEmpCode: "",
      loggedInUser: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ),
      loggedInUserEmpCode: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).EmpCode,
      loggedInUserRoleID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).RoleId,
      loggedInUserName: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).Name,
      loggedInUserOfficialEmailId: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OfficialEmailId,
      SentToManagersList: [],
      SentToManagersListNames: [],
      specificEmployeeLeaveManagerList: [],
      EmployeeLeaveTableColumns: [
        "Name",
        "Start Date",
        "End Date",
        "Status",
        "Action",
      ],
      AppliedLeaveTableColumns: [
        "Name",
        "Start Date",
        "End Date",
        "Status",
        "Action",
      ],
      YourApprovalEmployeeLeaveTableColumns: [
        "Name",
        "Start Date",
        "End Date",
        "Status",
        "Action",
      ],
      HolidayListColumns: ["Holiday Date", "Day", "Reason"],
      loggedInUserRequestApprovalLeavesFromAPI: [],
      cancelButtonTextObj: {},
      permisssionsStatus: {},
      rejectionPermissionStatus: {},
      markPresentPermissionStatus: {},
      leaveApprovedByList: {},
      leaveRejectedByList: {},
      leaveTableListRowLength: {},
      leaveTableList: ["LeaveNotifications"],
      AllEmployeesLeaves: [],
      pager: {},
      pageSize: 20,
      page: 1,
      prevY: 0,
      totalpage: 0,
      appliedLeavesPage: 1,
      appliedLeavesPrevY: 0,
      appliedLeavesTotalPage: 0,
      approvalLeavesPage: 1,
      approvalLeavesPrevY: 0,
      approvalLeavesTotalpage: 0,
      showModal: false,
      modalContent: {},
      holidayYears: [],
      currentYear: moment().year(),
      holidayList: [],
      openDialog: false,
      stateStauts: "",
      data: "",
      selectedEmployess: [],
      allEmployees: [],
      open: false,
    };
  }
  componentWillUnmount() {
    controller.abort();
  }
  getLeaves = async (page) => {
    const body = {
      orgID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      EmpCode: this.state.selectedEmployess
        .filter((e) => e.EmpCode)
        .map((item) => {
          return item.EmpCode;
        }),
      FromDate: this.state.eStartDate.format("YYYY-MM-DD"),
      ToDate: this.state.eEndDate.format("YYYY-MM-DD"),
    };
    const leavesResponse = await fetch(
      `${Const.API_ROOT}` +
      `${Const.GET_LEAVES}?page=${page}&pageSize=${this.state.pageSize}`,
      {
        method: "POST",
        headers: Const.API_HEADER,
        signal: signal,
        body: JSON.stringify(body),
      }
    );
    return leavesResponse.json();
  };

  allLeavesHandleObserver(entities) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y && this.state.page < this.state.totalpage) {
      this.props.loader(true);
      const currPage = this.state.page + 1;
      this.getLeaves(currPage).then((res) => {
        const filteredLeaves = res.EmployeeLeaves.filter(
          (data) => data.Status !== 6
        );
        this.setState({
          page: currPage,
          AllEmployeesLeaves: [
            ...this.state.AllEmployeesLeaves,
            ...filteredLeaves,
          ],
        });
        this.props.loader(false);
      });
    }
    this.setState({ prevY: y });
  }

  getAppliedLeaves = async (page) => {
    const logged_in_user_applied_leaves_body = {
      Param: CryptoJS.AES.encrypt(
        this.state.loggedInUserEmpCode,
        process.env.REACT_APP_SECRET_KEY
      ).toString(),
    };
    const appliedLeavesResponseJson = await fetch(
      `${Const.API_ROOT}` +
      `${Const.GET_LOGGED_IN_USER_APPLIED_LEAVES}?page=${page}&pageSize=${this.state.pageSize}`,
      {
        method: "POST",
        headers: Const.API_HEADER,
        signal: signal,
        body: JSON.stringify(logged_in_user_applied_leaves_body),
      }
    );
    return appliedLeavesResponseJson.json();
  };
  appliedLeaveHandleObserver(entities) {
    const y = entities[0].boundingClientRect.y;
    if (
      this.state.appliedLeavesPrevY > y &&
      this.state.appliedLeavesPage < this.state.appliedLeavesTotalPage
    ) {
      const currPage = this.state.appliedLeavesPage + 1;
      this.getAppliedLeaves(currPage).then((res) => {
        this.setState({
          appliedLeavesPage: currPage,
          EmployeeLeaves: [...this.state.EmployeeLeaves, ...res.EmployeeLeaves],
        });
      });
    }
    this.setState({ appliedLeavesPrevY: y });
  }

  getApprovalLeaves = async (page) => {
    const logged_in_user_applied_leaves_body = {
      EmpCodeAppliedBy: this.state.loggedInUserEmpCode,
    };
    const requestApprovalLeavesResponseAPI = await fetch(
      `${Const.API_ROOT}` +
      `${Const.GET_LOGGED_IN_USER_APPROVAL_LEAVES}?page=${page}&pageSize=${this.state.pageSize}`,
      {
        method: "POST",
        headers: Const.API_HEADER,
        signal: signal,
        body: JSON.stringify(logged_in_user_applied_leaves_body),
      }
    );
    return requestApprovalLeavesResponseAPI.json();
  };

  approvalLeaveHandleObserver(entities) {
    const y = entities[0].boundingClientRect.y;
    if (
      this.state.approvalLeavesPrevY > y &&
      this.state.approvalLeavesPage < this.state.approvalLeavesTotalpage
    ) {
      const currPage = this.state.approvalLeavesPage + 1;
      this.getApprovalLeaves(currPage).then((res) => {
        this.setState({
          approvalLeavesPage: currPage,
          loggedInUserRequestApprovalLeavesFromAPI: [
            ...this.state.loggedInUserRequestApprovalLeavesFromAPI,
            ...res.EmployeeLeaves,
          ],
        });
      });
    }
    this.setState({ approvalLeavesPrevY: y });
  }

  getHolidayYearsList = async () => {
    const holidayListYear = await fetch(
      Const.API_ROOT + Const.GET_ALL_HOLIDAY_YEARS,
      {
        method: "GET",
        headers: Const.API_HEADER,
      }
    );
    return holidayListYear.json();
  };

  getAllHolidays = async () => {
    const body = {
      Year: this.state.currentYear,
    };
    const holidaysList = await fetch(Const.API_ROOT + Const.GET_ALL_HOLIDAYS, {
      method: "POST",
      headers: Const.API_HEADER,
      signal: signal,
      body: JSON.stringify(body),
    });
    return holidaysList.json();
  };

  async componentDidMount() {
    controller = new AbortController();
    signal = controller.signal;
    this.props.loader(true);
    this.props.headerTitle("Leaves", 7);
    this.getLeaveTableLength();
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(
      this.allLeavesHandleObserver.bind(this),
      options
    );
    this.secondobserver = new IntersectionObserver(
      this.appliedLeaveHandleObserver.bind(this),
      options
    );
    this.thirdobserver = new IntersectionObserver(
      this.approvalLeaveHandleObserver.bind(this),
      options
    );

    //getHolidayYearsList
    const holidayListOfYearsResponseJson = await this.getHolidayYearsList();
    this.setState({ holidayYears: holidayListOfYearsResponseJson });

    //getAllHolidays
    const allHolidayResponseJson = await this.getAllHolidays();
    this.setState({ holidayList: allHolidayResponseJson });

    //getLeaves API
    let leavesResponseJson;
    if (this.state.tabValue === "employeeLeaves") {
      leavesResponseJson = await this.getLeaves(1);
    }
    //applied API
    let appliedLeaves;
    if (this.state.tabValue === "yourAppliedLeaves") {
      appliedLeaves = await this.getAppliedLeaves(1);
    }
    this.setState({
      EmployeeLeaves:
        this.state.tabValue === "yourAppliedLeaves"
          ? appliedLeaves.EmployeeLeaves
          : "",
      totalpage:
        this.state.tabValue === "employeeLeaves"
          ? leavesResponseJson.pager.totalPages
          : "",
      appliedLeavesTotalPage:
        this.state.tabValue === "yourAppliedLeaves"
          ? appliedLeaves.pager.totalPages
          : "",
    });

    if (this.state.tabValue === "employeeLeaves") {
      if (leavesResponseJson.EmployeeLeaves)
        this.setState({
          AllEmployeesLeaves: leavesResponseJson.EmployeeLeaves,
        });
    } else if (this.state.tabValue === "yourAppliedLeaves") {
      if (appliedLeaves.EmployeeLeaves)
        this.setState({ AllEmployeesLeaves: appliedLeaves.EmployeeLeaves });
    }
    this.props.loader(false);
    this.setState({
      loader: false,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.secondobserver && this.appliedLeavesLoadingRef.current) {
      this.secondobserver.observe(this.appliedLeavesLoadingRef.current);
    } else if (this.observer && this.allLeavesloadingRef.current) {
      this.observer.observe(this.allLeavesloadingRef.current);
    } else if (this.thirdobserver && this.approvalLeavesLoadingRef.current) {
      this.thirdobserver.observe(this.approvalLeavesLoadingRef.current);
    }
    if (
      this.state.tabValue !== prevState.tabValue &&
      this.state.tabValue === "yourAppliedLeaves"
    ) {
      const appliedLeaves = await this.getAppliedLeaves(1);
      if (this.state.EmployeeLeaves.length === 0) {
        this.setState({
          EmployeeLeaves: appliedLeaves.EmployeeLeaves,
          appliedLeavesTotalPage: appliedLeaves.pager.totalPages,
        });
      } else {
        this.setState({
          EmployeeLeaves: this.state.EmployeeLeaves,
        });
      }
    }
    if (
      this.state.tabValue !== prevState.tabValue &&
      this.state.tabValue === "leavesForYourApproval"
    ) {
      let approvalLeaves = await this.getApprovalLeaves(1);
      if (this.state.loggedInUserRequestApprovalLeavesFromAPI.length === 0) {
        this.setState({
          loggedInUserRequestApprovalLeavesFromAPI:
            approvalLeaves.EmployeeLeaves,
          approvalLeavesTotalpage: approvalLeaves.pager.totalPages,
        });
      } else {
        this.setState({
          loggedInUserRequestApprovalLeavesFromAPI:
            this.state.loggedInUserRequestApprovalLeavesFromAPI,
        });
      }
    }
    if (
      (this.state.tabValue !== prevState.tabValue &&
        this.state.tabValue === "employeeLeaves") ||
      this.state.selectedEmployess !== prevState.selectedEmployess ||
      this.state.eEndDate !== prevState.eEndDate
    ) {
      const leavesResponseJson = await this.getLeaves(1);
      if (this.state.selectedEmployess.length !== 0) {
        this.setState({
          AllEmployeesLeaves: leavesResponseJson.EmployeeLeaves,
          totalpage: leavesResponseJson.pager.totalPages,
        });
      } else {
        this.setState({
          AllEmployeesLeaves: this.state.AllEmployeesLeaves,
        });
      }
    }

    if (this.state.currentYear !== prevState.currentYear) {
      const allHolidayResponseJson = await this.getAllHolidays();
      this.setState({ holidayList: allHolidayResponseJson });
    }
  }

  getLeaveTableLength = () => {
    let leave_table_list_row_length = {};
    this.state.leaveTableList.map((tableName) => {
      const body = {
        tableName: tableName,
      };
      fetch(Const.API_ROOT + Const.GET_LEAVE_TABLE_LENGTH, {
        method: "POST",
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          leave_table_list_row_length[tableName] = responseJson[0].tableLength;
        })
        .catch((error) => {
          console.error(error);
        });
    });
    this.setState({
      leaveTableListRowLength: leave_table_list_row_length,
    });
  };
  handleTabViewChange = (event, value) => {
    this.setState({ tabValue: value });
  };

  checkData = (data, column) => {
    let val = data[column];
    if (!val) {
      return "N/A";
    } else if (
      column === "JoiningDate" ||
      column === "RelievingDate" ||
      column === "DateOfBirth"
    ) {
      return moment(val).format("MM/DD/YYYY");
    } else return val;
  };
  datePicker = (propStartDate, propEndDate) => {
    return (
      <DateRangePicker
        startDate={this.state[propStartDate]}
        startDateId="your_unique_start_date_id"
        endDate={this.state[propEndDate]}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          if (startDate === null) {
            startDate = moment().startOf("year");
          }
          if (endDate === null) {
            endDate = moment().endOf("year");
          }
          this.setState({ [propStartDate]: startDate, [propEndDate]: endDate });
        }}
        focusedInput={this.state.focusedInput}
        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        isOutsideRange={() => false}
      />
    );
  };

  toggleModal = (show) => {
    this.setState({ showModal: show });
  };

  actionTakenByFunc = (data) => {
    let anyone = "";
    if (data.ApprovedByEmpCode === null && data.RejectedByEmpCode === null) {
      anyone = "Not Yet";
    } else if (
      data.ApprovedByEmpCode !== null &&
      data.ApprovedByEmpCode !== undefined &&
      data.ApprovedByEmpCode !== "undefined"
    ) {
      anyone = <span>{data.ApprovedByEmpName}</span>;
    } else if (
      data.RejectedByEmpCode !== null &&
      data.RejectedByEmpCode !== undefined &&
      data.RejectedByEmpCode !== "undefined"
    ) {
      anyone = <span>{data.RejectedByEmpName}</span>;
    }
    if (this.state.leaveApprovedByList[data.ID] !== undefined) {
      anyone = this.state.leaveApprovedByList[data.ID];
    } else if (this.state.leaveRejectedByList[data.ID] !== undefined) {
      anyone = this.state.leaveRejectedByList[data.ID];
    }
    return anyone;
  };

  isApprovedfunc = (data) => {
    let anyone = "";
    if (data.ApprovedByEmpCode === null && data.RejectedByEmpCode === null) {
      anyone = "not yet";
    } else if (
      data.ApprovedByEmpCode !== null &&
      data.ApprovedByEmpCode !== undefined &&
      data.ApprovedByEmpCode !== "undefined"
    ) {
      anyone = <b>{data.ApprovedByEmpName}</b>;
    } else {
      anyone = "NA";
    }
    if (this.state.leaveApprovedByList[data.ID] !== undefined) {
      anyone = this.state.leaveApprovedByList[data.ID];
    }
    return anyone;
  };
  isRejectedfunc = (data) => {
    let anyone = "";
    if (data.ApprovedByEmpCode === null && data.RejectedByEmpCode === null) {
      anyone = "not yet";
    } else if (
      data.RejectedByEmpCode !== null &&
      data.RejectedByEmpCode !== undefined &&
      data.RejectedByEmpCode !== "undefined"
    ) {
      anyone = <b>{data.RejectedByEmpName}</b>;
    } else {
      anyone = "NA";
    }
    if (this.state.leaveRejectedByList[data.ID] !== undefined) {
      anyone = this.state.leaveRejectedByList[data.ID];
    }
    return anyone;
  };
  showActionButtons = (ApprovedByEmpCode, RejectedByEmpCode) => {
    let showButtons = true;
    if (ApprovedByEmpCode !== null || RejectedByEmpCode !== null) {
      showButtons = false;
    }
    return showButtons;
  };
  addLeave() {
    this.props.history.push(
      `/dashboard/add-leave/${this.state.loggedInUserEmpCode}`
    );
  }

  handleNotificationSender = (
    data,
    managerNotificationDescription,
    adminNotificationDescription,
    applierNotificationDescription,
    managers
  ) => {
    let sentToEmpDetails = [];
    if (managers) sentToEmpDetails = managers;
    else sentToEmpDetails.push(this.state.loggedInUser);
    const LeaveNotificationsBody = {
      ApplierData: data,
      AppliedDate: moment().format("ll"),
      AppliedTime: moment().format("LT"),
      SentByEmpCode: this.state.loggedInUserEmpCode,
      SentByName: this.state.loggedInUserName,
      SentToEmpDetails: sentToEmpDetails,
      adminNotificationDescription: adminNotificationDescription,
      managerNotificationDescription: managerNotificationDescription,
      applierNotificationDescription: applierNotificationDescription,
    };
    fetch(Const.API_ROOT + Const.SAVE_LEAVE_NOTIFICATIONS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(LeaveNotificationsBody),
    })
      .then((res) => res.json())
      .then((json) => {
        this.props.loader(false);
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };
  handleCancel = async (data) => {
    const body = {
      AllAdmins: this.state.AllAdmins,
      ID: data.ID,
      EmpCodeAppliedBy: this.state.loggedInUser,
      EmpCodeAppliedByName: this.state.loggedInUserName,
      EmpCodeAppliedByEmail: this.state.loggedInUserOfficialEmailId,
      startDate: data.FromDate.split("T")[0],
      endDate: data.ToDate.split("T")[0],
    };
    const cancelLeavesResponse = await fetch(
      Const.API_ROOT + Const.CANCEL_LEAVE,
      {
        method: "POST",
        headers: Const.API_HEADER,
        signal: signal,
        body: JSON.stringify(body),
      }
    );
    const cancelLeavesResponseJson = await cancelLeavesResponse.json();
    if (cancelLeavesResponseJson) {
      this.setState({
        permisssionsStatus: {
          ...this.state.permisssionsStatus,
          [data.ID]: 4,
        },
        cancelButtonTextObj: {
          ...this.state.cancelButtonTextObj,
          [data.ID]: 4,
        },
      });
      NotificationManager.success("Leave Cancelled", "Success", 2000);
    } else {
      NotificationManager.error(
        "Error",
        "Error Occurred while Leave Cancellation"
      );
    }

    const adminNotificationDescription = `${this.state.loggedInUserName
      } has cancelled their ${data.LeaveName} leave from ${data.FromDate.split("T")[0]
      } to ${data.ToDate.split("T")[0]}.`;

    const managerNotificationDescription = `${this.state.loggedInUserName
      } has cancelled their ${data.LeaveName} leave from ${data.FromDate.split("T")[0]
      } to ${data.ToDate.split("T")[0]}.`;

    this.handleNotificationSender(
      data,
      managerNotificationDescription,
      adminNotificationDescription,
      "",
      cancelLeavesResponseJson.email.managers
    );
  };
  handleApproval = async (data) => {
    const body = {
      AllAdmins: this.state.AllAdmins,
      ActionByEmpCode: this.state.loggedInUserEmpCode,
      ApplierData: data,
      ID: data.ID,
      ActionByEmp: this.state.loggedInUser,
      ActionByEmpName: this.state.loggedInUserName,
      ActionByEmpEmail: this.state.loggedInUserOfficialEmailId,
      startDate: data.FromDate.split("T")[0],
      endDate: data.ToDate.split("T")[0],
    };
    const approvedLeavesResponse = await fetch(
      Const.API_ROOT + Const.APPROVE_LEAVE,
      {
        method: "POST",
        headers: Const.API_HEADER,
        signal: signal,
        body: JSON.stringify(body),
      }
    );
    const approvedLeavesResponseJson = await approvedLeavesResponse.json();
    if (
      approvedLeavesResponseJson &&
      approvedLeavesResponseJson.approveLeaveResponse
    ) {
      this.setState({
        permisssionsStatus: {
          ...this.state.permisssionsStatus,
          [data.ID]: 2,
        },
        leaveApprovedByList: {
          ...this.state.leaveApprovedByList,
          [data.ID]: approvedLeavesResponseJson.approveLeaveResponse[0].Name,
        },
        leaveRejectedByList: {
          ...this.state.leaveRejectedByList,
          [data.ID]: "NA",
        },
      });
      NotificationManager.success("Leave Approved", "Success", 2000);
    } else {
      NotificationManager.error("Error", "Error Occurred while Leave Approval");
    }
    const adminNotificationDescription = `${this.state.loggedInUserName
      } has approved ${data.EmpName} ${data.LeaveName} leave from ${data.FromDate.split("T")[0]
      } to ${data.ToDate.split("T")[0]}.`;
    const managerNotificationDescription = adminNotificationDescription;
    const applierNotificationDescription = `Your ${data.LeaveName} leave from ${data.FromDate.split("T")[0]
      } to ${data.ToDate.split("T")[0]} has been approved by ${this.state.loggedInUserName
      }.`;
    this.handleNotificationSender(
      data,
      managerNotificationDescription,
      adminNotificationDescription,
      applierNotificationDescription,
      approvedLeavesResponseJson.email.managers
    );
  };
  handleRejection = async (data) => {
    // --- CHECK HERE IF ANYONE HAS ALREADY REJECTED OR NOT
    const body = {
      AllAdmins: this.state.AllAdmins,
      ActionByEmpCode: this.state.loggedInUserEmpCode,
      ApplierData: data,
      ID: data.ID,
      ActionByEmp: this.state.loggedInUser,
      ActionByEmpName: this.state.loggedInUserName,
      ActionByEmpEmail: this.state.loggedInUserOfficialEmailId,
      startDate: data.FromDate.split("T")[0],
      endDate: data.ToDate.split("T")[0],
    };
    const rejectedLeavesResponse = await fetch(
      Const.API_ROOT + Const.REJECT_LEAVE,
      {
        method: "POST",
        headers: Const.API_HEADER,
        signal: signal,
        body: JSON.stringify(body),
      }
    );
    const rejectedLeavesResponseJson = await rejectedLeavesResponse.json();
    if (
      rejectedLeavesResponseJson &&
      rejectedLeavesResponseJson.rejectLeaveResponse
    ) {
      this.setState({
        permisssionsStatus: {
          ...this.state.permisssionsStatus,
          [data.ID]: 3,
        },
        rejectionPermissionStatus: {
          ...this.state.permisssionsStatus,
          [data.ID]: 3,
        },
        leaveRejectedByList: {
          ...this.state.leaveRejectedByList,
          [data.ID]: rejectedLeavesResponseJson.rejectLeaveResponse[0].Name,
        },
        leaveApprovedByList: {
          ...this.state.leaveApprovedByList,
          [data.ID]: "NA",
        },
      });
      NotificationManager.success("Leave Rejected", "Success", 2000);
    } else {
      NotificationManager.error(
        "Error",
        "Error Occurred while Leave Rejection"
      );
    }
    const adminNotificationDescription = `${this.state.loggedInUserName
      } has rejected ${data.EmpName} ${data.LeaveName} leave from ${data.FromDate.split("T")[0]
      } to ${data.ToDate.split("T")[0]}.`;
    const managerNotificationDescription = adminNotificationDescription;
    const applierNotificationDescription = `Your ${data.LeaveName} leave from ${data.FromDate.split("T")[0]
      } to ${data.ToDate.split("T")[0]} has been rejected by ${this.state.loggedInUserName
      }.`;
    this.handleNotificationSender(
      data,
      managerNotificationDescription,
      adminNotificationDescription,
      applierNotificationDescription,
      rejectedLeavesResponseJson.email.managers
    );
  };
  handleMarkPresent = async (data) => {
    const body = {
      ID: data.ID,
      EmpCode: data.EmpCode,
      FromDate: data.FromDate,
      Todate: data.ToDate,
    };
    const markPresentresponse = await fetch(
      Const.API_ROOT + Const.Mark_Present,
      {
        method: "POST",
        headers: Const.API_HEADER,
        signal: signal,
        body: JSON.stringify(body),
      }
    );
    if (markPresentresponse) {
      this.setState({
        markPresentPermissionStatus: {
          ...this.state.permisssionsStatus,
          [data.ID]: 6,
        },
        permisssionsStatus: {
          ...this.state.permisssionsStatus,
          [data.ID]: 6,
        },
      });
      NotificationManager.success("Marked Present", "Success", 2000);
    } else {
      NotificationManager.error(
        "Error",
        "Error Occurred while Marking Present"
      );
    }
  };
  handleLeaveStatusTableCell = (data) => {
    if (
      Object.keys(this.state.permisssionsStatus).length > 0 &&
      this.state.permisssionsStatus[data.ID] !== undefined
    ) {
      return (
        <TableCell
          style={{
            color: LeaveStatus[this.state.permisssionsStatus[data.ID]].color,
          }}
        >
          {LeaveStatus[this.state.permisssionsStatus[data.ID]].status}
        </TableCell>
      );
    } else {
      return (
        <TableCell style={{ color: LeaveStatus[data.Status].color }}>
          {LeaveStatus[data.Status].status}
        </TableCell>
      );
    }
  };
  handleCancelButtonTableCell = (data, classes) => {
    if (
      Object.keys(this.state.cancelButtonTextObj).length > 0 &&
      this.state.cancelButtonTextObj[data.ID] !== undefined
    ) {
      return (
        <TableCell>
          <div style={{ display: "flex" }}>
            <span style={{ display: "flex" }}>
              <Button
                className={classes.viewDetailtButton}
                variant="contained"
                onClick={() => this.onClickHandler(data)}
              >
                {" "}
                View Detail
              </Button>
            </span>
          </div>
        </TableCell>
      );
    } else {
      return (
        <TableCell>
          {" "}
          {(data.Status === 1 || data.Status === 2) &&
            data.ToDate.split("T")[0] >=
            new Date().toISOString().split("T")[0] ? (
            <div style={{ display: "flex" }}>
              <Button
                className={classes.rejectButton}
                variant="contained"
                onClick={(event) => {
                  this.onAlertHandler(
                    "Cancel",
                    data,
                    "Are you sure to cancel leave"
                  );
                }}
              >
                Cancel
              </Button>
              <span style={{ display: "flex" }}>
                <Button
                  className={classes.viewDetailtButton}
                  variant="contained"
                  onClick={() => this.onClickHandler(data)}
                >
                  View Detail
                </Button>
              </span>
            </div>
          ) : (
            <span style={{ display: "flex" }}>
              <Button
                className={classes.viewDetailtButton}
                variant="contained"
                onClick={() => this.onClickHandler(data)}
              >
                View Detail
              </Button>
            </span>
          )}
        </TableCell>
      );
    }
  };
  onClickHandler = (modalData) => {
    this.setState({
      modalContent: modalData,
    });
    this.toggleModal(true);
  };
  onAlertHandler = (type, data, alertContent) => {
    this.setState({ openDialog: true, stateStauts: type, data, alertContent });
  };

  alertSubmit = () => {
    const { stateStauts, data } = this.state;
    if (stateStauts === "Cancel") this.handleCancel(data);
    else if (stateStauts === "Approve") this.handleApproval(data);
    else if (stateStauts === "Rejected") this.handleRejection(data);
    else if (stateStauts === "markPresent") this.handleMarkPresent(data);
    this.handleClose(false);
  };
  filterOptions = () =>
    createFilterOptions({
      matchFrom: "start",
      stringify: (option) => option.Name,
    });

  handleInputChange = async (event, value) => {
    if (value.length === 0) {
      if (this.state.open) this.setState({ open: false });
    } else {
      if (!this.state.open) this.setState({ open: true });
    }
    if (value.length !== 0) {
      this.props.loader(true);
      var body = {
        OrganizationID: this.state.OrganizationID,
      };
      fetch(
        `${Const.API_ROOT}` + `${Const.GET_AUTOCOMPLETE_USERS}?query=${value}`,
        {
          method: "POST",
          headers: Const.API_HEADER,
          body: JSON.stringify(body),
          signal: signal,
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          let decryptData = CryptoJS.AES.decrypt(
            responseJson,
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8);
          const parseData = JSON.parse(decryptData);
          this.props.loader(false);
          this.setState({
            allEmployees: parseData.employees.filter((emp) => {
              return emp.Name;
            }),
          });
        })
        .catch((error) => {
          this.props.loader(false);
          console.error(error);
        });
    }
  };
  isAdmin = () => this.state.loggedInUserRoleID === 1;
  render() {
    const { classes } = this.props;
    const { tabValue, alertContent } = this.state;
    return (
      <div>
        <LeavesModal
          isOpen={this.state.showModal}
          onClose={() => this.toggleModal(false)}
          modalContent={this.state.modalContent}
          actionTakenByFunc={this.actionTakenByFunc}
        />
        <AlertComponent
          isOpen={this.state.openDialog}
          onClose={() => this.handleClose(false)}
          handleCancel={this.handleCancel}
          handleSubmit={() => this.alertSubmit()}
          alertContent={alertContent}
        />
        {!this.state.loader ? (
          <Paper className={classes.root} style={{ display: "inline-table" }}>
            <div>
              <AppBar position="static" className={classes.tabPanel}>
                <Tabs value={tabValue} onChange={this.handleTabViewChange}>
                  {this.isAdmin() && (
                    <Tab value="employeeLeaves" label="Employee Leaves" />
                  )}
                  <Tab value="yourAppliedLeaves" label="Your Applied Leaves" />
                  <Tab
                    value="leavesForYourApproval"
                    label="Leaves For Your Approval"
                  />
                  <Tab value="holidayList" label="Holiday List" />
                </Tabs>
              </AppBar>
              {this.isAdmin() && tabValue === "employeeLeaves" && (
                <TabContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} className={classes.filterDiv}>
                      <Autocomplete
                        filterOptions={this.filterOptions()}
                        className={classes.select}
                        open={this.state.open}
                        disabled={false}
                        multiple
                        value={this.state.selectedEmployess}
                        options={this.state.allEmployees}
                        onInputChange={this.handleInputChange}
                        onClose={() =>
                          this.setState({
                            open: false,
                          })
                        }
                        getOptionLabel={(option) => option.Name}
                        onChange={(event, newValue) => {
                          this.setState({ selectedEmployess: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Employee Name"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.daterange}>
                      {!this.state.isTabOneSelected && (
                        <Button
                          onClick={() =>
                            this.setState({
                              isTabOneSelected: !this.state.isTabOneSelected,
                            })
                          }
                        >
                          <ArrowBackIcon />
                        </Button>
                      )}
                      {this.datePicker("eStartDate", "eEndDate")}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Paper className={classes.root}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            {this.state.EmployeeLeaveTableColumns.map(
                              (col, i) => {
                                return (
                                  <TableCell key={col + i}>{col}</TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.AllEmployeesLeaves.length > 0 &&
                            this.state.AllEmployeesLeaves.map((data) => {
                              if (data.Status !== 6) {
                                return this.state.permisssionsStatus[
                                  data.ID
                                ] !== 6 ? (
                                  <TableRow key={data.ID}>
                                    <TableCell>
                                      {data.EmpCodeAppliedByName}
                                    </TableCell>
                                    <TableCell>
                                      {data.FromDate.split("T")[0]}
                                    </TableCell>
                                    <TableCell>
                                      {data.ToDate.split("T")[0]}
                                    </TableCell>
                                    {this.handleLeaveStatusTableCell(data)}
                                    <TableCell>
                                      <div style={{ display: "flex" }}>
                                        {data.Status === 5 &&
                                          this.state.markPresentPermissionStatus[
                                          data.ID
                                          ] === undefined ? (
                                          <Button
                                            variant="contained"
                                            className={classes.approveButton}
                                            onClick={(event) =>
                                              this.onAlertHandler(
                                                "markPresent",
                                                data,
                                                "Are you sure to mark present"
                                              )
                                            }
                                          >
                                            Mark Present
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                        {data.Status === 1 &&
                                          this.state.permisssionsStatus[
                                          data.ID
                                          ] === undefined ? (
                                          <Button
                                            variant="contained"
                                            className={classes.approveButton}
                                            onClick={(event) =>
                                              this.onAlertHandler(
                                                "Approve",
                                                data,
                                                "Are you sure to approve leave"
                                              )
                                            }
                                          >
                                            Approve
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                        {this.state.rejectionPermissionStatus[
                                          data.ID
                                        ] === undefined &&
                                          (data.Status === 1 ||
                                            data.Status === 2) ? (
                                          <Button
                                            variant="contained"
                                            className={classes.rejectButton}
                                            onClick={(event) =>
                                              this.onAlertHandler(
                                                "Rejected",
                                                data,
                                                "Are you sure to reject leave"
                                              )
                                            }
                                          >
                                            Reject
                                          </Button>
                                        ) : (
                                          ""
                                        )}

                                        <Button
                                          variant="contained"
                                          className={classes.viewDetailtButton}
                                          onClick={() =>
                                            this.onClickHandler(data)
                                          }
                                        >
                                          View Detail
                                        </Button>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ) : null;
                              } else {
                                return null; // return null for leaves with Status === 6
                              }
                            })}
                        </TableBody>
                      </Table>
                      <div ref={this.allLeavesloadingRef}></div>
                    </Paper>
                  </Grid>
                </TabContainer>
              )}
              {tabValue === "yourAppliedLeaves" && (
                <TabContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} className={classes.filterDiv}>
                      {this.datePicker("yStartDate", "yEndDate")}
                    </Grid>
                    <Grid align="right" item xs={6} sm={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.addLeave()}
                      >
                        <i className={`${classes.leftIcon} material-icons`}>
                          directions_run
                        </i>{" "}
                        Apply Leave
                      </Button>
                    </Grid>
                  </Grid>
                  <Paper className={classes.root}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {this.state.AppliedLeaveTableColumns.map((col, i) => {
                            return <TableCell key={col + i}>{col}</TableCell>;
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.EmployeeLeaves.length ? (
                          this.state.EmployeeLeaves.filter(
                            (employee) =>
                              employee.EmpCode ===
                              this.state.loggedInUserEmpCode &&
                              employee.FromDate.split("T")[0] >=
                              this.state.yStartDate.format("YYYY-MM-DD") &&
                              employee.ToDate.split("T")[0] <=
                              this.state.yEndDate.format("YYYY-MM-DD")
                          ).map((data) => {
                            return (
                              <TableRow key={data.ID}>
                                <TableCell>
                                  {data.EmpCodeAppliedByName}
                                </TableCell>
                                <TableCell>
                                  {data.FromDate.split("T")[0]}
                                </TableCell>
                                <TableCell>
                                  {data.ToDate.split("T")[0]}
                                </TableCell>
                                {this.handleLeaveStatusTableCell(data)}
                                {this.handleCancelButtonTableCell(
                                  data,
                                  classes
                                )}
                              </TableRow>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                    <div ref={this.appliedLeavesLoadingRef}></div>
                  </Paper>
                </TabContainer>
              )}
              {tabValue === "leavesForYourApproval" && (
                <TabContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} className={classes.filterDiv}>
                      {this.datePicker("lStartDate", "lEndDate")}
                    </Grid>
                  </Grid>
                  <Paper className={classes.root}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {this.state.YourApprovalEmployeeLeaveTableColumns.map(
                            (col, i) => {
                              return <TableCell key={col + i}>{col}</TableCell>;
                            }
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.loggedInUserRequestApprovalLeavesFromAPI
                          .length ? (
                          this.state.loggedInUserRequestApprovalLeavesFromAPI
                            .filter(
                              (data) =>
                                data.FromDate.split("T")[0] >=
                                this.state.lStartDate.format("YYYY-MM-DD") &&
                                data.ToDate.split("T")[0] <=
                                this.state.lEndDate.format("YYYY-MM-DD")
                            )
                            .map((data) => {
                              return (
                                <TableRow key={data.ID}>
                                  <TableCell>
                                    {data.EmpCodeAppliedByName}
                                  </TableCell>
                                  <TableCell>
                                    {data.FromDate.split("T")[0]}
                                  </TableCell>
                                  <TableCell>
                                    {data.ToDate.split("T")[0]}
                                  </TableCell>
                                  {this.handleLeaveStatusTableCell(data)}
                                  <TableCell>
                                    {
                                      <div style={{ display: "flex" }}>
                                        {data.Status === 1 &&
                                          this.state.permisssionsStatus[
                                          data.ID
                                          ] === undefined ? (
                                          <Button
                                            variant="contained"
                                            className={classes.approveButton}
                                            onClick={(event) =>
                                              this.onAlertHandler(
                                                "Approve",
                                                data,
                                                "Are you sure to approve leave"
                                              )
                                            }
                                          >
                                            Approve
                                          </Button>
                                        ) : (
                                          ""
                                        )}

                                        {/* <p> / </p> */}
                                        {this.state.rejectionPermissionStatus[
                                          data.ID
                                        ] === undefined &&
                                          (data.Status === 1 ||
                                            data.Status === 2) ? (
                                          <Button
                                            variant="contained"
                                            className={classes.rejectButton}
                                            onClick={(event) =>
                                              this.onAlertHandler(
                                                "Rejected",
                                                data,
                                                "Are you sure to reject leave"
                                              )
                                            }
                                          >
                                            Reject
                                          </Button>
                                        ) : (
                                          ""
                                        )}

                                        <Button
                                          variant="contained"
                                          className={classes.viewDetailtButton}
                                          onClick={() =>
                                            this.onClickHandler(data)
                                          }
                                        >
                                          {" "}
                                          View Detail
                                        </Button>
                                      </div>
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                    <div ref={this.approvalLeavesLoadingRef}></div>
                  </Paper>
                </TabContainer>
              )}
              {tabValue === "holidayList" && (
                <TabContainer>
                  <Grid container spacing={2} className={classes.filterDiv}>
                    <Grid item xs={12} sm={4}>
                      <Select
                        variant="outlined"
                        displayEmpty={true}
                        width="fullWidth"
                        value={this.state.currentYear}
                        onChange={(e) =>
                          this.setState({
                            currentYear: e.target.value,
                          })
                        }
                      >
                        {this.state.holidayYears.map((data) => {
                          return (
                            <MenuItem key={data.YEAR} value={data.YEAR}>
                              {data.YEAR}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                  <Paper className={classes.root}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {this.state.HolidayListColumns.map((col, i) => {
                            return <TableCell key={col + i}>{col}</TableCell>;
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.holidayList.map((data) => {
                          return (
                            <TableRow>
                              <TableCell>
                                {moment(data.HolidayDate).format("DD-MMM")}
                              </TableCell>
                              <TableCell>
                                {moment(data.HolidayDate).format("dddd")}
                              </TableCell>
                              <TableCell>{data.HolidayName}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                </TabContainer>
              )}
            </div>
          </Paper>
        ) : (
          ""
        )}
        <Footer />
      </div>
    );
  }
}
export default withStyles(styles)(Leaves);
