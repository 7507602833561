import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  makeStyles,
  Card,
  CardMedia,
  Typography,
  Paper,
} from '@material-ui/core';
import webinar from '../images/webpage-browser-digital-icon-symbols-concept.jpg';
import staticData from './static_data.json';

const useStyles = makeStyles(() => ({
  section_two_new_line: {
    color: '#333333',
    fontWeight: 300,
    fontSize: '18px',
    whiteSpace: 'pre-line',
  },
}))

const SectionTwo = () => {
  const {
    section_two_new_line,
  } = useStyles();

  const [cardMaxWidth, setCardMaxWidth] = useState('1320px');

  useEffect(() => {
    const setResponsiveness = () => {
      if (window.innerWidth < 990) {
        setCardMaxWidth('720px')
      }
      if (window.innerWidth < 1200) {
        setCardMaxWidth('960px')
      } else if (window.innerWidth < 1400) {
        setCardMaxWidth('1140px')
      } else {
        setCardMaxWidth('1320px')
      }
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const CustomCardMedia = (
    <CardMedia
      component="img"
      style={{ width: 500, alignSelf: 'flex-start' }}
      image={webinar}
      alt="Live from space album cover"
      width='200px !important'
      height='340em'
    />
  )

  return (
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        boxShadow: 0,
      }}
      style={{
        width: cardMaxWidth,
        maxWidth: '1320px',
        boxShadow: 'none',
      }}
    >
      <Grid container
        spacing={{ xs: 3, sm: 3, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12 }}
      >
        <Box item component={Grid} xs={12} sm={12} md={6} display={{ xs: "none", md: "block" }} >
          <Card style={{
            boxShadow: 'none',
            display: "flex",
            justifyContent: "center"
          }}>
            {CustomCardMedia}
          </Card>
        </Box>
        <Grid item xs={12} sm={12} md={6} container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs style={{ margin: '0 25px' }}>
              <Typography component="div" variant="h3">
                {staticData.section_2.heading}
              </Typography>
              <Typography variant="p" component="div" className={section_two_new_line}>
                {staticData.section_2.content}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SectionTwo