import React, { useState, useEffect } from "react";
import Const from "../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Footer from "../common/footer";
import { Link } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CryptoJS from "crypto-js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  action: {
    cursor: "pointer",
    margin: 2,
  },
  editAction: {
    color: "#00a9f4",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  button: {
    backgroundColor: "#fb7813 !important",
  },
  cardLayout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  tableCell: {
    width: "20%",
  },
  innerTableCell: {
    border: 0,
  },
});

const DocumentsPanel = (props) => {
  const [state, setState] = useState({
    Banks: [],
    bankUsed: false,
    listView: false,
    OrganizationID: JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).OrganizationID,
  });
  const [documentList, setDocumentList] = useState({});
  const [uploadStatus, setUploadStatus] = useState(false);

  const { classes, EmpCode, isUpdateMoreDocuments } = props;

  useEffect(() => {
    var body = {
      OrganizationID: state.OrganizationID,
    };
    props.loader(true);
    fetch(Const.API_ROOT + Const.GET_BANKS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        props.loader(false);
        setState({
          ...state,
          Banks: responseJson,
        });
      })
      .catch((error) => {
        props.loader(false);
        console.error(error);
      });
  }, []);

  useEffect(() => {
    fetch(`${Const.API_ROOT}${Const.GET_FILES}/${EmpCode}`)
      .then((response) => response.json())
      .then((response) => {
        setDocumentList(response);
      });
  }, [uploadStatus]);

  const handleInputChange = async (event) => {
    event.preventDefault();
    const imgCollection = event.target.files;

    let formData = new FormData();
    try {
      for (const key of Object.keys(imgCollection)) {
        formData.append("imgCollection", imgCollection[key]);
      }
    } catch (ex) {
      console.log("[EXCEPTION] Formdata: ", ex);
    }

    var requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    await fetch(
      `${Const.API_ROOT}${Const.UPLOAD_FILE}/${EmpCode}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 500) {
          return NotificationManager.error(
            "Invalid format: upload failed",
            "Failure",
            3000
          );
        }
        NotificationManager.success(
          "File(s) uploaded successfully",
          "Success",
          3000
        );
        setUploadStatus(!uploadStatus);
      })
      .catch((error) => {
        NotificationManager.error("File(s) upload failed", "Failure", 3000);
      });
  };

  const handleDelete = async (event, doc) => {
    event.preventDefault();
    const fileName = doc.name;
    const requestOptions = {
      method: "DELETE",
    };

    await fetch(
      `${Const.API_ROOT}${Const.DELETE_FILE}/${EmpCode}/${fileName}`,
      requestOptions
    )
      .then((response) => {
        NotificationManager.success(
          "File Deleted successfully",
          "Success",
          3000
        );
        setUploadStatus(!uploadStatus);
      })
      .catch((error) => {
        NotificationManager.error("File Delete failed", "Failure", 3000);
      });
  };

  return (
    <div>
      {isUpdateMoreDocuments && (
        <Paper className={classes.paper}>
          <input
            accept="image/*,.pdf,.doc,.docx"
            type="file"
            id="upload_files"
            style={{ display: "none" }}
            onChange={handleInputChange}
            multiple
          />
          <label htmlFor="upload_files">
            <Button
              component="span"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Upload Files
            </Button>
          </label>
        </Paper>
      )}

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Filename</TableCell>
              <TableCell className={classes.tableCell} align="right">
                Uploaded Date
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                File Size
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>

        {documentList.length > 0 &&
          documentList
            .filter((doc) => doc.name !== `${EmpCode}.jpeg`)
            .map((doc) => {
              let extension = doc.extension.slice(1);
              if (extension === "jpeg") {
                extension = "jpg";
              } else if (extension === "docx") {
                extension = "doc";
              } else if (
                extension !== "png" &&
                extension !== "doc" &&
                extension !== "docx" &&
                extension !== "jpeg" &&
                extension !== "jpg" &&
                extension !== "pdf" &&
                extension !== "gif"
              ) {
                extension = "file";
              }
              return (
                <Link
                  key={doc.name}
                  underline="none"
                  href={doc.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow hover="true">
                        <TableCell className={classes.tableCell}>
                          <TableRow>
                            <TableCell className={classes.innerTableCell}>
                              <Avatar
                                alt="extension"
                                src={require(`../images/fileFormats/image-${extension}.png`)}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.innerTableCell}
                            >
                              {doc.name}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          {doc.uploadedDate}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          {doc.fileSize}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          <Button onClick={(event) => handleDelete(event, doc)}>
                            <DeleteIcon>
                              <i className="material-icons" aria-hidden="true">
                                delete
                              </i>
                            </DeleteIcon>
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Link>
              );
            })}
      </Paper>

      <NotificationContainer />
      <Footer />
    </div>
  );
};

export default withStyles(styles)(DocumentsPanel);
