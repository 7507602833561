import React, { useState, useEffect } from "react";
import Const from "../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddAppraisal from "./add-appraisal";
import CryptoJS from "crypto-js";

const styles = () => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  button: {
    backgroundColor: "#fb7813 !important",
  },
  tableCell: {
    width: "20%",
  },
  editAction: {
    color: "#666",
  },
  custCardActionButton: {
    width: "50% !important",
    borderRadius: 0,
    margin: 0,
    color: "#444",
  },
  display: {
    display: "flex",
  },
});

const AppraisalsPanel = (props) => {
  const [appraisalData, setappraisalData] = useState([]);
  const [showAppraisalForm, setshowAppraisalForm] = useState(false);
  const [editAppraisalData, seteditAppraisalData] = useState({});

  useEffect(() => {
    loadAppraisalData();
  }, []);

  const loadAppraisalData = () => {
    let empCode = CryptoJS.AES.encrypt(
      EmpCode,
      process.env.REACT_APP_SECRET_KEY
    ).toString();
    let body = {
      Param: empCode,
    };
    fetch(Const.API_ROOT + Const.GET_EMPLOYEE_APPRAISALS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        setappraisalData(response);
      })
      .then((error) => {
        console.log(error);
      });
  };

  const id = props.match.params.id;
  const { classes, DesignationList, EmployeeId, EmpCode, redirectToAppraisal } =
    props;

  const hideAppraisalForm = () => {
    setshowAppraisalForm(false);
  };

  const deleteAppraisal = (id, index) => {
    let body = {
      ID: id,
    };
    fetch(Const.API_ROOT + Const.DELETE_EMPLOYEE_APPRAISAL, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        appraisalData.slice(index, 1);
        loadAppraisalData();
      })
      .then((error) => {
        console.log(error);
      });
  };

  const editAppraisal = (id, index) => {
    let data = appraisalData[index];
    seteditAppraisalData(data);
    setshowAppraisalForm(true);
  };

  const addAppraisal = () => {
    seteditAppraisalData({});
    setshowAppraisalForm(true);
  };
  return (
    <div>
      {showAppraisalForm ? (
        <AddAppraisal
          editAppraisalData={editAppraisalData}
          appraisalData={appraisalData}
          DesignationId={props.DesignationId}
          DesignationList={DesignationList}
          EmployeeId={EmployeeId}
          EmpCode={EmpCode}
          redirectToAppraisal={redirectToAppraisal}
          hideAppraisalForm={hideAppraisalForm}
          loadAppraisalData={loadAppraisalData}
        />
      ) : (
        <>
          {JSON.parse(
            CryptoJS.AES.decrypt(
              localStorage.getItem("Item"),
              process.env.REACT_APP_SECRET_KEY
            ).toString(CryptoJS.enc.Utf8)
          ).RoleId === 1 && props.location.pathname !== "/dashboard/profile" ? (
            <Paper className={classes.paper}>
              <Button
                component="span"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  addAppraisal(id);
                }}
              >
                Add Appraisal
              </Button>
            </Paper>
          ) : (
            ""
          )}
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    Last Designation
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Appraised Designation
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Last Amount
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Appraised Amount
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Appraisal Date
                  </TableCell>
                  {JSON.parse(
                    CryptoJS.AES.decrypt(
                      localStorage.getItem("Item"),
                      process.env.REACT_APP_SECRET_KEY
                    ).toString(CryptoJS.enc.Utf8)
                  ).RoleId === 1 &&
                  props.location.pathname !== "/dashboard/profile" ? (
                    <TableCell className={classes.tableCell} align="center">
                      Action
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {appraisalData.map((elm, index) => {
                  return (
                    <TableRow key={elm.ID}>
                      <TableCell align="left">
                        {DesignationList.find(
                          (e) => e.DesignationId === elm.CurrentDesignation
                        )
                          ? DesignationList[elm.CurrentDesignation - 1].Name
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {DesignationList.find(
                          (e) => e.DesignationId === elm.NewDesignation
                        )
                          ? DesignationList[elm.NewDesignation - 1].Name
                          : ""}
                      </TableCell>
                      <TableCell align="center">{elm.CurrentSalary}</TableCell>
                      <TableCell align="center">{elm.NewSalary}</TableCell>
                      <TableCell align="center">
                        {elm.AppraisalDate.split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-")}
                      </TableCell>
                      {JSON.parse(
                        CryptoJS.AES.decrypt(
                          localStorage.getItem("Item"),
                          process.env.REACT_APP_SECRET_KEY
                        ).toString(CryptoJS.enc.Utf8)
                      ).RoleId === 1 &&
                      props.location.pathname !== "/dashboard/profile" ? (
                        <TableCell className={classes.display}>
                          <Button
                            className={classes.custCardActionButton}
                            size="small"
                            color="primary"
                            onClick={() => editAppraisal(elm.ID, index)}
                          >
                            <i
                              className={`${classes.editAction} material-icons`}
                              aria-hidden="true"
                            >
                              edit
                            </i>
                          </Button>
                          <Button
                            className={classes.custCardActionButton}
                            size="small"
                            color="primary"
                            onClick={() => deleteAppraisal(elm.ID, index)}
                          >
                            <i
                              className={`${classes.deleteAction} material-icons`}
                              aria-hidden="true"
                            >
                              delete
                            </i>
                          </Button>
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(AppraisalsPanel);
