import React from 'react'
import {
  Box,
  Grid,
  makeStyles
} from '@material-ui/core';
import staticData from './static_data.json';
import { SectionThreeGrid } from './SectionsGrid';
const useStyles = makeStyles(() => ({
  section_heading: {
    fontSize: "32px",
    color: '#FFF'
  },
  section_three_heading: {
    width: '100%',
    textAlign: 'center',
    color: '#FFF',
    margin: '2em 0',
  },
  section_three_grid: {
    textAlign: '-webkit-center',
    margin: '0 0 6em 0'
  },
  section_three_grid_box: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '1320px',
    textAlign: 'start !important',
    position: 'relative',
    zIndex: 2,
  },
  section_three_overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
    position: 'absolute',
    pointerEvents: 'none',
    minHeight: '100%',
    minWidth: '100%',
  },
}))

const SectionThree = () => {
  const {
    section_heading,
    section_three_overlay,
    section_three_heading,
  } = useStyles();

  return (
    <>
      <Grid className={section_three_overlay}></Grid>
      <Box>
        <Grid container style={{
          overflow: 'auto', position: 'relative',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '8em'
        }}
        >
          <h4 className={`${section_heading} ${section_three_heading}`}>
            {staticData.section_3.heading}
          </h4>
          <Box sx={{ 
            flexGrow: 1, 
            maxWidth: '1320px', 
            padding: '0 44px' 
            }}>
            <Grid
              container
              spacing={{ xs: 1, sm: 2, md: 3 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
              // alignItems="stretch"
            >
              {staticData.section_3.content.map((info, index) => (
                <Grid item xs={12} sm={12} md={6} key={index} style={{display: 'flex'}}>
                  <SectionThreeGrid info={info} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export default SectionThree