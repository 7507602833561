import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  ST_card_container: {
    overflow: 'unset',
    padding: 0,

  },
  ST_title: {
    fontSize: "32px",
    margin: '17px 0 37px 0',
    borderBottom: '1px solid #80808059',
    paddingBottom: '1em',
  },
  ST_points_container: {
    padding: 0,
  },
  ST_points: {
    paddingLeft: '15px',
    fontSize: '18px'
  },
  SF_title: {
    fontSize: "32px",
    padding: '0 1em'
  },
  SF_para: {
    fontWeight: 300,
    fontSize: "18px",
  },
});
export const SectionThreeGrid = props => {
  const classes = useStyles();

  const { info } = props;
  const { points, title } = info;
  return (
    <Card style={{
      minWidth: 275,
      // maxWidth: "100%",
      overflow: 'unset',
      padding: '30px 40px 0',
      position: 'relative',
      maxWidth: '95%',
      margin: '20px'
    }}>
      <CardContent className={classes.ST_card_container}>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          <h4 className={classes.ST_title}>
            {title}
          </h4>
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" className={classes.ST_points_container}>
          <ul className={classes.ST_points}>
            {points.map(point => (
              <li>{point}</li>
            ))}
          </ul>
        </Typography>
      </CardContent>
    </Card>
  )
};

export const SectionFourGrid = props => {
  const classes = useStyles();

  const { info } = props;
  const { para, title } = info;
  return (
    <Card style={{
      minWidth: 275,
      maxWidth: "100%",
      overflow: 'unset',
      padding: '30px 40px 40px 40px',
      position: 'relative',
      textAlign: 'center',
      margin: '1em'
    }}>
      <CardContent className={classes.ST_card_container}>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          <h4 className={classes.SF_title}>
            {title}
          </h4>
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" className={classes.SF_para}>
          <p>{para}</p>
        </Typography>
      </CardContent>
    </Card>
  )
};
