import React, { Component } from "react";
import Const from "../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CardActions from "@material-ui/core/CardActions";
import Footer from "../common/footer";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";
import ReadMoreReact from "read-more-react";
import CryptoJS from "crypto-js";
var controller;
var signal;
const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },

  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  action: {
    cursor: "pointer",
    margin: 2,
  },
  deleteAction: {
    color: "#666",
  },
  editAction: {
    color: "#666",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  button: {
    backgroundColor: "#fb7813 !important",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  di: {
    display: "inline",
    float: "left",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: "95%",
    // marginTop: 16
  },
  EmployeesFormControl176: {
    marginTop: 100,
  },
  sortableIcon: {
    position: "relative",
    paddingRight: "30px !important",
  },
  sortableIconContiner: {
    margin: 0,
    //position: 'absolute',
    top: "50%",
    display: "inline-block",
    //right: 0,
    cursor: "pointer",
  },
  sortableIconItem: {
    fontWeight: "bold",
    fontSize: 15,
    verticalAlign: "middle",
  },

  paddingRight10: {
    paddingRight: 10,
  },
  sortingDetail: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  textLeft: {
    textAlign: "left",
  },
  marginLeft20: {
    marginLeft: 20,
  },
  custFlex: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  inlineSpan: {
    display: "inline-block",
    width: "32%",
    marginRight: "1%",
    textAlign: "center",
    verticalAlign: "top",
    padding: 5,
    fontSize: 13,
  },
  seprator: {
    textAlign: "center",
    margin: "15px 0",
    position: "relative",
  },
  sepratorSpan: {
    color: "#fff",
    background: "#fb7813",
    padding: " 0px 10px",
    borderRadius: "8px",
    height: "25px",
    display: "inline-block",
    lineHeight: "24px",
    position: "relative",
    zIndex: 1,
    fontSize: 14,
  },
  sepratorBorder: {
    background: "#fb7813",

    position: "absolute",
    height: "1px",
    left: "15px",
    right: "15px",
    top: "50%",
  },
  positionR: { position: "relative" },
  alignItemsCenter: { alignItems: "center" },
  avatarImage: { width: "100% !important", height: "auto !important" },
  customCard: { height: "auto", minHeight: 150 },
  tskName: {
    fontSize: 14,
    color: "#3b6978",
    marginTop: 17,
    fontWeight: "bold",
    maxHeight: "70px",
    overflow: "hidden",
  },
  proName: {
    fontSize: 16,
    color: "#3b6978",
    marginTop: 10,
    marginLeft: "25%",
    textAlign: "center",
  },
  statusStyle: { fontSize: 16, textAlign: "left" },
  estimated: {
    fontSize: 14,
    color: "#3b6978",
  },
  consumed: {
    fontSize: 14,
    color: "#3b6978",
  },
  seprator: {
    margin: "15px 0",
    position: "relative",
  },
  sepratorSpan: {
    color: "#fff",
    background: "#fb7813",
    padding: " 0px 10px",
    borderRadius: "8px",
    height: "25px",
    display: "inline-block",
    lineHeight: "24px",
    position: "relative",
    zIndex: 1,
    fontSize: 14,
    marginLeft: "40%",
  },
  sepratorBorder: {
    background: "#fb7813",

    position: "absolute",
    height: "1px",
    left: "30px",
    right: "-55px",
    top: "50%",
  },
  nm: { margin: "0 !important" },
  values: { position: "relative", marginTop: "10px" },
  custCardAction: {
    padding: "0 !important",
    borderTop: "1px solid #fb7813",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
  custCardActionButton: {
    width: "50% !important",
    borderRadius: 0,
    margin: 0,
    color: "#444",
  },
  taskCardActionButton: {
    width: "50% !important",
    borderRadius: 0,
    margin: 0,
    color: "#444",
    marginLeft: "190px",
  },
  rightSepratorContainer: {
    position: "relative",
    display: "inline-block",
    verticalAlign: "middle",
  },
  rightSeprator: {
    position: "absolute",
    width: 2,
    height: 50,
    background: "#c0c0c0",
    top: "50%",
    transform: "translateY(-50%)",
  },
  valueTitle: { fontWeight: 500 },
  selectorElement: { position: "relative" },
  bgroup: { position: "absolute", top: 5, left: 10, fontSize: 15 },
  heightFix: { minHeight: 200, position: "relative", marginLeft: "15px" },
  bgroupImg: {
    width: 10,
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: 5,
  },
  bgroupData: {
    display: "inline-block",
    verticalAlign: "middle",
    fontSize: 14,
  },
  valueData: {
    color: "#6f6f6f",
  },
  lineClump: {
    // display:"-webkit-box",
    // maxHeight:"20px",
    // overflowX:"hidden"
  },
});
const selectList = [
  {
    id: 3,
    name: "All",
  },
  {
    id: 0,
    name: "Not Yet Started",
  },
  {
    id: 1,
    name: "Completed",
  },
  {
    id: 2,
    name: "In Progress",
  },
];
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllTasks: [],
      Tasks: [],
      columns: [],
      filterBy: "",
      filterByStatus: 3,
      filterValue: "",
      filterProjectValue: "",
      sortBy: "",
      listView: false,
      projectList: [],
      ConsumedHoursWithTasks: [],
      projectToDoId: this.props.match.params.id,
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      roleId: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).RoleId,
    };
  }
  componentWillUnmount() {
    controller.abort();
  }

  componentWillMount() {
    controller = new AbortController();
    signal = controller.signal;
    this.props.loader(true);
    this.props.headerTitle("Todo List", 4);
    if (this.state.projectToDoId) {
      localStorage.setItem("project", JSON.stringify(this.state.projectToDoId));
      this.setState({ filterProjectValue: parseInt(this.state.projectToDoId) });
      this.getProjectsList();
      this.getAllToDoListByProjectId(this.state.projectToDoId);
    } else {
      localStorage.setItem("project", JSON.stringify(null));
      NotificationManager.success(
        "Please select project from Drop Down List to display ToDos",
        "Success",
        4000
      );
      this.getProjectsList();
      this.props.loader(false);
    }
  }

  getAllToDoListByProjectId = (projectId) => {
    setTimeout(
      function () {
        console.clear();
        var body = {
          OrganizationID: this.state.OrganizationID,
          ProjectId: projectId,
        };
        fetch(Const.API_ROOT + Const.GET_ALL_TODO_LIST, {
          method: "POST",
          headers: Const.API_HEADER,
          body: JSON.stringify(body),
          signal: signal,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.tasks.length === 0) {
              NotificationManager.error("", "No ToDos  Found for this project");
            }
            this.props.loader(false);
            this.setState({
              Tasks: responseJson.tasks,
              AllTasks: responseJson.tasks,
              columns: responseJson.defaultColumns,
              ConsumedHoursWithTasks: responseJson.consumedHoursWithTasks,
              ConsumedHoursWithTasksList: responseJson.consumedHoursWithTasks,
              filterBy: responseJson.defaultColumns[0].ColumnName,
              sortBy: responseJson.defaultColumns[0].ColumnName,
            });
            this.filterOnlyStatus(this.state.filterValue);
          })
          .catch((error) => {
            this.props.loader(false);
            console.error(error);
          });
      }.bind(this),
      1000
    );
  };

  getProjectsList = () => {
    const code = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).EmpCode;
    let endPoints;
    var body;
    if (this.state.roleId === 1) {
      body = {
        OrganizationID: this.state.OrganizationID,
        ProjectId: 0,
      };
      endPoints = Const.GET_AllPROJECTS;
    } else {
      body = {
        EmpCode: code,
      };
      endPoints = Const.GET_USER_PROJECTS;
    }
    setTimeout(
      function () {
        console.clear();
        fetch(Const.API_ROOT + endPoints, {
          method: "POST",
          headers: Const.API_HEADER,
          body: JSON.stringify(body),
          signal: signal,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            this.props.loader(false);
            this.setState({
              projectList:
                JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).RoleId === 1
                  ? responseJson
                  : responseJson.projects,
            });
          })
          .catch((error) => {
            this.props.loader(false);
            console.error(error);
          });
      }.bind(this),
      1000
    );
  };

  Transition(props) {
    return <Slide direction="up" {...props} />;
  }
  editTodo = (TodoId) => {
    this.props.history.push("/dashboard/add-todo/" + TodoId);
  };

  addTodo() {
    this.props.history.push("/dashboard/add-todo");
  }

  handleProjectFilter = (event) => {
    this.setState({ filterProjectValue: event.target.value });
    this.props.loader(true);
    const projectIdFromDropDown = event.target.value;
    localStorage.setItem("project", JSON.stringify(event.target.value));
    this.getAllToDoListByProjectId(projectIdFromDropDown);
  };

  handleFilterByChange = (event) => {
    this.setState({
      filterValue: event.target.value,
    });
    this.filterOnlyStatus(event.target.value);
  };

  filterOnlyStatus(value) {
    var val = value;
    var filteredRecords;
    if (val !== null) {
      if (val === 0) {
        filteredRecords = this.state.ConsumedHoursWithTasksList.filter(
          (project) => project.Status === 0
        );
      } else if (val === 1) {
        filteredRecords = this.state.ConsumedHoursWithTasksList.filter(
          (project) => project.Status === 1
        );
      } else if (val === 2) {
        filteredRecords = this.state.ConsumedHoursWithTasksList.filter(
          (project) => project.Status === 2
        );
      } else if (val === 3) {
        filteredRecords = this.state.ConsumedHoursWithTasksList;
      }
    }

    this.setState({
      ConsumedHoursWithTasks: filteredRecords,
      filterByStatus: value,
    });
  }

  checkData = (data, column) => {
    var val = data[column];
    if (val === null) {
      return "N/A";
    } else if (column === "Status") {
      if (val === 0) {
        return "Not Yet Started";
      } else if (val === 1) {
        return "Completed";
      } else if (val === 2) {
        return "In Progress";
      }
    } else return val;
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} className={classes.textLeft}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={4} className={classes.textLeft}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel htmlFor="filter-by-simple">
                      {" "}
                      Search by Project
                    </InputLabel>
                    <Select
                      value={this.state.filterProjectValue}
                      onChange={this.handleProjectFilter}
                      input={
                        <FilledInput name="filter-by" id="filter-by-simple" />
                      }
                      className={classes.textLeft}
                    >
                      {this.state.projectList &&
                        this.state.projectList.map((col, ind) => {
                          return (
                            <MenuItem value={col.ProjectId} key={ind}>
                              {col.Name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={4} className={classes.textLeft}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel htmlFor="filter-by-simple">
                      {" "}
                      Search by Status
                    </InputLabel>
                    <Select
                      disabled={this.state.filterProjectValue === ""}
                      defaultValue={this.state.filterByStatus}
                      onChange={this.handleFilterByChange}
                      input={
                        <FilledInput name="filter-by" id="filter-by-simple" />
                      }
                      className={classes.textLeft}
                    >
                      {selectList.map((col, ind) => {
                        return (
                          <MenuItem value={col.id} key={ind}>
                            {col.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.custFlex}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.listView}
                    onChange={(e) =>
                      this.setState({
                        listView: !this.state.listView,
                      })
                    }
                    value={this.state.listView}
                  />
                }
                label={!this.state.listView ? "Tiles View" : "List View"}
              />
              {this.state.roleId === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.addTodo()}
                >
                  <i className={`${classes.leftIcon} material-icons `}>
                    grading
                  </i>{" "}
                  + Add new ToDo's
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
        {this.state.listView ? (
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.state.columns.map((col, i) => {
                    return (
                      <TableCell align="center" key={i}>
                        {col.ColumnName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.ConsumedHoursWithTasks &&
                  this.state.ConsumedHoursWithTasks.map((n) => {
                    return (
                      <TableRow
                        className={classes.tableRowHover}
                        key={n.TaskId}
                      >
                        {this.state.columns.map((col, i) => {
                          return (
                            <TableCell align="center" key={i}>
                              <div
                                className={classes.lineClump}
                                textOverflow="ellipsis"
                              >
                                {this.checkData(n, col.ColumnName)}
                              </div>
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          <span
                            className={classes.action}
                            onClick={() => this.editTodo(n.TaskId)}
                          >
                            <i
                              className={`${classes.editAction} material-icons`}
                              aria-hidden="true"
                            >
                              edit
                            </i>
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Grid container spacing={2}>
            {this.state.ConsumedHoursWithTasks &&
              this.state.ConsumedHoursWithTasks.map((data, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={i}
                    className={classes.selectorElement}
                  >
                    <Card className={`${classes.card}  ${classes.heightFix}`}>
                      <CardContent>
                        <Grid container className={classes.alignItemsCenter}>
                          <div className={classes.bgroup}>
                            {data.Status === 0 ? (
                              <span style={{ color: "#F6AA0A" }}>
                                Not Yet Started
                              </span>
                            ) : data.Status === 1 ? (
                              <span style={{ color: "#07B430" }}>
                                Completed
                              </span>
                            ) : data.Status === 2 ? (
                              <span style={{ color: "#FF0C01" }}>
                                In Progress
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <Grid
                            style={{
                              padding: 5,
                              width: "100%",
                              minHeight: "100px",
                              maxHeight: "100px",
                            }}
                            item
                          >
                            <Typography
                              variant="caption"
                              component="h6"
                              className={classes.tskName}
                            >
                              <ReadMoreReact
                                className={classes.ellipsisText}
                                text={data.Name}
                                min={25}
                                ideal={55}
                                max={60}
                                readMoreText={
                                  <span
                                    style={{
                                      color: "royalblue",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    ..ViewMore
                                  </span>
                                }
                              />
                            </Typography>
                            <div className={classes.seprator}>
                              <span></span>
                            </div>
                          </Grid>
                          <div style={{ marginTop: "15px" }}>
                            <Typography
                              align="left"
                              variant="caption"
                              className={classes.consumed}
                              color="textSecondary"
                            >
                              {data.ConsumedBudget} Hours Consumed
                            </Typography>
                          </div>
                        </Grid>
                      </CardContent>
                      <CardActions className={classes.custCardAction}>
                        <Button
                          className={classes.taskCardActionButton}
                          size="small"
                          color="primary"
                          onClick={() => this.editTodo(data.TaskId)}
                        >
                          <i
                            className={`${classes.editAction} material-icons`}
                            aria-hidden="true"
                          >
                            edit
                          </i>
                          &nbsp;Edit
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        )}
        <Footer />
      </div>
    );
  }
}
export default withStyles(styles)(Projects);
