import React, { Component } from "react";
import Const from "../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { DateRangePicker } from "react-dates";
import EmployeesDropDown from "../common/employeeDropDown";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Cell,
  Pie,
} from "recharts";
import { NotificationManager } from "react-notifications";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import Footer from "../common/footer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import getLeavesRemaining from "../../utils/leaveCalucationUtil";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CakeIcon from "@material-ui/icons/Cake";
import Eco from "@material-ui/icons/Accessibility";
import Policy from "@material-ui/icons/Description";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CryptoJS from "crypto-js";
import constant from "../common/constant";
let moment = require("moment");
let controller;
let signal;
const styles = {
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    fontSize: "22px",
    fontWeight: "500",
  },
  button: {
    marginTop: "20px",
    backgroundColor: "#fb7813 !important",
    padding: "10px 30px",
  },
  active: {
    background: "#22b14c",
  },
  active1: {
    background: "#800080",
  },
  active2: {
    background: "#8A2BE2",
  },
  inActive: {
    background: "#00a2e8",
  },
  total: {
    background: "#ff7f27",
  },
  total1: {
    background: "#E21717",
  },
  paper: {
    marginTop: 30,
    padding: 20,
  },
  root: {
    width: 1200,
    height: 600,
    marginTop: 30,
  },
  card: {
    width: 300,
    borderRadius: 10,
  },
  empDate: {
    color: " #000000",
    fontSize: 20,
    fontWeight: 400,
  },
  overflowtext: {
    whiteSpace: "initial",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "3",
  },
  empDate1: {
    color: " #000000",
    display: "flex",
    flexDirection: "column",
    marginRight: "30px",
  },
  heightFix: { width: 300, position: "relative" },
  bgroupImg: {
    height: "auto",
  },
  textDecor: {
    color: " #0097a7",
    fontWeight: 500,
    marginBottom: 8,
    textTransform: "capitalize",
    "&:hover": {
      fontWeight: 500,
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  cardHover: {
    transition: "transform .5s",
    boxShadow: "0 10px 40px 0 rgb(0 0 0 / 12%)",
    overflow: "hidden",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: "0 10px 40px 0 rgb(0 0 0 / 26%)",
    },
  },
  dateContainer: {
    marginBottom: 10,
    fontWeight: 400,
    color: "#444",
    width: "100%",
    border: "1px solid transparent",
  },
  actions: {
    width: "174px",
    height: "85px",
    borderRadius: "30px",
    marginInline: "15px",
  },
  displayTaken: {
    backgroundColor: "#ffc800",
  },
  actionHeader: {
    paddingLeft: "9px",
    paddingBottom: "10px",
    fontWeight: "bolder",
    fontSize: "23px",
  },
  leaveActionHeader: {
    paddingLeft: "9px",
    paddingBottom: "10px",
    fontWeight: "bolder",
    fontSize: "23px",
    paddingTop: "10px",
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "initial",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "3",
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  avatarImage: {
    width: "60px",
    height: "auto !important",
    borderRadius: "50%",
    maxWidth: "120px",
    marginRight: "14px",
  },
  birthdayIcon: { right: "50%", top: "8px" },
  scrollView: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      display: "none",
    },
    "&::-ms-overflow-style": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
};

class Dashboard extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      masterLeaves: [],
      totalEmployees: 0,
      activeEmployees: 0,
      inactiveEmployees: 0,
      pageLoaded: false,
      loadEmployeeGraph: false,
      employeeReport: [],
      employeesTimeSheet: [],
      SelectedDate: moment().format("YYYY-M-DD"),
      SelectedDateForAttendance: moment().subtract(1, "d").format("YYYY-M-DD"),
      selectedValueRadio: "TimeDiff",
      sheetData: [],
      startDate: moment().subtract(7, "d"),
      endDate: moment(),
      attendance: [],
      showAttendanceDialog: false,
      AttendanceDialogData: [],
      AttendanceSelectedForDetails: "",
      holidayTab: "holidayList",
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      JoiningDate: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).JoiningDate,
      Status: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).Status,
      OrganizationSettings: { Shifts: 0, IncludeLogofToIdle: 0 },
      roleId: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).RoleId,
      liveNewsUpdates: [],
      EmpCode: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).EmpCode,
      allBannerImage: {},
      upcomingAppraisals: [],
      upcomingBirthdays: [],
      birthdayProfilePicture: {},
      appraisalProfilePicture: {},
      companyPolicyList: [],
      latestPolicy: null,


    };
  }
  async componentWillMount() {
    controller = new AbortController();
    signal = controller.signal;
    this._isMounted = true;
    this.props.headerTitle("Dashboard", 1);
    this.props.loader(true);
    if (this.state.roleId === 1) {
      this.getEmployeesStatus();
      this.getUpcomingAppraisals();
      this.getUpcomingBirthdays();
    }
    this.getLeaves();
    this.getNewsUpdate();
    // this.getDataFromLocalStorage();
    // get Masterleaves API call
    const masterLeaveResponse = await fetch(
      Const.API_ROOT + Const.GET_LEAVES_SETTINGS,
      {
        method: "GET",
        headers: Const.API_HEADER,
        signal: signal,
      }
    );
    const masterLeaves = await masterLeaveResponse.json();
    this.setState({
      masterLeaves: masterLeaves,
    });
  }

  getLeaves = async () => {
    let Param = CryptoJS.AES.encrypt(
      this.state.EmpCode,
      process.env.REACT_APP_SECRET_KEY
    ).toString();

    let body = { Param: Param };
    const leavesResponse = await fetch(
      Const.API_ROOT + Const.GET_LEAVES_COUNTS,
      {
        method: "POST",
        headers: Const.API_HEADER,
        signal: signal,
        body: JSON.stringify(body),
      }
    );
    const leavesResponseJson = await leavesResponse.json();
    this.setState({
      FullDayLeaveCount: leavesResponseJson.FullDayLeaveCount,
      HalfDayLeaveCount: leavesResponseJson.HalfDayLeaveCount,
    });
    this.props.loader(false);
  };

  checkFullDayLeaveCount = () => {
    const eCode = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).EmpCode;
    let full_day_count = 0;
    const obj =
      this.state.FullDayLeaveCount &&
      this.state.FullDayLeaveCount.find((e) => e.EmpCode === eCode);
    if (obj !== undefined) {
      full_day_count = obj.leavesTaken;
    }
    return full_day_count;
  };
  checkHalfDayLeaveCount = () => {
    const eCode = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).EmpCode;
    let full_day_count = 0;
    const obj =
      this.state.HalfDayLeaveCount &&
      this.state.HalfDayLeaveCount.find((e) => e.EmpCode === eCode);
    if (obj !== undefined) {
      full_day_count = obj.leavesTaken;
    }
    return full_day_count;
  };
  async getUpcomingAppraisals() {
    let body = {
      OrganizationID: this.state.OrganizationID,
    };
    const response = await fetch(
      Const.API_ROOT + Const.GET_UPCOMING_APPRAISALS,
      {
        method: "POST",
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
        signal: signal,
      }
    );
    const responseJson = await response.json();
    const local_profile_pictures = {};
    await Promise.all(
      responseJson.map(async (employee) => {
        const image_url = `${Const.API_ROOT}${Const.SHOW_FILE}/${employee.EmpCode}/${employee.EmpCode}.jpeg`;
        const profile_pictures_response = await fetch(image_url);
        local_profile_pictures[employee.EmpCode] =
          profile_pictures_response.status === 200
            ? image_url
            : require("../images/face1.jpg");
      })
    );
    this.setState({
      appraisalProfilePicture: local_profile_pictures,
    });
    this.setState({ upcomingAppraisals: responseJson });
  }
  async getUpcomingBirthdays() {
    let body = {
      OrganizationID: this.state.OrganizationID,
    };
    const response = await fetch(
      Const.API_ROOT + Const.GET_UPCOMING_BIRTHDAYS,
      {
        method: "POST",
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
        signal: signal,
      }
    );
    const responseJson = await response.json();
    const local_profile_pictures = {};
    await Promise.all(
      responseJson.map(async (employee) => {
        const image_url = `${Const.API_ROOT}${Const.SHOW_FILE}/${employee.EmpCode}/${employee.EmpCode}.jpeg`;
        const profile_pictures_response = await fetch(image_url);
        local_profile_pictures[employee.EmpCode] =
          profile_pictures_response.status === 200
            ? image_url
            : require("../images/face1.jpg");
      })
    );
    this.setState({
      birthdayProfilePicture: local_profile_pictures,
    });
    this.setState({ upcomingBirthdays: responseJson });
  }
  async getNewsUpdate() {
    let body = { defaultColumns: false };
    const response = await fetch(Const.API_ROOT + Const.GET_LIVE_NEWSUPDATES, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    });
    const responseJson = await response.json();
    let dataLiveNewsUpdates = [];
    dataLiveNewsUpdates =
      this.state.roleId === 1
        ? responseJson.liveNewsUpdates.slice(0, 8)
        : responseJson.liveNewsUpdates
          .filter((x) => x.Status === true)
          .slice(0, 8);
    const local_banner_pictures = {};
    await Promise.all(
      dataLiveNewsUpdates.map(async (news) => {
        const image_url = `${Const.API_ROOT}${Const.GET_BANNER_IMAGE}/${news.id}/${news.id}.jpeg`;
        const banner_pictures_response = await fetch(image_url);
        local_banner_pictures[news.id] =
          banner_pictures_response.status === 200 && image_url;
      })
    );
    this.setState({
      liveNewsUpdates: dataLiveNewsUpdates,
      allBannerImage: local_banner_pictures,
    });
  }
  getEmployeesStatus() {
    setTimeout(
      function () {
        let body = {
          OrganizationID: this.state.OrganizationID,
        };

        if (this._isMounted) {
          fetch(Const.API_ROOT + Const.GET_Employees_Status, {
            method: "POST",
            headers: Const.API_HEADER,
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((responseJson) => {
              let active = 0;
              let inactive = 0;
              responseJson.map(function (row) {
                if (row.Status === 0) inactive = row.StatusCount;
                else active = row.StatusCount;
              });

              this.setState({
                totalEmployees: inactive + active,
                inactiveEmployees: inactive,
                activeEmployees: active,
              });
              this.props.loader(false);
              this.getOrganizationSettings(true);
            })
            .catch((error) => {
              console.error(error);
              this.props.loader(false);
              this.getOrganizationSettings(true);
            });
        }
      }.bind(this),
      500
    );
  }
  currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  componentWillUnmount = () => {
    controller.abort();
    this._isMounted = false;
  };
  getParentStatus = () => {
    return this._isMounted;
  };
  selectEmployee(SelectedEmployeeId, label) {
    this.setState(
      {
        SelectedEmployeeId: SelectedEmployeeId,
        selectedEmployeeName: label,
      },
      () => {
        this.getEmployeeReport("", label);
      }
    );
  }
  changeRange(startDate, endDate) {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
      },
      () => {
        if (this.state.SelectedEmployeeId) this.getEmployeeReport();
      }
    );
  }
  getOrganizationSettings(onload) {
    let body = {
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
    };
    this.props.loader(true);
    fetch(Const.API_ROOT + Const.GET_ORGANIZATION_SETTINGS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.loader(false);
        this.setState({
          OrganizationSettings: responseJson["OrganizationSettings"][0],
        });
        let SelectedEmployeeIdForCart = localStorage.getItem(
          "SelectedEmployeeIdForCart"
        );
        if (SelectedEmployeeIdForCart) {
          this.setState(
            {
              SelectedEmployeeId: SelectedEmployeeIdForCart,
            },
            () => {
              this.getEmployeeReport(
                true,
                localStorage.getItem("SelectedEmployeeNameForCart")
              );
            }
          );
        } else {
          this.getTimeSheetData(true);
        }
      })
      .catch((error) => {
        this.props.loader(false);
        let SelectedEmployeeIdForCart = localStorage.getItem(
          "SelectedEmployeeIdForCart"
        );
        if (SelectedEmployeeIdForCart) {
          this.setState(
            {
              SelectedEmployeeId: SelectedEmployeeIdForCart,
            },
            () => {
              this.getEmployeeReport(
                true,
                localStorage.getItem("SelectedEmployeeNameForCart")
              );
            }
          );
        } else {
          this.getTimeSheetData(true);
        }
        console.error(error);
      });
  }

  getEmployeeReport(onload, label) {
    if (!this.state.startDate) {
      NotificationManager.error("Error", "Select start date first!.");
      return false;
    }
    if (!this.state.endDate) {
      NotificationManager.error("Error", "Select end date first!.");
      return false;
    }
    if (!this.state.SelectedEmployeeId) {
      NotificationManager.error("Error", "Select employee  first!.");
      return false;
    }
    let body = {
      OrganizationID: this.state.OrganizationID,
      EmployeeId: this.state.SelectedEmployeeId,
      IncludeLogofToIdle: this.state.OrganizationSettings.IncludeLogofToIdle,
      StartDate: this.state.startDate.format("YYYY-MM-DD") + " ",
      EndDate: this.state.endDate.format("YYYY-MM-DD") + " ",
    };
    this.props.loader(true);
    if (this._isMounted) {
      fetch(Const.API_ROOT + Const.GET_EMPLOYEE_REPORT, {
        method: "POST",
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (onload) this.getTimeSheetDat(onload);
          this.props.loader(false);
          localStorage.setItem(
            "SelectedEmployeeIdForCart",
            this.state.SelectedEmployeeId
          );
          if (label) {
            localStorage.setItem("SelectedEmployeeNameForCart", label);
          }
          this.setEmployeeReportGraph(responseJson);
        })
        .catch(() => {
          this.props.loader(false);
          if (onload) {
            this.getTimeSheetData(onload);
          }
        });
    }
  }
  getTimeSheetData = (onload) => {
    let body = {
      OrganizationID: this.state.OrganizationID,
      DateForTimeSheet: this.state.SelectedDate + " ",
      IncludeLogofToIdle: this.state.OrganizationSettings.IncludeLogofToIdle,
      SelectedEmployeeId: "",
      active: true,
    };
    this.props.loader(true);
    if (this._isMounted) {
      fetch(Const.API_ROOT + Const.GET_TIMING_DATA, {
        method: "POST",
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.props.loader(false);
          if (onload) {
            this.getAttendance();
          }
          this.setState(
            {
              sheetData: responseJson,
            },
            () => {
              this.setTimeSheet();
            }
          );
        })
        .catch((error) => {
          console.error(error);
          if (onload) this.getAttendance();
        });
    }
  };
  getAttendance = () => {
    this.setState({
      attendance: [],
    });
    let body = {
      OrganizationID: this.state.OrganizationID,
      DateForAttendance: this.state.SelectedDateForAttendance,
    };
    this.props.loader(true);
    fetch(Const.API_ROOT + Const.GET_ATTENDANCE, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.loader(false);
        let att = [];
        let presentCount = 0;
        let absentCount = 0;
        let presentEmp = [];
        let absentEmp = [];
        responseJson.map((x) => {
          if (x.Present === 1) {
            presentEmp.push(x.name);
            presentCount++;
          } else if (x.Present === 0) {
            absentEmp.push(x.name);
            absentCount++;
          }
        });
        att.push({
          name: "Present",
          value: presentCount,
          empNames: presentEmp,
          color: "#1bb555",
        });
        att.push({
          name: "Absent",
          value: absentCount,
          empNames: absentEmp,
          color: "#f00b00",
        });
        this.setState({
          attendance: att,
          pageLoaded: true,
        });
      })
      .catch((error) => {
        this.props.loader(false);
        this.setState({
          pageLoaded: true,
        });
      });
  };
  setTimeSheet() {
    let item = {};
    this.setState(
      {
        employeesTimeSheet: [],
        loadTimeSheetGraph: false,
      },
      () => {
        this.state.sheetData.map((data, i) => {
          item = {};
          item["name"] = data.Name;
          if (this.state.selectedValueRadio === "TimeDiff") {
            item["Total"] = this.getHours(data.TimeDiff);
          } else if (this.state.selectedValueRadio === "IdleTime") {
            item["Idle"] = this.getHours(data.IdleTime);
          } else {
            item["Actual"] = this.getHours(data.ActualWork);
          }
          this.state.employeesTimeSheet.push(item);
          return null;
        });
        this.setState({
          employeesTimeSheet: this.state.employeesTimeSheet,
          loadTimeSheetGraph: true,
        });
      }
    );
  }
  setEmployeeReportGraph(responseJson) {
    let item = {};
    this.setState({
      employeeReport: [],
      loadEmployeeGraph: false,
    });
    responseJson.map((data, i) => {
      item = {};
      item["name"] = data.ScreenDate;
      item["Total"] = this.getHours(data.TotalWorkTime);
      item["Idle"] = this.getHours(data.IdleTime);
      item["Actual"] = this.getHours(data.ActualWork);
      this.state.employeeReport.push(item);
      return null;
    });
    this.setState({
      employeeReport: this.state.employeeReport,
      loadEmployeeGraph: true,
    });
  }
  getHours = (minutes) => {
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    if (hours < 0) {
      hours = 0;
    }
    if (minutes < 0) {
      minutes = 0;
    }
    return hours + "." + minutes;
  };
  handleChange = (event) => {
    this.setState({ selectedValueRadio: event.target.value }, () => {
      this.setTimeSheet();
    });
  };
  handleDateChange = (date) => {
    let CahngedDate = moment(date).format("YYYY-M-DD");
    this.setState(
      {
        SelectedDate: CahngedDate,
      },
      () => {
        this.getTimeSheetData();
      }
    );
  };
  handleAttendanceDateChange = (date) => {
    let CahngedDate = moment(date).format("YYYY-M-DD");
    this.setState(
      {
        SelectedDateForAttendance: CahngedDate,
      },
      () => {
        this.getAttendance();
      }
    );
  };
  showAttendanceDialog = (value, index) => {
    let data = this.state.attendance.filter((x) => x.name === value);
    this.setState({
      showAttendanceDialog: true,
      AttendanceDialogData: data[0].empNames,
      AttendanceSelectedForDetails: value,
    });
  };
  closeAttendanceDialog = () => {
    this.setState({
      showAttendanceDialog: false,
    });
  };
  getDate(date) {
    return moment(date).format("DD-MMM-YYYY");
  }

  handleLiveNewsUpdates = (id) => {
    this.props.history.push("/dashboard/LiveNewsUpdatesDetails/" + id);
  };
  onAdd = () => {
    this.props.history.push(`/dashboard/add-leave/${this.state.EmpCode}`);
  };
  OnMark = () => {
    this.props.history.push(`/dashboard/markAttendance/`);
  };
  onHoliday = () => {
    this.props.history.push({
      pathname: "/dashboard/leaves",
      search: "",
      state: { tabValue: "holidayList" },
    });
  };
  overallPendingLeaves = () => {
    const totalLeaves = getLeavesRemaining(
      this.state.JoiningDate,
      "full",
      this.state.Status,
      this.state.masterLeaves
    );
    const takenLeaves =
      this.checkFullDayLeaveCount() + this.checkHalfDayLeaveCount() / 2;

    if (totalLeaves - takenLeaves < 0) {
      return 0;
    } else {
      return totalLeaves - takenLeaves;
    }
  };

  // getDataFromLocalStorage = async () => {
  //   try {
  //     const companyPolicy = await fetch(constant.API_ROOT + constant.GET_COMPANY_POLICY, {
  //       method: 'GET',
  //       headers: constant.API_HEADER,
  //     });

  //     const companyPolicyData = await companyPolicy.json();
  //     const currentYear = moment().year();
  //     // currentYear.toString()
  //     const policiesForCurrentYear = companyPolicyData.filter(
  //       (policy) => policy.PolicyFileName.includes(currentYear.toString())
  //     );
  //     const latest = policiesForCurrentYear.reduce(
  //       (prev, current) =>
  //         moment(current.PolicyFileName, 'Company Policy - YYYY.pdf').isAfter(
  //           moment(prev.PolicyFileName, 'Company Policy - YYYY.pdf')
  //         )
  //           ? current
  //           : prev,
  //       policiesForCurrentYear[0]
  //     );
  //     this.setState({ latestPolicy: latest });
  //   } catch (error) {
  //     console.error("Error retrieving data from PolicyList", error)
  //     return null;
  //   }
  // };
  // generateDownloadLink = (fileName) => {
  //   return `${constant.API_ROOT}${constant.DOWNLOAD_COMPANY_POLICY}?fileName=${encodeURIComponent(fileName)}`;
  // };

  handleLinkClick = async () => {
    this.props.history.push('/dashboard/companyPolicy');
  };

  render() {
    const { classes } = this.props;
    const { liveNewsUpdates } = this.state;
    const { upcomingAppraisals } = this.state;
    const { upcomingBirthdays } = this.state;
    return (
      <div>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <Typography className={classes.actionHeader}>
              Quick Actions
            </Typography>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Grid item xs={12} sm={12}>
                <Fab
                  onClick={this.onAdd}
                  color="primary"
                  aria-label="add"
                  className={`${classes.actions} ${classes.active}`}
                >
                  <AddIcon />
                  Apply Leave
                </Fab>
                <Fab
                  onClick={this.OnMark}
                  color="secondary"
                  aria-label="edit"
                  className={`${classes.actions} ${classes.total}`}
                >
                  <CheckIcon />
                  Mark Attendance
                </Fab>
                <Fab
                  onClick={this.onHoliday}
                  color="secondary"
                  aria-label="add"
                  className={`${classes.actions} ${classes.active1}`}
                >
                  <Eco />
                  Holiday List
                </Fab>
                <a
                  // href="COMPANY POLICY 2023.pdf"
                  onClick={this.handleLinkClick}
                  style={{ textDecoration: "none" }}
                >
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={`${classes.actions} ${classes.active2}`}
                  >
                    <Policy />
                    Company Policy
                  </Fab>
                </a>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <>
          <Typography className={classes.leaveActionHeader}>
            Leaves Status
          </Typography>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} style={{ display: "flex" }}>
              <span>
                <Card className={`${classes.card} ${classes.total1}`}>
                  <CardContent>
                    <Typography className={classes.pos} color="textPrimary">
                      Overall Pending Leaves
                    </Typography>
                    <Typography className={classes.pos} color="textPrimary">
                      {this.overallPendingLeaves()}
                    </Typography>
                  </CardContent>
                </Card>
              </span>
              <span style={{ position: "relative", left: "10px" }}>
                <Card className={`${classes.card} ${classes.active}`}>
                  <CardContent>
                    <Typography className={classes.pos} color="textSecondary">
                      Leaves Taken
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {this.checkFullDayLeaveCount() +
                        this.checkHalfDayLeaveCount() / 2}
                    </Typography>
                  </CardContent>
                </Card>
              </span>
              <span
                style={{
                  position: "relative",
                  left: "10px",
                  paddingLeft: "10px",
                }}
              >
                <Card className={`${classes.card} ${classes.displayTaken}`}>
                  <CardContent>
                    <Typography className={classes.pos} color="textSecondary">
                      Total Leaves Entitled
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {getLeavesRemaining(
                        this.state.JoiningDate,
                        "full",
                        this.state.Status,
                        this.state.masterLeaves
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </span>
            </Grid>
          </Grid>
        </>
        <Paper
          className={classes.paper}
          style={{ background: "#f7f7f7", paddingBottom: 50 }}
        >
          <Typography
            variant="title"
            component="h2"
            style={{ marginBottom: "70px" }}
          >
            News and Update
          </Typography>
          <div>
            <Grid container spacing={10} style={{ padding: "0 50px" }}>
              {liveNewsUpdates &&
                liveNewsUpdates.map((data, i) => (
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    style={{ display: "flex", padding: "10px 15px" }}
                  >
                    <Typography
                      className={`${classes.dateContainer} ${classes.cardHover} `}
                      style={{ background: "#fff" }}
                    >
                      <Typography
                        className={`${classes.empDate1} `}
                        style={{ marginRight: "0" }}
                        variant="subtitle1"
                        onClick={() => this.handleLiveNewsUpdates(data.id)}
                      >
                        {this.state.allBannerImage[data.id] ? (
                          <img
                            alt=""
                            className={classes.bgroupImg}
                            src={this.state.allBannerImage[data.id]}
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <img
                            alt=""
                            className={classes.bgroupImg}
                            src={require("../images/noImage.jpeg")}
                            style={{ width: "100%" }}
                          />
                        )}
                        <Card
                          className={`${classes.card}  ${classes.heightFix}`}
                          style={{
                            width: "100%",
                            boxShadow: "unset",
                            borderRadius: "0",
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={2}>
                              <Typography
                                className={` ${classes.textDecor} ${classes.overflowtext} `}
                              >
                                {data.Title}
                              </Typography>
                              <Typography
                                className={`${classes.dateContainer} `}
                              >
                                {this.getDate(data.DateOfEntry)}
                              </Typography>
                              <Typography
                                sx={{
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 1,
                                }}
                                className={`${classes.dateContainer} ${classes.description} `}
                              >
                                {data.Description.replace(
                                  /&nbsp;|(<[^>]+>)/g,
                                  ""
                                )}
                              </Typography>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Typography>
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </div>
          <div style={{ textAlign: "right", paddingTop: 30 }}>
            <Button
              onClick={() =>
                this.props.history.push(
                  this.state.roleId === 1
                    ? "/dashboard/LiveNewsUpdates"
                    : "/dashboard/LiveNewsUpdatesList"
                )
              }
              variant="contained"
              color="primary"
              className={classes.button}
            >
              View All
            </Button>
          </div>
        </Paper>
        {this.state.roleId === 1 ? (
          <Paper
            className={classes.paper}
            style={{ background: "#f7f7f7", paddingBottom: 50 }}
          >
            <Grid container>
              {/* Left Grid */}
              <Grid item xs={6}>
                <Typography
                  variant="title"
                  component="h2"
                  style={{ marginBottom: "30px" }}
                >
                  Upcoming Appraisals
                </Typography>
                <div
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                  className={classes.scrollView}
                >
                  {upcomingAppraisals &&
                    upcomingAppraisals.map((data, i) => (
                      <List
                        key={i}
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                          flexDirection: "column",
                        }}
                      >
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt="TechBit"
                              src={
                                this.state.appraisalProfilePicture[data.EmpCode]
                              }
                              className={classes.avatarImage}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={data.Name}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {moment(data.NextAppraisal).format("MMMM")}
                                </Typography>
                                <br></br>(
                                {moment(data.NextAppraisal).format(
                                  "DD-MM-YYYY"
                                )}
                                )
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    ))}
                </div>
              </Grid>
              {/* Right Grid */}
              <Grid item xs={6}>
                <Typography
                  variant="title"
                  component="h2"
                  style={{ marginBottom: "30px" }}
                >
                  Upcoming Birthdays
                </Typography>
                <div
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                  className={classes.scrollView}
                >
                  {upcomingBirthdays &&
                    upcomingBirthdays.map((data, i) => (
                      <List
                        key={i}
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                          flexDirection: "column",
                        }}
                      >
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt="TechBit"
                              src={
                                this.state.birthdayProfilePicture[data.EmpCode]
                              }
                              className={classes.avatarImage}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={data.Name}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {moment(data.Birthday).format("MMMM")}
                                </Typography>
                                <br></br>(
                                {moment(data.Birthday).format("DD-MM-YYYY")})
                              </React.Fragment>
                            }
                          />
                          <Fab
                            color="primary"
                            aria-label="add"
                            className={`${classes.birthdayIcon}`}
                          >
                            <CakeIcon />
                          </Fab>
                        </ListItem>
                      </List>
                    ))}
                </div>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          ""
        )}
        {/* {this.state.roleId === 1 && (
          <>
            <Typography
              style={{ marginTop: 30 }}
              variant="headline"
              component="h2"
            >
              Performance Charts
            </Typography>
            <Typography variant="subtitle1" component="h2">
              Employees
            </Typography>
            <Paper className={classes.paper}>
              <Typography variant="title" component="h2">
                Hours in Office vs Hours Worked Graph
              </Typography>
              <Grid container spacing={2} style={{ margin: 15 }}>
                <Grid item xs={12} sm={4} className={classes.filterDiv}>
                  <DateRangePicker
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) =>
                      this.changeRange(startDate, endDate)
                    } // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput })
                    } // PropTypes.func.isRequired,
                    isOutsideRange={() => false}
                  />
                </Grid>
                <Grid container item xs={12} sm={6} style={{ padding: 0 }}>
                  <Grid item xs={12} sm={6}>
                    {this.state.pageLoaded ? (
                      <EmployeesDropDown
                        getParentStatus={this.getParentStatus.bind(this)}
                        id={this.state.SelectedEmployeeId}
                        selectEmployee={this.selectEmployee.bind(this)}
                      />
                    ) : (
                      <span>Loading...</span>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {!localStorage.getItem("SelectedEmployeeIdForCart") ? (
                <Typography
                  variant="headline"
                  component="h2"
                  style={{ textAlign: "center", padding: 15 }}
                >
                  Please select any employee to render chart!
                </Typography>
              ) : this.state.loadEmployeeGraph ? (
                <ResponsiveContainer width="100%" height={350}>
                  <BarChart
                    data={this.state.employeeReport}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Total" fill="#8884d8" />
                    <Bar dataKey="Idle" fill="#f40b0080" />
                    <Bar dataKey="Actual" fill="#22b14c" />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <div></div>
              )}
            </Paper>
            <Paper className={classes.paper} style={{ marginBottom: 50 }}>
              <Typography variant="title" component="h2">
                All employees daily time sheet
              </Typography>
              <Grid container spacing={2} style={{ margin: 15 }}>
                <Grid item xs={12} sm={3}>
                  <FormControl margin="normal" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        keyboard
                        label="Date"
                        value={this.state.SelectedDate}
                        onChange={this.handleDateChange}
                        format={"dd/MM/yyyy"}
                        disableOpenOnEnter
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid container item xs={12} sm={5} style={{ paddingTop: 35 }}>
                  <Grid item xs={12} sm={4}>
                    <InputLabel>Total Work</InputLabel>
                    <Radio
                      checked={this.state.selectedValueRadio === "TimeDiff"}
                      onChange={this.handleChange}
                      value="TimeDiff"
                      name="radio-button-demo"
                      aria-label="A"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel>Idle Time</InputLabel>
                    <Radio
                      checked={this.state.selectedValueRadio === "IdleTime"}
                      onChange={this.handleChange}
                      value="IdleTime"
                      name="radio-button-demo"
                      aria-label="B"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel>Actual Work</InputLabel>
                    <Radio
                      checked={this.state.selectedValueRadio === "ActualWork"}
                      onChange={this.handleChange}
                      value="ActualWork"
                      name="radio-button-demo"
                      aria-label="C"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {this.state.loadTimeSheetGraph ? (
                <ResponsiveContainer width="100%" height={350}>
                  <BarChart
                    data={this.state.employeesTimeSheet}
                    margin={{ top: 5, right: 30, left: 20, bottom: 100 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      interval={0}
                      textAnchor="end"
                      angle={-85}
                      dy={0}
                      style={{ marginTop: 50 }}
                    />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> 
                    {this.state.selectedValueRadio === "TimeDiff" ? (
                      <Bar dataKey="Total" fill="#8884d8" />
                    ) : this.state.selectedValueRadio === "IdleTime" ? (
                      <Bar dataKey="Idle" fill="#f40b0080" />
                    ) : this.state.selectedValueRadio === "ActualWork" ? (
                      <Bar dataKey="Actual" fill="#22b14c" />
                    ) : (
                      ""
                    )}
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                ""
              )}
            </Paper>
            <Paper className={classes.paper} style={{ marginBottom: 50 }}>
              <Typography variant="title" component="h2">
                Employees Attendance Chart
              </Typography>
              <Grid container spacing={2} style={{ margin: 15 }}>
                <Grid item xs={12} sm={3}>
                  <FormControl margin="normal" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        keyboard
                        label="Date"
                        value={this.state.SelectedDateForAttendance}
                        onChange={this.handleAttendanceDateChange}
                        format={"dd/MM/yyyy"}
                        maxDate={moment().subtract(1, "d")}
                        disableOpenOnEnter
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <ResponsiveContainer width={"100%"} height={420}>
                <PieChart
                  width={400}
                  height={400}
                  onMouseEnter={this.onPieEnter}
                >
                  <Legend verticalAlign="top" height={36} />
                  <Pie
                    dataKey="value"
                    data={this.state.attendance}
                    cx={"auto"}
                    cy={150}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                  >
                    {this.state.attendance.map((entry, index) => (
                      <Cell
                        style={{ cursor: "pointer" }}
                        fill={entry.color}
                        key={index}
                        onClick={(d, i) =>
                          this.showAttendanceDialog(entry.name)
                        }
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Paper>
            <Dialog
              open={this.state.showAttendanceDialog}
              onClose={this.closeAttendanceDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`List of ${this.state.AttendanceSelectedForDetails} Employees`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.AttendanceDialogData.map((entry, index) => (
                    <p key={index}>{entry}</p>
                  ))}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.closeAttendanceDialog}
                  color="primary"
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )} */}
        <Footer />
      </div>
    );
  }
}
// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   percent,
//   index,
//   value,
//   name,
// }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);
//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? "start" : "end"}
//       dominantBaseline="central"
//     >
//       {value + " " + name}
//     </text>
//   );
// };
export default withStyles(styles)(Dashboard);
