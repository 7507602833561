import React, { useState, useEffect } from "react";

const SessionTimeout = ({ history }) => {
  const [lastActivityTime, setLastActivityTime] = useState(new Date());

  useEffect(() => {
    const logoutAfterInactivity = () => {
      localStorage.removeItem("Item");
      localStorage.removeItem("SelectedEmployeeIdForCart");
      localStorage.removeItem("SelectedEmployeeNameForCart");
      window.location.replace("/");
    };

    const inactivityTimer = setTimeout(() => {
      logoutAfterInactivity();
    }, 1800 * 1000);

    const handleActivity = () => {
      setLastActivityTime(new Date());
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [lastActivityTime, history]);

  return null;
};

export default SessionTimeout;
