import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import staticData from './static_data.json';

const useStyles = makeStyles(() => ({
  section_heading: {
    fontSize: "32px",
    color: '#FFF'
  },
  section_one_heading: {
    fontSize: '68px',
    margin: 0,
    marginTop: '2em'
  },
  section_one_para: {
    fontSize: '18px',
    fontWeight: 300,
    color: '#FFF',
  },
  section_one_box_outer_container: {
    maxWidth: '1320px',
  },
  section_one_overlay: {
    background: 'rgba(128,142,176, 0.34)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
  },
  section_one_box_inner_container: {
    margin: '0 42px 0px 40px',
  },
  section_one_content_container: {
    maxWidth: '66%'
  },

}))

const SectionOne = () => {
  const [fontSize, setFontSize] = useState('68px');
  const [maxWidth, setMaxWidth] = useState('66%');
  const {
    section_one_box_outer_container,
    section_one_box_inner_container,
    section_one_content_container,
    section_heading,
    section_one_heading,
    section_one_para,
    section_one_overlay,
  } = useStyles();

  useEffect(() => {
    const setResponsiveness = () => {
      if (window.innerWidth < 580) { 
        setFontSize('36px')
        setMaxWidth('100%')

      }
      else if (window.innerWidth < 990) {
        setFontSize('48px')
      } else {
        setFontSize('68px');
        setMaxWidth('66%')
      }
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  return (
    <>
      <Box flexWrap="wrap" className={section_one_box_outer_container} >
        <Box class={section_one_box_inner_container}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} className={section_one_content_container} style={{maxWidth}}>
            <h4
              className={`${section_heading} ${section_one_heading}`}
              style={{ fontSize }}
            >
              {staticData.section_1.heading}
            </h4>
            <Typography variant="p" component="div" className={section_one_para}>{staticData.section_1.content}</Typography>
          </Grid>
        </Box>
      </Box>
      <Box className={section_one_overlay}></Box>
    </>
  )
}

export default SectionOne