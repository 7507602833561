import {
  Toolbar,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '1320px',
    width: '100%'
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    color: 'white'
  },
}));

export default function Header() {
  const { toolbar, container } = useStyles();

  const displayDesktop = (
    <Toolbar className={toolbar}>
      <Typography style={{ color: 'inherit' }}>
        Copyright © 2022 ProTraq.
      </Typography>
    </Toolbar>
  );

  return (
    <footer>
      <Container className={container}>
        {displayDesktop}
      </Container>
    </footer>
  );
}