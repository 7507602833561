import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Const from "../common/constant";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { Select, MenuItem } from "@material-ui/core";
import MyDocument from "./MyPdfDocument";
import { NotificationManager } from "react-notifications";
import CryptoJS from "crypto-js";

const styles = () => ({
  salaryTable: {
    border: "1px solid #ccc",
    margin: "0px 10px 10px 10px",
    overflow: "unset",
    padding: "10px",
  },
  filter_paper: {
    padding: "25px",
  },
  button: {
    backgroundColor: "#fb7813 !important",
  },
  actionText: {
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  yearText: {
    fontSize: "20px",
    marginRight: "16px",
  },
});

const PayloadPanel = (props) => {
  const { classes } = props;
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [currentMonth, setCurrentMonth] = useState();
  const [months, setMonths] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const [years, setYears] = useState([]);
  useEffect(() => {
    getYearsList();
  }, []);
  useEffect(() => {
    props.loader(true);
    getMonthList();
  }, [currentYear]);

  const getemployeeData = async (month, data) => {
    let id = CryptoJS.AES.encrypt(
      props.EmpCode,
      process.env.REACT_APP_SECRET_KEY
    ).toString();

    let body = {
      year: currentYear,
      month: month,
      id: id,
    };
    const listYear = await fetch(Const.API_ROOT + Const.GET_PAYLOAD_DATA, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    });
    const responseJson = await listYear.json();
    if (responseJson.recordset[0] === undefined) {
      NotificationManager.error(
        "Error",
        "Your pay slip for this month was not added"
      );
    } else {
      setShowPdf(true);
      setModalContent(responseJson.recordset[0]);
      setCurrentMonth(data);
    }
  };

  const getMonthList = async () => {
    let body = {
      currentYear: currentYear,
    };
    const listYear = await fetch(Const.API_ROOT + Const.GET_SALARY_SHEET, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    });
    const responseJson = await listYear.json();
    setMonths(responseJson);
    props.loader(false);
  };

  const getYearDropList = () => {
    return (
      years &&
      years.map((year, i) => {
        return (
          <MenuItem key={i} value={year.years}>
            {year.years}
          </MenuItem>
        );
      })
    );
  };

  const getYearsList = async () => {
    const listYear = await fetch(
      Const.API_ROOT + Const.GET_UPLOADED_SALARY_YEAR,
      { method: "POST", headers: Const.API_HEADER }
    );
    const responseJson = await listYear.json();
    setYears(responseJson);
  };

  const showPdfData = (month, data) => {
    getemployeeData(month, data);
  };

  return (
    <div>
      <div>
        <Paper className={classes.root} style={{ marginBottom: "30px" }}>
          <Paper className={classes.filter_paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className={classes.yearText}>Year</p>
                <Select
                  variant="outlined"
                  displayEmpty={true}
                  width="fullWidth"
                  value={currentYear}
                  onChange={(e) => {
                    setCurrentYear(e.target.value);
                  }}
                  style={{ width: "180px" }}
                >
                  {getYearDropList()}
                </Select>
              </div>
            </div>
          </Paper>
        </Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Salary Month</TableCell>
              <TableCell className={classes.tableCell} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {months &&
              months.map((data, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {moment(data.x).format("MMMM")}
                  </TableCell>
                  <TableCell align="center">
                    <div className="{classes.actions}">
                      <a
                        onClick={(_e) =>
                          showPdfData(
                            moment(data.x).format("M"),
                            moment(data.x).format("MMMM")
                          )
                        }
                        className={classes.actionText}
                        style={{ marginRight: "20px" }}
                      >
                        View Detail
                      </a>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {showPdf === true && (
          <MyDocument
            isOpen={showPdf}
            onClose={() => setShowPdf(false)}
            modalContent={modalContent}
            year={currentYear}
            month={currentMonth}
          />
        )}
      </div>
    </div>
  );
};
export default withStyles(styles)(PayloadPanel);
