import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./style.css"
import logo from "./Assets/Images/logo.png"
import vector from "./Assets/Images/Vector.png"
import vector1 from "./Assets/Images/vector-1.png"
import vector2 from "./Assets/Images/vector-2.png"
import vector3 from "./Assets/Images/vector-3.png"
import vector4 from "./Assets/Images/vector-4.png"
import vector5 from "./Assets/Images/vector-5.png"
import icon1 from "./Assets/Images/icon-1.png"
import icon2 from "./Assets/Images/icon-2.png"
import icon3 from "./Assets/Images/icon-3.png"
import icon4 from "./Assets/Images/icon-4.png"
import icon5 from "./Assets/Images/icon-5.png"
import icon6 from "./Assets/Images/icon-6.png"
import intro from "./Assets/Images/intro-vector.png"
import image from "./Assets/Images/Image.png"
import WOW from "wowjs";

function home() {
    const [activeItem, setActiveItem] = useState('home');
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, [])


    function scrollToAbout(event) {
        event.preventDefault();
        const aboutSection = document.getElementById('about');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
        setActiveItem('about');
    }
    function businessScrollToAbout(event) {
        event.preventDefault();
        const businessOutcome = document.getElementById('business');
        businessOutcome.scrollIntoView({ behavior: 'smooth' });
        setActiveItem('business');
    }
    function homeScrollToAbout(event) {
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setActiveItem('home');
    }
    function featureScrollToAbout(event) {
        event.preventDefault();
        const features = document.getElementById('feature');
        features.scrollIntoView({ behavior: 'smooth' });
        setActiveItem('features');
    }
    return (
        <>
            <>
                <div className="nav-content">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="container-fluid">
                                <a className="logo">
                                    <img src={logo} alt="logo" />
                                </a>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mt-0">
                                        <li className="nav-item">
                                            <a className={`nav-link ${activeItem === 'home' ? 'active' : ''}`} aria-current="page" href="#" onClick={homeScrollToAbout} >
                                                HOME
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link  ${activeItem === 'about' ? 'active' : ''}`} aria-current="page" href="#" onClick={scrollToAbout}>
                                                ABOUT US
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link  ${activeItem === 'business' ? 'active' : ''}`} aria-current="page" href="#" onClick={businessScrollToAbout} >
                                                BUSINESS OUTCOMES
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link  ${activeItem === 'features' ? 'active' : ''}`} aria-current="page" href="#" onClick={featureScrollToAbout} >
                                                FEATURES
                                            </a>
                                        </li>
                                    </ul>
                                    <Link to="/login">
                                        <button className="btn btn-outline-success" type="submit" key='login' to='/login'>
                                            Login
                                        </button>
                                    </Link>
                                </div>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasExample"
                                    aria-controls="offcanvasExample"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon" />
                                </button>
                                <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
                                    aria-labelledby="offcanvasExampleLabel">
                                    <div class="offcanvas-header pb-0">
                                        <a class="logo" href="index.html">
                                            <img src={logo} alt="logo" />
                                        </a>
                                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="offcanvas-body">
                                        <ul class="navbar-nav mt-0">
                                            <li class="nav-item">
                                                <a class="nav-link active" aria-current="page" href="#" onClick={homeScrollToAbout}>HOME</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link active" aria-current="page" href="#" onClick={scrollToAbout}>ABOUT US</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link active" aria-current="page" href="#" nClick={businessScrollToAbout}>BUSINESS OUTCOMES</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link active" aria-current="page" href="#" onClick={featureScrollToAbout}>FEATURES</a>
                                            </li>
                                        </ul>
                                        <Link to="/login">
                                            <button class="btn btn-outline-success" type="submit">Login</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* Navbar Ends*/}
                {/* Header Section Starts*/}
                <section className="header-section" id='home'>
                    <img src={vector1} alt="" className="vector-1" />
                    <img src={vector2} alt="" className="vector-2" />
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10" >
                                <div className="text wow slideInLeft" data-wow-duration="0.3s" data-wow-delay="0.3s">
                                    <h1>
                                        Monitoring &amp; Time Tracking Software For Efficient{" "}
                                        <span>Productivity</span>
                                    </h1>
                                    <p>
                                        This tool offers not just data, but insights and context so every
                                        company can understand productivity behavior, analyze the findings
                                        and plan how to design their teams for better business outcomes in
                                        the future.
                                    </p>
                                    <button className="btn btn-outline-success" type="submit">
                                        Get Started
                                    </button>
                                </div>
                                <div className="text-center wow slideInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                                    <img src={vector} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Header Section Ends*/}
                {/* Intro Starts */}
                <section className="intro" id="about">
                    <img src={intro} alt="" className="intro-vector" />
                    <div className="container wow slideInUp" data-wow-duration="0.3s" data-wow-delay="0.3s" >
                        <div className="text">
                            <h1>Who We Are?</h1>
                            <p style={{ textAlign: "justify" }}>
                                Today's business operations seek to be as efficient as possible with
                                streamlined workflows, reduced downtime, increased productivity along
                                with boosting employee's morale. But the remote workers of today
                                introduce different work patterns and systems usage. Hence, employee
                                monitoring needs to be evolved giving organizations key user behavior
                                insights to understand how work gets done. <br /> <br />
                                Protraq is a a SaaS-based productivity monitoring tool for
                                organizations of all sizes. This tool offers not just data, but
                                insights and context so every company can understand productivity
                                behavior, analyze the findings and plan how to design their teams for
                                better business outcomes in the future. This is all done by
                                normalizing the data so companies can find trends, patterns, and
                                correlations to gain insight into business processes and how to
                                improve them. <br /> <br />
                                We believe that employee productivity is something that can always be
                                improved upon, hence this tool help leaders identify employees who are
                                underperforming and why before they become disengaged.
                            </p>
                        </div>
                    </div>
                </section>
                {/* Intro Ends */}
                {/* Features Section Starts */}
                <section className="features" id='feature'>
                    <img src={vector3} alt="" className="vector-3" />
                    <div className="container">
                        <div className="heading">
                            <h1>Make Every Second Count</h1>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card wow slideInLeft" data-wow-duration="0.2s" data-wow-delay="0.2s">
                                    <img src={icon1} alt="" className="icon" />
                                    <h3>Automatic time tracking</h3>
                                    <p style={{ textAlign: "justify" }}>
                                        Protraq starts and stops working when an employee does, from the
                                        minute he/she turn on the computer, to the minute he/she turn it
                                        off. Protraq tracks and calculates productivity automatically so
                                        that companies can rely 100% on the data.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card wow slideInRight" data-wow-duration="0.2s" data-wow-delay="0.2s">
                                    <img src={icon2} alt="" className="icon" />
                                    <h3>Cost calculation and project billing</h3>
                                    <p style={{ textAlign: "justify" }}>
                                        Know how much each project cost to the company and how much you
                                        should charge your customers with the integrated time billing
                                        feature. Set each employee's hourly rates, and Protraq will
                                        automatically calculate project costs based on time spent.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card wow slideInLeft" data-wow-duration="0.3s" data-wow-delay="0.3s">
                                    <img src={icon3} alt="" className="icon" />
                                    <h3>Offline Time Tracking</h3>
                                    <p style={{ textAlign: "justify" }}>
                                        Allow employees to manually add activities completed away from
                                        their computer. Specify the reasons for absence and put them down
                                        as productive, or unproductive. Then, easily sort offline
                                        activities and approve or deny them.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card wow slideInRight" data-wow-duration="0.3s" data-wow-delay="0.3s">
                                    <img src={icon4} alt="" className="icon" />
                                    <h3>Auto Screenshots</h3>
                                    <p style={{ textAlign: "justify" }}>
                                        Capture what's on employees' screen automatically and randomly
                                        every hour. Customize as you need by only activating for certain
                                        employees and adjusting intervals between screenshots. Protraq
                                        will take a screenshot of what everyone is working on.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card wow slideInLeft" data-wow-duration="0.4s" data-wow-delay="0.4s">
                                    <img src={icon5} alt="" className="icon" />
                                    <h3>Work Schedules</h3>
                                    <p style={{ textAlign: "justify" }}>
                                        Plan and schedule employee’s shifts more effectively and 6x faster
                                        than when using spreadsheets. See a complete daily, weekly, and
                                        monthly overview of shifts, avoid overstaffing and understaffing,
                                        and track the shift workers' productivity with ease.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card wow slideInRight" data-wow-duration="0.4s" data-wow-delay="0.4s">
                                    <img src={icon6} alt="" className="icon" />
                                    <h3>Custom Reports</h3>
                                    <p style={{ textAlign: "justify" }}>
                                        Customize reports with the help of various filters to see the
                                        exact data you need. For example, use Protraq Reports to create
                                        reports about the company's productivity status, websites used at
                                        work or time spent on specific tasks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={vector4} alt="" className="vector-4" />
                </section>
                {/* Features Section Ends */}
                {/* Services Section Starts */}
                <section className="services" id='business'>
                    <div className="container">
                        <div className="heading">
                            <h1>Business Outcomes With Protraq</h1>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tabs-wp">
                                    <ul
                                        className="nav nav-pills d-none d-lg-flex justify-content-between"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link active"
                                                id="first-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#first-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="first-tab-pane"
                                                aria-selected="true"
                                            >
                                                Analyze Workforce <br />
                                                Productivity
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link"
                                                id="second-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#second-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="second-tab-pane"
                                                aria-selected="false"
                                            >
                                                Improve Operational <br />
                                                Efficiency
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link"
                                                id="third-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#third-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="third-tab-pane"
                                                aria-selected="false"
                                            >
                                                Manage Your Remote <br />
                                                Workforce
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="nav-link"
                                                id="fourth-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#fourth-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="fourth-tab-pane"
                                                aria-selected="false"
                                            >
                                                Reporting &amp; Enhanced <br />
                                                Security
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content accordion" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active accordion-item"
                                            id="first-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="first-tab"
                                            tabIndex={0}
                                        >
                                            <h2 className="accordion-header d-lg-none" id="headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne"
                                                >
                                                    Analyze Workforce Productivity
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse show  d-lg-block"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#myTabContent"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-7 col-md-12 order-change">
                                                            <div className="content left-side">
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>Understand how employees utilize their time</p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Identify and compare the amount of time spent on
                                                                        productive and unproductive activities.
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Discover wasteful practices and distracting
                                                                        environments
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Analyze productive behaviors to establish a top
                                                                        performing baseline
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Understand employee utilization of time and make
                                                                        needed adjustments
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <div className="image right-side">
                                                                <img
                                                                    src={image}
                                                                    className="img-fluid"
                                                                    alt="vector"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade accordion-item"
                                            id="second-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="second-tab"
                                            tabIndex={0}
                                        >
                                            <h2 className="accordion-header d-lg-none" id="headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo"
                                                >
                                                    Improve Operational Efficiency
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className="accordion-collapse collapse d-lg-block"
                                                aria-labelledby="headingTwo"
                                                data-bs-parent="#myTabContent"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-8 order-change">
                                                            <div className="content left-side">
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Manage costs with better visibility of application
                                                                        usage
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Analyze data insights to design teams for better
                                                                        business outcomes
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Look for changes that positively affect employee
                                                                        performance.
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Filter percentages of app and website usage by
                                                                        productive and unproductive categories.
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Identify frequently used unproductive apps or
                                                                        websites and set usage limits.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="image right-side">
                                                                <img
                                                                    src={image}
                                                                    className="img-fluid"
                                                                    alt="vector"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade accordion-item"
                                            id="third-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="third-tab"
                                            tabIndex={0}
                                        >
                                            <h2 className="accordion-header d-lg-none" id="headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="collapseThree"
                                                >
                                                    Manage Your Remote Workforce
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className="accordion-collapse collapse d-lg-block"
                                                aria-labelledby="headingThree"
                                                data-bs-parent="#myTabContent"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-8 order-change">
                                                            <div className="content left-side">
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Get visibility into how remote employees utilize
                                                                        their time
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Discover productivity insights across dispersed
                                                                        teams
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Identify overworked remote employees to reduce
                                                                        attrition
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Compare productivity levels across geographic
                                                                        locations or remote teams.
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Discover how much real time is spent using work
                                                                        relevant tools.
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Establish remote policies, measure their
                                                                        effectiveness, and make changes for on-going
                                                                        improvement.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="image right-side">
                                                                <img
                                                                    src={image}
                                                                    className="img-fluid"
                                                                    alt="vector"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade accordion-item"
                                            id="fourth-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="fourth-tab"
                                            tabIndex={0}
                                        >
                                            <h2 className="accordion-header d-lg-none" id="headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="collapseFour"
                                                >
                                                    Reporting &amp; Enhanced Security
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseFour"
                                                className="accordion-collapse collapse d-lg-block"
                                                aria-labelledby="headingFour"
                                                data-bs-parent="#myTabContent"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-8 order-change">
                                                            <div className="content left-side">
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Filter analyses for each day, week, month, and
                                                                        year to analyze trends.
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Weigh the effectiveness of new operating
                                                                        procedures and how they impact productivity.
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Secure sensitive information from being shared
                                                                        using screenshot redaction.
                                                                    </p>
                                                                </div>
                                                                <div className="point">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={40}
                                                                        height={40}
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M17 8v8l4.5-4L17 8m-5 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"
                                                                        />
                                                                    </svg>
                                                                    <p>
                                                                        Prevent access to distracting websites and
                                                                        terminate unapproved applications.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="image right-side">
                                                                <img
                                                                    src={image}
                                                                    className="img-fluid"
                                                                    alt="vector"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={vector5} alt="" className="vector-5" />
                </section>
                {/* Services Section Ends */}
                {/* Footer Starts */}
                <section className="footer">
                    <p>Copyright © 2023 Protraq . All rights reserved.</p>
                </section>
                {/* Footer Starts */}
            </>

        </>

    )
}

export default home