import React, { Component } from "react";
import Const from "../../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CardActions from "@material-ui/core/CardActions";
import Footer from "../../common/footer";
import { NotificationManager } from "react-notifications";
const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  action: {
    cursor: "pointer",
    margin: 2,
  },
  editAction: {
    color: "#00a9f4",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  button: {
    backgroundColor: "#fb7813 !important",
  },
});

class Shift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaves: [],
      listView: false,
    };
  }
  getLeavesMasterData = () => {
    fetch(Const.API_ROOT + Const.GET_LEAVES_SETTINGS, {
      method: "GET",
      headers: Const.API_HEADER,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          leaves: responseJson,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  componentDidMount() {
    this.getLeavesMasterData();
  }
  componentWillUnmount() {
    this.getLeavesMasterData();
  }

  editLeaves = (ID) => {
    console.log(ID, "ID");
    this.props.history.push("/dashboard/settings/leaves/edit-leaves/" + ID);
  };
  addLeaves = () => {
    this.props.history.push("/dashboard/settings/leaves/add-leaves");
  };
  deleteLeaves = (id, index) => {
    let body = {
      ID: id,
    };
    fetch(Const.API_ROOT + Const.DELETE_LEAVES_SETTINGS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then(() => {
        this.state.leaves.slice(index, 1);
        this.getLeavesMasterData();
      })
      .then((error) => {
        console.log(error);
      });
    NotificationManager.success("Leaves Deleted successfully", "Success", 2000);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.paper}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.listView}
                onChange={(e) =>
                  this.setState({
                    listView: !this.state.listView,
                  })
                }
                value={this.state.listView}
              />
            }
            label={!this.state.listView ? "Tiles View" : "List View"}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.addLeaves()}
          >
            <i className={`${classes.leftIcon} material-icons`}>person_add</i>
            Add Leaves
          </Button>
        </Paper>
        {this.state.listView ? (
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Number of Leaves</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.leaves.map((n, index) => {
                  return (
                    <TableRow className={classes.tableRowHover} key={n.ID}>
                      <TableCell component="th" scope="row">
                        {n.Year}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {n.NumberOfLeaves}
                      </TableCell>
                      <TableCell align="right">
                        <span
                          className={classes.action}
                          onClick={() => this.editLeaves(n.ID)}
                        >
                          <i
                            className={`${classes.editAction} material-icons`}
                            aria-hidden="true"
                          >
                            edit
                          </i>
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span
                          className={classes.action}
                          onClick={() => this.deleteLeaves(n.ID, index)}
                        >
                          <i
                            className={`${classes.deleteAction} material-icons`}
                            aria-hidden="true"
                          >
                            delete
                          </i>
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Grid container spacing={16}>
            {this.state.leaves.map((data, index) => {
              return (
                <Grid item xs={12} sm={3} key={index}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container spacing={24}>
                        <Grid style={{ padding: 5 }} item xs={12}>
                          <Typography variant="title" component="h2">
                            {data.Year}
                          </Typography>
                        </Grid>
                        <Typography
                          style={{ padding: 10, fontWeight: "bold" }}
                          component="p"
                        >
                          Total Leaves : {data.NumberOfLeaves}
                        </Typography>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => this.editLeaves(data.ID)}
                      >
                        <i
                          className={`${classes.editAction} material-icons`}
                          aria-hidden="true"
                        >
                          edit
                        </i>
                        &nbsp;edit
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => this.deleteLeaves(data.ID, index)}
                      >
                        <i
                          className={`${classes.deleteAction} material-icons`}
                          aria-hidden="true"
                        >
                          delete
                        </i>
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Footer />
      </div>
    );
  }
}
export default withStyles(styles)(Shift);
