import config from "../../config";

export default {
  API_ROOT: `${config.REACT_APP_NODE_URL}`, // FOR LOCAL
  //API_ROOT:'http://103.212.121.173:3002',  // FOR PRODUCTION
  API_HEADER: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  SETTINGS_TABS: {
    Banks: 0,
    Designations: 1,
    Departments: 2,
    DefaultColumns: 3,
    Shifts: 4,
    Leaves: 5,
  },
  VALIDATE_USER: "/User/ValidateUser",
  GET_USERS: "/User/GetEmployees",
  GET_AUTOCOMPLETE_USERS: "/User/GetAutoCompleteEmployeeDetails",

  GET_LIVE_NEWSUPDATES: "/LiveNewsUpdates/GetNewsUpdates",
  ADD_LIVE_NEWSUPDATES: "/LiveNewsUpdates/AddNewsUpdates",
  DELETE_LIVE_NEWSUPDATES: "/LiveNewsUpdates/DeleteNewsUpdates",
  UPLOAD_BANNER_IMAGE: "/LiveNewsUpdates/UploadBannerImage",
  UPLOAD_ATTACTMENTS: "/LiveNewsUpdates/uploadAttachments",
  GET_NEWS_UPDATE_ATTACHMENTS: "/LiveNewsUpdates/getAttachments",
  GET_BANNER_IMAGE: "/LiveNewsUpdates/showBannerImage",
  DELETE_NEWS_UPDATE_UPLOAD_ATTACHMENT:
    "/LiveNewsUpdates/deleteuploadedAttachment",
  GET_LIVE_NEWSUPDATES_DETAILS: "/LiveNewsUpdates/DetailsNewsUpdates",

  GET_Employees_Status: "/User/GetEmployeesStatus",
  GET_EMPLOYEE_DETAILS: "/User/GetEmployeeDetails",
  GET_EMPLOYEE_REPORT: "/Report/GetEmployeeReport",
  GET_TIMING_DATA: "/User/GetTimingData",
  GET_ALL_LIST_DATA: "/User/GetAllListData",
  ADD_EMPLOYEE: "/User/AddEmployee",
  DELETE_EMPLOYEE: "/User/DeleteEmployee",
  GET_EMPLOYEE_SCREENS: "/User/GetEmployeeScreens",
  GET_EMPLOYEE_BASE64_IMAGE: "/User/GetEmployeeImageBase64",
  CHECK_EMAIL_EXIST: "/User/CheckEmailExist",
  GET_EMPLOYEE_APPRAISALS: "/User/GetEmployeeAppraisals",
  ADD_EMPLOYEE_APPRAISAL: "/User/AddEmployeeAppraisal",
  DELETE_EMPLOYEE_APPRAISAL: "/User/DeleteEmployeeAppraisal",
  GET_UPCOMING_APPRAISALS: "/User/upcomingAppraisals",
  GET_UPCOMING_BIRTHDAYS: "/User/upcomingBirthdays",

  //changes
  UPLOAD_PROFILE_PICTURE: "/User/UploadProfilePicture", // /UploadProfilePicture/:employeeCode
  UPLOAD_FILE: "/User/upload", // /upload/:employeeCode
  GET_FILES: "/User/getFiles", // /getFiles/:employeeCode
  SHOW_FILE: "/User/showFile", // /showFile/:employeeCode/:fileName
  DELETE_FILE: "/User/deleteFile", // /deleteFile/:employeeCode/:fileName

  //TimeSheets Details
  GET_ORGANIZATION_SETTINGS: "/User/GetOrganizationSettings",

  //Dashboard
  GET_ATTENDANCE: "/Dashboard/GetAttendance",

  //Bank
  GET_BANKS: "/Bank/GetBanks",
  GET_BANK_DETAILS: "/Bank/GetBankDetails",
  GET_BANK_MASTER_DATA: "/Bank/GetMasterData",
  ADD_BANK: "/Bank/AddBank",

  //Designation
  GET_DESIGNATIONS: "/Designation/GetDesignations",
  GET_DESIGNATION_DETAILS: "/Designation/GetDesignationDetails",
  GET_DESIGNATION_MASTER_DATA: "/Designation/GetMasterData",
  ADD_DESIGNATION: "/Designation/AddDesignation",

  //Department
  GET_DEPARTMENTS: "/Department/GetDepartments",
  GET_DEPARTMENT_DETAILS: "/Department/GetDepartmentDetails",
  GET_DEPARTMENT_MASTER_DATA: "/Department/GetMasterData",
  ADD_DEPARTMENT: "/Department/AddDepartment",

  //Default Columns
  GET_DEFAULT_COLUMN_MASTER: "/DefaultColumn/GetMasterData",
  SAVE_DEFAULT_COLUMNS: "/DefaultColumn/SaveDefaultColumn",

  //Shift
  TOGGLE_IDLE: "/Shift/ToggleIdle",
  TOGGLE_SHIFTS: "/Shift/ToggleShifts",
  GET_SHIFTS: "/Shift/GetShifts",
  GET_SHIFT_DETAILS: "/Shift/GetShiftDetails",
  GET_SHIFT_MASTER_DATA: "/Shift/GetMasterData",
  ADD_SHIFT: "/Shift/AddShift",

  //Project
  GET_PROJECTS: "/Project/GetProjects",
  GET_PROJECT_STATUS_LIST: "/Project/GetProjectStatusList",
  GET_PROJECT_TIMESHEET_INTERVAL: "/Project/GetProjectTimesheetList",
  ADD_NEW_PROJECT: "/Project/AddProject",
  GET_AllPROJECTS: "/Project/GetAllProjects",
  GET_TEAMMEMBERS: "/Project/GetTeamMembers",
  ADD_NEW_ToDo: "/Project/AddTodo",
  GET_TODO_LIST: "/Project/GetToDoDetails",
  GET_ALL_TODO_LIST: "/Project/GetAllToDo",
  ADD_PROJECT_TEAM_DATA: "/Project/AddProjectTeamData",
  GET_PROJECT_DETAILS: "/Project/GetProjectDetails",
  GET_PROJECT_TEAM_DATA: "/Project/GetProjectTeamData",
  GET_CURRENCY: "/Project/GetCurrencyofOrganisation",
  GET_USER_PROJECTS: "/Project/getUserProjects",

  BLOOD_GROUP_LIST: ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"],

  GET_LEAVES: "/Leaves/GetAllLeaves",
  GET_LEAVES_COUNTS: "/Leaves/getLeavesCount",
  GET_LEAVE_TYPES: "/Leaves/GetLeaveTypes",
  ADD_LEAVE: "/Leaves/AddLeave",
  CANCEL_LEAVE: "/Leaves/CancelLeave",
  GET_LEAVES_SETTINGS: "/Leaves/GetLeaveSettings",
  ADD_LEAVES_SETTINGS: "/Leaves/AddLeaveSettings",
  DELETE_LEAVES_SETTINGS: "/Leaves/DeleteLeaveSettings",
  GET_LEAVE_MASTER_DATA: "/Leaves/GetMasterData",
  GET_LEAVE_SETTINGS_DETAILS: "/Leaves/GetLeavesSettingsDetails",
  Mark_Present: "/Leaves/markpresent",
  GET_LEAVES_COUNT: "/Leaves/getLeaveCount",

  // delete them
  APPROVE_LEAVE: "/Leaves/ApproveLeave",
  REJECT_LEAVE: "/Leaves/RejectLeave",

  // yet to be created
  ACTION_ON_LEAVE: "/Leaves/ActionOnLeave",

  GET_LEAVE_TABLE_LENGTH: "/Leaves/GetTableLength",
  GET_SENT_TO_MANGERS: "/Leaves/GetSentToManagers",
  GET_LOGGED_IN_USER_APPROVAL_LEAVES: "/Leaves/AskedForYourApprovalLeaves",
  GET_LOGGED_IN_USER_APPLIED_LEAVES: "/Leaves/AppliedLeaves",

  SAVE_LEAVE_NOTIFICATIONS: "/Leaves/SaveLeaveNotifications",
  GET_LEAVE_NOTIFICATIONS: "/Leaves/GetLeaveNotifications",
  UPDATE_LEAVE_NOTIFICATIONS: "/Leaves/UpdateLeaveNotifications",

  //Attendance
  GET_ATTENDANCE: "/Attendance/getAttendance",
  ADD_ATTENDANCE: "/Attendance/addAttendance",
  GET_FILTERED_ATTENDANCE: "/Attendance/getFilteredAttendance",

  //Holidays
  GET_ALL_HOLIDAYS: "/Holiday/getHolidaysList",
  GET_ALL_HOLIDAY_YEARS: "/Holiday/getHolidayYears",

  //PayRoll
  UPLOAD_SALARY_SHEET: "/PayRoll/uploadSalarySheet",
  GET_SALARY_SHEET: "/PayRoll/getUploadSalaryDate",
  DELETE_SALARY_SHEET: "/PayRoll/deleteUploadSalarysheet",
  GET_PAYLOAD_DATA: "/PayRoll/getPayloadData",
  GET_EXCEL_FILE: "/PayRoll/getExcelFile",
  GET_UPLOADED_SALARY_YEAR: "/PayRoll/getUploadSalaryYear",
  CHECK_UPLOADED_SALARY_YEAR: "/PayRoll/checkCurrentMonthSalaryIsUploaded",

  //Company Policy
  GET_COMPANY_POLICY: "/CompanyPolicy/GetCompanyPolicies",
  DOWNLOAD_COMPANY_POLICY: "/CompanyPolicy/DownloadCompanyPolicy"
};
