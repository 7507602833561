import Employees from "../../components/employees";
import TimeSheet from "../../components/time-sheet";
import AddEmployee from "../../components/add-employee";
import AddBank from "../../components/settings/banks/add-bank";
import AddDepartment from "../../components/settings/departments/add-department";
import AddDesignation from "../../components/settings/designations/add-designation";
import AddShift from "../../components/settings/shifts/add-shift";
import AddLeaves from "../../components/settings/leaves/add-leaves";
import Settings from "../../components/settings";
import TimeSheetDetails from "../../components/time-sheet-details";
import Reports from "../../components/reports";
import Project from "../../components/projects";
import Todo from "../../components/todos";
import AddProject from "../../components/projects/add-project";
import AddTodo from "../../components/todos/add-todo";
import Profile from "../../components/profile/index";
import LiveNewsUpdates from "../../components/News/LiveNewsUpdates";
import AddLiveNewsUpdates from "../../components/News/AddLiveNewsUpdates";
import Leaves from "../../components/leaves";
import AddLeave from "../../components/leaves/add-leave";
import Dashboard from "../../Dashboard";
import Attendance from "../../components/attendance";
import AddAttendance from "../../components/attendance/add-attendance";
import LiveNewsUpdatesDetails from "../../components/News/LiveNewsUpdatesDetails";
import AddPayRoll from "../../components/payroll-management/add-payroll";
import PayRoll from "../../components/payroll-management";
import CompanyPolicy from "../../components/companyPolicy"
export const routes = [
  {
    id: 1,
    name: "Dashboard",
    path: "/dashboard",
    icon: "desktop_mac",
    authorisedUsers: ["1", "2"],
    comp: Dashboard,
    exact: true,
    protectedRoute: false,
  },
  {
    id: 2,
    name: "My Profile",
    path: "/dashboard/profile",
    icon: "person",
    authorisedUsers: ["1", "2"],
    exact: true,
    comp: Profile,
    protectedRoute: false,
  },
  {
    id: 3,
    name: "Projects",
    path: "/dashboard/projects",
    icon: "web",
    authorisedUsers: ["1", "2"],
    exact: true,
    comp: Project,
    protectedRoute: false,
  },
  {
    id: 4,
    name: "ToDo",
    path: "/dashboard/todos",
    icon: "grading",
    authorisedUsers: ["1", "2"],
    exact: true,
    comp: Todo,
    protectedRoute: false,
  },
  {
    id: 5,
    name: "Employees",
    path: "/dashboard/employees",
    icon: "supervisor_account",
    authorisedUsers: ["1"],
    exact: true,
    comp: Employees,
    protectedRoute: true,
  },

  {
    id: 6,
    name: "Time Sheet",
    path: "/dashboard/time-sheet",
    icon: "timeline",
    authorisedUsers: ["1", "2"],
    exact: true,
    comp: TimeSheet,
    protectedRoute: false,
  },
  {
    id: 7,
    name: "Leaves",
    path: "/dashboard/leaves",
    icon: "star",
    authorisedUsers: ["1", "2"],
    exact: true,
    comp: Leaves,
    protectedRoute: false,
  },
  {
    id: 8,
    name: "Attendance",
    path: "/dashboard/markAttendance",
    icon: "check",
    authorisedUsers: ["1", "2"],
    exact: true,
    comp: AddAttendance,
    protectedRoute: false,
  },
  {
    id: 9,
    name: "News Updates",
    path: "/dashboard/LiveNewsUpdates",
    icon: "newspaper",
    authorisedUsers: ["1"],
    exact: true,
    comp: LiveNewsUpdates,
    protectedRoute: true,
  },
  {
    id: 10,
    name: "Reports",
    path: "/dashboard/reports",
    icon: "bar_chart",
    authorisedUsers: ["1"],
    exact: true,
    comp: Reports,
    protectedRoute: true,
  },
  {
    id: 11,
    name: "Settings",
    path: "/dashboard/settings/0",
    icon: "settings",
    authorisedUsers: ["1"],
    exact: true,
    comp: Settings,
    protectedRoute: true,
  },
  {
    id: 12,
    name: "PayRoll",
    path: "/dashboard/PayRollManagement",
    icon: "payment",
    authorisedUsers: ["1"],
    exact: true,
    comp: PayRoll,
    protectedRoute: true,
  },
  {
    id: 13,
    name: "Company Policy",
    path: "/dashboard/companyPolicy",
    icon: "payment",
    authorisedUsers: ["1", "2"],
    exact: true,
    comp: CompanyPolicy,
    protectedRoute: false,
  },
  {
    path: "/dashboard/AddPayRollManagement",
    exact: true,
    comp: AddPayRoll,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/EditPayRollManagement",
    exact: true,
    comp: AddPayRoll,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/add-employee",
    exact: true,
    comp: AddEmployee,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/add-employee/:id",
    exact: true,
    comp: AddEmployee,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/time-sheet-details",
    exact: true,
    comp: TimeSheetDetails,
    protectedRoute: false,
    authorisedUsers: ["2"],
  },
  {
    path: "/dashboard/time-sheet-details/:date/:id/:Name",
    exact: true,
    comp: TimeSheetDetails,
    protectedRoute: false,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/:id",
    exact: true,
    comp: Settings,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/bank/add-bank",
    exact: true,
    comp: AddBank,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/bank/edit-bank/:id",
    exact: true,
    comp: AddBank,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/designation/add-designation",
    exact: true,
    comp: AddDesignation,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/designation/edit-designation/:id",
    exact: true,
    comp: AddDesignation,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/department/add-department",
    exact: true,
    comp: AddDepartment,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/department/edit-department/:id",
    exact: true,
    comp: AddDepartment,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/shift/add-shift",
    exact: true,
    comp: AddShift,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/shift/edit-shift/:id",
    exact: true,
    comp: AddShift,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/leaves/add-leaves/",
    exact: true,
    comp: AddLeaves,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/settings/leaves/edit-leaves/:id",
    exact: true,
    comp: AddLeaves,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/attendance",
    exact: true,
    comp: Attendance,
    protectedRoute: false,
    authorisedUsers: ["1", "2"],
  },
  {
    path: "/dashboard/add-project",
    exact: true,
    comp: AddProject,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/add-project/:id",
    exact: true,
    comp: AddProject,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/todos/:id",
    exact: true,
    comp: Todo,
    protectedRoute: false,
    authorisedUsers: ["1", "2"],
  },
  {
    path: "/dashboard/add-todo",
    exact: true,
    comp: AddTodo,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/add-todo/:id",
    exact: true,
    comp: AddTodo,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/AddLiveNewsUpdates",
    exact: true,
    comp: AddLiveNewsUpdates,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/AddLiveNewsUpdates/:id",
    exact: true,
    comp: AddLiveNewsUpdates,
    protectedRoute: true,
    authorisedUsers: ["1"],
  },
  {
    path: "/dashboard/add-leave/:id",
    exact: true,
    comp: AddLeave,
    protectedRoute: false,
    authorisedUsers: ["1", "2"],
  },
  {
    path: "/dashboard/LiveNewsUpdatesDetails/:id",
    exact: true,
    comp: LiveNewsUpdatesDetails,
    protectedRoute: false,
    authorisedUsers: ["1", "2"],
  },
  {
    path: "/dashboard/LiveNewsUpdatesList",
    exact: true,
    comp: LiveNewsUpdates,
    protectedRoute: false,
    authorisedUsers: ["1", "2"],
  },
];
