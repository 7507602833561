import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import constant from "../../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import { ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/typography";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import CryptoJS from "crypto-js";
import { CircularProgress } from "@material-ui/core";
const styles = () => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 25,
  },
  heading: {
    paddingTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
  CheckButton: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
  progress: {
    marginLeft: 530,
    marginTop: 300,
    position: "absolute",
  },
});

const markAttendance = (props) => {
  const { classes } = props;
  const [inTime, setinTime] = useState(null);
  const [outTime, setoutTime] = useState(null);
  const [checkattendanceData, setcheckattendanceData] = useState([]);
  const [loader, setloader] = useState(true);
  useEffect(() => {
    props.headerTitle("Attendance", 8, true);
    getAllAttendance();
  }, []);

  const getAllAttendance = (props) => {
    let Param = CryptoJS.AES.encrypt(
      JSON.stringify({
        orgID: JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("Item"),
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8)
        ).OrganizationID,
        EmpCode: JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("Item"),
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8)
        ).EmpCode,
      }),
      process.env.REACT_APP_SECRET_KEY
    ).toString();
    const body = { Param: Param };
    fetch(constant.API_ROOT + constant.GET_ATTENDANCE, {
      method: "POST",
      headers: constant.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const attendanceData = responseJson.filter((e) =>
          e.ShiftID === 1
            ? e.EmpCode ===
                JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).EmpCode && getcurrentDate() === e.InTime.split("T")[0]
            : (e.EmpCode ===
                JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).EmpCode &&
                getcurrentDate() === e.InTime.split("T")[0]) ||
              (e.EmpCode ===
                JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).EmpCode &&
                getpreviousDate() === e.InTime.split("T")[0])
        );
        if (attendanceData.length !== 0) {
          setcheckattendanceData(attendanceData);
          const hoursDiff = moment
            .duration(
              moment(new Date(), "YYYY/MM/DD HH:mm").diff(
                moment(attendanceData[0].InTime, "YYYY/MM/DD HH:mm")
              )
            )
            .asHours();
          const getInTime = attendanceData[0].InTime;
          if (attendanceData[0].ShiftID === 2) {
            if (hoursDiff > 20) {
              setinTime(null);
            } else {
              setinTime(getInTime);
            }
          } else {
            setinTime(getInTime);
          }
          if (attendanceData[0].OutTime !== null) {
            const getOutTime = attendanceData[0].OutTime;
            if (attendanceData[0].ShiftID === 2) {
              if (hoursDiff > 20) {
                setoutTime(null);
              } else {
                setoutTime(getOutTime);
              }
            } else {
              setoutTime(getOutTime);
            }
          }
        }
        setloader(true);
      })
      .catch((error) => {
        setloader(false);
        console.log(error);
      });
  };
  const getcurrentDate = () => {
    const date = new Date();
    return moment(date).format("YYYY-MM-DD");
  };
  const getpreviousDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return moment(date).format("YYYY-MM-DD");
  };
  const getHoursDifference = () => {
    const shift = checkattendanceData.map((item) => item.ShiftID);
    const intime = checkattendanceData.map((item) => item.InTime);
    if (shift[0] === 2) {
      const InTime = intime[0];
      const hoursDiff = moment
        .duration(
          moment(new Date(), "YYYY/MM/DD HH:mm").diff(
            moment(InTime, "YYYY/MM/DD HH:mm")
          )
        )
        .asHours();
      return hoursDiff;
    }
  };
  const checkIntimeDisable = () => {
    const intime = checkattendanceData.map((item) => item.InTime);
    if (
      checkattendanceData.find((e) => e.InTime === null) ||
      checkattendanceData.length === 0
    ) {
      return false;
    } else if (
      checkattendanceData.find((e) => e.ShiftID === 2) &&
      getHoursDifference() > 20 &&
      checkattendanceData.length === 1
    ) {
      return false;
    } else if (
      checkattendanceData.find((e) => e.ShiftID === 2) &&
      checkattendanceData.length === 2 &&
      intime[0] !== null
    ) {
      return true;
    } else {
      return true;
    }
  };
  const checkOutTimeDisable = () => {
    const outtime = checkattendanceData.map((item) => item.OutTime);
    if (
      checkattendanceData.find((e) => e.OutTime === null) ||
      checkattendanceData.length === 0
    ) {
      return false;
    } else if (
      checkattendanceData.find((e) => e.ShiftID === 2) &&
      getHoursDifference() > 20 &&
      checkattendanceData.length === 1
    ) {
      return false;
    } else if (
      checkattendanceData.find((e) => e.ShiftID === 2) &&
      checkattendanceData.length === 2 &&
      outtime[0] !== null
    ) {
      return true;
    } else {
      return true;
    }
  };
  const checkSaveButtonDisable = () => {
    const intime = checkattendanceData.map((item) => item.InTime);
    const outtime = checkattendanceData.map((item) => item.OutTime);
    if (checkattendanceData.length === 1) {
      if (
        checkattendanceData.find((e) => e.ShiftID === 2) &&
        getHoursDifference() > 20
      ) {
        return false;
      }
    }
    if (
      checkattendanceData.find((e) => e.InTime !== null) &&
      checkattendanceData.find((e) => e.OutTime !== null) &&
      checkattendanceData.length === 1
    ) {
      return true;
    } else if (
      checkattendanceData.find((e) => e.ShiftID === 2) &&
      getHoursDifference() > 20 &&
      checkattendanceData.length === 1
    ) {
      return false;
    } else if (
      checkattendanceData.find((e) => e.ShiftID === 2) &&
      checkattendanceData.length === 2 &&
      outtime[0] !== null &&
      intime[0] !== null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showHideOutTimeLabel = () => {
    if (checkattendanceData.length === 1) {
      if (
        checkattendanceData.find((e) => e.ShiftID === 2) &&
        getHoursDifference() > 20
      ) {
        return false;
      }
    }
    if (checkattendanceData.length !== 0) {
      return true;
    }
  };
  const submitAttendance = (e) => {
    const shift = checkattendanceData.map((item) => item.ShiftID);
    console.log(getHoursDifference(), "diff");
    if (inTime === null) {
      NotificationManager.error("Error", "Please Select In Time");
      e.preventDefault();
    } else if (
      shift[0] === 2 &&
      checkattendanceData.length !== 0 &&
      getHoursDifference() < 20 &&
      outTime === null
    ) {
      NotificationManager.error("Attendance Already Marked");
      e.preventDefault();
    } else if (
      shift[0] === 1 &&
      checkattendanceData.length !== 0 &&
      outTime === null
    ) {
      NotificationManager.error("Attendance Already Marked");
      e.preventDefault();
    } else {
      setloader(false);
      const validityPeriod = 600;
      const timestamp = Math.floor(moment.utc() / 1000);
      const expirationTime = timestamp + validityPeriod;
      let startInTime = CryptoJS.AES.encrypt(
        JSON.stringify({
          inTime:
            inTime === null
              ? inTime
              : moment.utc(inTime).format("YYYY MMMM DD HH:mm:ss"),
          time: expirationTime,
        }),
        process.env.REACT_APP_SECRET_KEY
      ).toString();
      const body = {
        EmpCode: JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("Item"),
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8)
        ).EmpCode,

        InTime: startInTime,
        OutTime:
          outTime === null
            ? outTime
            : moment.utc(outTime).format("YYYY MMMM DD HH:mm:ss"),
        type:
          (checkattendanceData.find((e) => e.ShiftID === 2) &&
            checkattendanceData.length === 1 &&
            checkattendanceData.find((e) => e.InTime !== null) &&
            checkattendanceData.find((e) => e.OutTime !== null)) ||
          checkattendanceData.length === 0
            ? "insert"
            : "update",
        ID: checkattendanceData.length !== 0 ? checkattendanceData[0].ID : "",
      };
      fetch(constant.API_ROOT + constant.ADD_ATTENDANCE, {
        method: "POST",
        headers: constant.API_HEADER,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then(() => {
          NotificationManager.success(
            "Attendance Marked Successfully",
            "Success",
            1500
          );
          getAllAttendance();
        })
        .catch((error) => {
          setloader(false);
          console.error(error);
        });
    }
  };

  const currentDate = () => {
    const date = moment().format("DD MMMM YYYY");
    return date;
  };
  const checkAttendance = () => {
    props.history.push(`/dashboard/attendance`);
  };

  return (
    <div>
      {loader ? (
        <Paper className={classes.root}>
          <ValidatorForm className={classes.form} onSubmit={submitAttendance}>
            <Typography component="h1" variant="h5">
              Mark Your Attendance
            </Typography>
            <Grid container spacing={12} className={classes.heading}>
              <Grid container spacing={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  disabled
                  value={currentDate()}
                />
              </Grid>
              <Grid item xs={6} className={classes.heading}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    label="In Time"
                    disabled={checkIntimeDisable()}
                    value={inTime}
                    onChange={setinTime}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {showHideOutTimeLabel() === true ? (
                <Grid item xs={6} className={classes.heading}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      label="Out Time"
                      disabled={checkOutTimeDisable()}
                      value={outTime}
                      onChange={setoutTime}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} className={classes.paper1}>
              <Button
                type="submit"
                disabled={checkSaveButtonDisable()}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Save
              </Button>
              {JSON.parse(
                CryptoJS.AES.decrypt(
                  localStorage.getItem("Item"),
                  process.env.REACT_APP_SECRET_KEY
                ).toString(CryptoJS.enc.Utf8)
              ).RoleId === 2 ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={(e) => checkAttendance(e)}
                >
                  Check Attendance
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </ValidatorForm>
        </Paper>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </div>
  );
};
export default withStyles(styles)(markAttendance);
