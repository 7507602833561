import React, { useState, useEffect } from 'react'
import Const from '../common/constant';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ImageCropper from '../../utils/imageCropper';

const UploadPhotograph = props => {
  const { isPhotoUploaded, photoUploaded, EmpCode, handleClose } = props;
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  useEffect(() => {
    if (photoUploaded !== '') {
      setImageToCrop(photoUploaded);
    }
  }, [isPhotoUploaded])

  const handleCroppedPhoto = async event => {
    event.preventDefault();
    if(props.Id) {
      handleClose(croppedImage)
  } else{
    const body = croppedImage;
    await fetch(`${Const.API_ROOT}${Const.UPLOAD_PROFILE_PICTURE}/${EmpCode}`, {
      method: 'POST',
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    }).then((response) => response.json())
      .then((responseJson) => {
        handleClose();
      })
  }
  }
  return (
    <div>
      <Dialog
        open={isPhotoUploaded}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Upload Profile Picture</DialogTitle>
        <DialogContent>
          <div>
            <ImageCropper
              imageToCrop={imageToCrop}
              onImageCropped={(croppedImage) => 
                {
                  setCroppedImage(croppedImage)}
              }
              fileName={props.Id ? props.Id : EmpCode}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCroppedPhoto} color="primary" autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UploadPhotograph
