import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Footer from "../../common/footer";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import Const from "../../common/constant";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CryptoJS from "crypto-js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 25,
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  heading: {
    marginTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  bigAvatar: {
    marginLeft: 80,
    marginTop: 15,
    width: 140,
    height: 140,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
});

var controller;
var signal;

class AddTodo extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);

    this.state = {
      TodoName: "",
      Status: "",
      projectList: [],
      loader: true,
      tabValue: 0,
      SelectedEmployeeName: "",
      SelectedEmployeeId: "",
      filterValue: "",
      filterBy: "Name",
      AllEmployees: [],
      EmployeeList: [],
      assignedTo: [],
      ProjectID: "",
      addedProjectId: "",
      options: [],
      addedMembers: [],
      selectedValue: [],
      TodoIdForEdit: this.props.match.params.id,
      buttonText: this.props.match.params.id ? "Update Todo" : "Add Todo",
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      action: this.props.match.params.id ? "update" : "add",
      projectList: [],
      projectId: JSON.parse(localStorage.getItem("project")),
    };
    this.style = {
      searchBox: {
        border: "none",
        borderBottom: "1px solid rgb(118, 118, 118)",
        borderRadius: "0px",
      },
    };
  }

  componentWillMount() {
    this.getProjects();
    this.props.loader(false);
    this.setState({
      loader: false,
    });
    if (this.state.TodoIdForEdit) {
      this.setToDoDetails();
      this.props.headerTitle("Update ToDo", 5, true);
    } else {
      this.props.headerTitle("Add a new ToDo", 5, true);
    }
  }
  setToDoDetails = () => {
    var body = {
      TodoId: this.state.TodoIdForEdit,
    };
    fetch(Const.API_ROOT + Const.GET_TODO_LIST, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          TodoName: responseJson.todos[0].Name,
          Status: responseJson.todos[0].Status,
          ProjectID: responseJson.todos[0].ProjectId,
          selectedValue: responseJson.taskmembers,
          addedProjectId: responseJson.todos[0].ProjectId,
          loader: false,
          addedMembers: responseJson.taskmembers,
        });
        this.getTeamMembers(responseJson.todos[0].ProjectId);
        this.props.loader(false);
      })
      .catch((error) => {
        this.props.loader(false);
        this.setState({
          loader: false,
        });
        console.error(error);
      });
  };
  getTeamMembers = (ProjectID) => {
    controller = new AbortController();
    signal = controller.signal;
    var body = {
      ProjectId: ProjectID,
      OrganizationID: this.state.OrganizationID,
    };
    fetch(Const.API_ROOT + Const.GET_TEAMMEMBERS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    })
      .then((response) => response.json())

      .then((responseJson) => {
        this.props.loader(false);
        this.setState({
          options: responseJson.employees,
          EmployeeList: responseJson.employees,
        });
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  };
  getProjects = () => {
    controller = new AbortController();
    signal = controller.signal;
    var body = {
      // ProjectId: ProjectID,
      OrganizationID: this.state.OrganizationID,
    };
    fetch(Const.API_ROOT + Const.GET_AllPROJECTS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    })
      .then((response) => response.json())

      .then((resp) => {
        this.props.loader(false);
        this.setState({
          projectList: resp,
          ProjectID: this.state.projectId,
        });
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
  };

  changeProject = (e) => {
    this.setState({
      ProjectID: e.target.value,
    });
    if (this.state.addedProjectId == e.target.value) {
      this.setState({
        selectedValue: this.state.addedMembers,
      });
    } else {
      this.setState({
        selectedValue: [],
      });
    }
    this.getTeamMembers(e.target.value);
  };

  handleSubmit = (e) => {
    this.props.loader(true);
    var body = {
      Name: this.state.TodoName,
      ProjectId: this.state.ProjectID,
      AssignedMembers: this.state.selectedValue,
      Status: this.state.Status,
      action: this.state.action,
      TodoId: this.state.TodoIdForEdit,
    };

    fetch(Const.API_ROOT + Const.ADD_NEW_ToDo, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.props.loader(false);
        if (responseJson.id != null || responseJson.id != "") {
          if (this.state.TodoIdForEdit) {
            NotificationManager.success(
              "ToDo updated successfully",
              "Success",
              2000
            );
          } else {
            NotificationManager.success(
              "ToDo Added successfully",
              "Success",
              2000
            );
          }
          setTimeout(
            function () {
              this.props.history.push("/dashboard/todos");
            }.bind(this),
            2000
          );
        } else {
          NotificationManager.error("Error", "please try again later.");
        }
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
    e.preventDefault();
  };

  handleChange = (event, value) => {
    this.setState({
      tabValue: value,
      filterValue: "",
      AllEmployees: this.state.EmployeeList,
    });
  };

  onRemove(selectedList, removedItem) {
    this.state.assignedTo = selectedList;
  }

  getParentStatus = () => {
    return this._isMounted;
  };

  selectEmployee(SelectedEmployeeId, label) {
    this.setState({
      SelectedEmployeeId: SelectedEmployeeId,
      SelectedEmployeeName: label,
    });
  }

  handlefilterResults(e) {
    this.setState({
      filterValue: e.target.value,
    });
    if (this.state.filterBy !== "")
      this.filterRecords(this.state.filterBy, e.target.value);
  }

  filterRecords = (filterBy, filterValue) => {
    var filteredRecords = this.state.EmployeeList.filter(function (emp) {
      var val = emp[filterBy];
      if (val) {
        if (val.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) {
          emp.name = "abc";
          return emp;
        }
      }
      return null;
    });

    this.setState({
      AllEmployees: filteredRecords,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {!this.state.loader ? (
          <Paper className={classes.root}>
            <ValidatorForm
              className={classes.form}
              onSubmit={this.handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item sm={2}></Grid>
                <Grid item xs={12} sm={7}>
                  <TextValidator
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      this.setState({ TodoName: e.target.value })
                    }
                    label="Title"
                    name="TodoName"
                    value={this.state.TodoName}
                    validators={["required"]}
                    errorMessages={["ToDo Name is required"]}
                  />
                </Grid>
                <Grid item sm={2}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={2}></Grid>
                <Grid item xs={12} sm={7}>
                  <FormControl margin="normal" fullWidth>
                    <SelectValidator
                      margin="normal"
                      fullWidth
                      style={{ marginBottom: "13px" }}
                      value={this.state.ProjectID}
                      label="Project"
                      onChange={(e) => this.changeProject(e)}
                      inputProps={{
                        name: "ProjectID",
                        id: "ProjectID",
                      }}
                      validators={["required"]}
                      errorMessages={["Project is required"]}
                    >
                      {this.state.projectList.map((data) => {
                        return (
                          <MenuItem key={data.ProjectId} value={data.ProjectId}>
                            {data.Name}
                          </MenuItem>
                        );
                      })}
                    </SelectValidator>
                  </FormControl>
                </Grid>
                <Grid item sm={2}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={2}>
                  {" "}
                </Grid>

                <Grid item xs={12} sm={7}>
                  <FormControl margin="normal" fullWidth>
                    <Autocomplete
                      multiple
                      id="tags-standard-manager"
                      value={this.state.selectedValue}
                      options={this.state.options}
                      getOptionLabel={(option) => option.Name}
                      onChange={(event, newValue) => {
                        this.setState({ selectedValue: newValue });
                      }}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          variant="standard"
                          label="Assigned To"
                          value={this.state.selectedValue}
                          validators={["required"]}
                          errorMessages={["Member is required"]}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={2}></Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={2}></Grid>
                <Grid item xs={12} sm={7}>
                  <FormControl margin="normal">
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label="Mark As Completed"
                      onChange={(e) =>
                        this.setState({ Status: e.target.checked })
                      }
                      checked={this.state.Status}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={2}></Grid>
              </Grid>

              <Grid container spacing={2} className={classes.heading}>
                <Grid item xs={12} className={classes.paper1}>
                  <Button
                    onClick={() => this.props.history.push("/dashboard/todos")}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <i className={`${classes.leftIcon} material-icons`}>
                      arrow_back
                    </i>{" "}
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <i className={`${classes.leftIcon} material-icons`}>
                      {this.props.match.params.id ? "update" : "grading"}
                    </i>{" "}
                    {this.state.buttonText}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
        ) : (
          ""
        )}
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(AddTodo);
