import React, { Component } from "react";
import Const from "../../../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { NotificationManager } from "react-notifications";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Footer from "../../../common/footer";
import CryptoJS from "crypto-js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 25,
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  heading: {
    marginTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  bigAvatar: {
    marginLeft: 80,
    marginTop: 15,
    width: 140,
    height: 140,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
  },
});
class AddLeaves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaveId: "",
      year: "",
      typeofLeave: "Full Day",
      numberOfLeaves: "",
      id: "",
      leaveIdforSave: "",
      leaveIdforEdit: this.props.match.params.id,
      action: this.props.match.params.id ? "update" : "add",
      buttonText: this.props.match.params.id ? "Update Leaves" : "Add Leaves",
      OrganizationID: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).OrganizationID,
      loader: true,
    };
  }
  componentWillMount() {
    var body = {
      OrganizationID: this.state.OrganizationID,
    };

    if (this.state.leaveIdforEdit) {
      this.setLeaves();
    } else {
      fetch(Const.API_ROOT + Const.GET_LEAVE_MASTER_DATA, {
        method: "POST",
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("responseJson : " + JSON.stringify(responseJson));

          this.setState({
            leaveId: this.addZero(
              responseJson.LastLeaveId[0].leaveId
                ? parseInt(responseJson.LastLeaveId[0].leaveId) + 1
                : 1,
              3
            ),
            leaveIdforSave: this.addZero(
              responseJson.LastLeaveId[0].leaveId
                ? parseInt(responseJson.LastLeaveId[0].leaveId) + 1
                : 1,
              3
            ),
            loader: false,
          });
          this.props.loader(false);
          this.props.headerTitle("Add Leaves", 10, true);
        })
        .catch((error) => {
          this.props.loader(false);
          this.setState({
            loader: false,
          });
          console.error(error);
        });
    }
  }
  setLeaves = () => {
    var body = {
      leaveId: this.state.leaveIdforEdit,
    };
    fetch(Const.API_ROOT + Const.GET_LEAVE_SETTINGS_DETAILS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson = responseJson[0];
        this.setState({
          leaveId: this.addZero(parseInt(responseJson.ID), 3),
          year: responseJson.Year,
          numberOfLeaves: responseJson.NumberOfLeaves,
          loader: false,
        });
        this.props.loader(false);
        this.props.headerTitle("Leave Details ", 10, true);
      })
      .catch((error) => {
        this.props.loader(false);
        this.setState({
          loader: false,
        });
        console.error(error);
      });
  };
  addZero(Number, totalLength) {
    Number = Number.toString();
    for (var i = Number.length; i < totalLength; i++) {
      Number = 0 + Number;
    }
    return Number;
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (e) => {
    this.props.loader(true);
    var body = {
      Year: this.state.year,
      NumberOfLeaves: this.state.numberOfLeaves,
      TypeOfLeave: 1,
      type: this.state.action,
      ID:
        this.state.action === "update"
          ? this.state.leaveIdforEdit
          : this.state.leaveIdforSave,
    };
    console.log(JSON.stringify(body));
    fetch(Const.API_ROOT + Const.ADD_LEAVES_SETTINGS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        this.props.loader(false);
        if (responseJson.rowsAffected) {
          if (this.state.leaveIdforEdit) {
            NotificationManager.success(
              "Leaves updated successfully",
              "Success",
              2000
            );
          } else {
            NotificationManager.success(
              "Leaves Added successfully",
              "Success",
              2000
            );
          }
        } else {
          NotificationManager.error("Error", "please try again later.");
        }
      })
      .catch((error) => {
        this.props.loader(false);
        console.error(error);
      });
    e.preventDefault();
    this.props.history.push(
      "/dashboard/settings/" + Const.SETTINGS_TABS.Leaves
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {!this.state.loader ? (
          <Paper className={classes.root}>
            <ValidatorForm
              className={classes.form}
              onSubmit={this.handleSubmit}
            >
              <Typography component="h1" variant="h5">
                Leaves Details
              </Typography>
              <Grid container spacing={24}>
                <Grid item xs={6}>
                  <FormControl disabled margin="normal" required fullWidth>
                    <InputLabel>Id</InputLabel>
                    <Input
                      id="id"
                      name="id"
                      autoComplete="id"
                      value={this.state.leaveId}
                      onChange={(e) => this.setState({ id: e.target.value })}
                      placeholder="Id"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    margin="normal"
                    fullWidth
                    label="Number of Leaves"
                    onChange={(e) =>
                      this.setState({ numberOfLeaves: e.target.value })
                    }
                    value={this.state.numberOfLeaves}
                    validators={["required"]}
                    errorMessages={["Leaves are required"]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextValidator
                    margin="normal"
                    fullWidth
                    label="Year"
                    onChange={(e) => this.setState({ year: e.target.value })}
                    value={this.state.year}
                    validators={["required"]}
                    errorMessages={["Leaves are required"]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={24} className={classes.heading}>
                <Grid item xs={12} className={classes.paper1}>
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        "/dashboard/settings/" + Const.SETTINGS_TABS.Leaves
                      )
                    }
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <i className={`${classes.leftIcon} material-icons`}>
                      arrow_back
                    </i>{" "}
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <i className={`${classes.leftIcon} material-icons`}>
                      {this.props.match.params.id ? "update" : "person_add"}
                    </i>{" "}
                    {this.state.buttonText}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
        ) : (
          ""
        )}
        <Footer />
      </div>
    );
  }
}
export default withStyles(styles)(AddLeaves);
