import React, { useEffect, useRef, useState } from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  MenuList,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Const from "../common/constant";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import CryptoJS from "crypto-js";

var controller;
var signal;
const useStyles = makeStyles({
  menuHT: {
    top: "89px !important",

    // width: '54vh',
    left: "1204px !important",
    // left: '1255px !important'
  },
});

export default function NotificationMenu(props) {
  controller = new AbortController();
  signal = controller.signal;
  const { socketHandler } = props;

  const classes = useStyles();

  const [width, setWidth] = useState(0);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const menuRef = useRef(null);
  const [localStorageUserDetails] = useState(
    JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    )
  );
  const [allLeaveNotifications, setAllLeaveNotifications] = useState([]);

  const menuItemBorderBottom = {
    borderBottom: `1px solid yellow`,
  };

  useEffect(() => {
    socketHandler.on("new_notification", function (data) {
      setAllLeaveNotifications((oldAllLeaveNotifications) => [
        data,
        ...oldAllLeaveNotifications,
      ]);
    });
  }, []);

  useEffect(() => {
    (async () => {
      const leave_notifications_body = {
        SentToEmpCode: localStorageUserDetails.EmpCode,
      };
      const allLeaveNotificationsAPI = await fetch(
        Const.API_ROOT + Const.GET_LEAVE_NOTIFICATIONS,
        {
          method: "POST",
          headers: Const.API_HEADER,
          signal: signal,
          body: JSON.stringify(leave_notifications_body),
        }
      );
      const allLeaveNotificationsAPIJson =
        await allLeaveNotificationsAPI.json();
      setAllLeaveNotifications(allLeaveNotificationsAPIJson);
    })();
  }, []);

  useEffect(() => {
    const menuHTML = menuRef.current
      ? menuRef.current.getBoundingClientRect()
      : { width: 0, height: 0, left: 0, top: 0 };
    setWidth(menuHTML.width);
    setPosition({
      left: menuHTML.left + 5,
      top: menuHTML.top + menuHTML.height - 5,
    });
  }, [menuRef]);

  const handleClose = async (popupState) => {
    popupState.close();
    if (allLeaveNotifications.find((noti) => noti.Viewed === 0)) {
      const update_leave_notifications_body = {
        SentToEmpCode: localStorageUserDetails.EmpCode,
      };
      const updatedLeaveNotificationsAPI = await fetch(
        Const.API_ROOT + Const.UPDATE_LEAVE_NOTIFICATIONS,
        {
          method: "POST",
          headers: Const.API_HEADER,
          signal: signal,
          body: JSON.stringify(update_leave_notifications_body),
        }
      );
      const updatedLeaveNotificationsAPIJson =
        await updatedLeaveNotificationsAPI.json();
      setAllLeaveNotifications(updatedLeaveNotificationsAPIJson);
    }
  };

  const TypographyText = ({ notification }) => (
    <Typography
      style={{
        padding: "8px 17px",
        opacity: "0.4",
        margin: "-12px 0px 0px 0px",
        fontSize: "0.9rem",
      }}
    >
      Applied at <em>{notification.AppliedTime}</em> on{" "}
      <em>{notification.AppliedDate}</em>
    </Typography>
  );
  const NotiIcon = () => {
    if (allLeaveNotifications.length === 0) {
      return <NotificationsNoneIcon />;
    } else if (allLeaveNotifications) {
      const doesNewNotificationExist = allLeaveNotifications.find(
        (noti) => noti.Viewed === 0
      );
      if (doesNewNotificationExist) {
        return <NotificationsActiveIcon />;
      }
    }
    return <NotificationsIcon />;
  };

  const NotiDescription = (notification) => {
    if (notification.IsManager === 1) {
      return notification.ManagerNotificationDescription;
    } else if (notification.IsManager === 2) {
      return notification.ApplierNotificationDescription;
    }
    return notification.AdminNotificationDescription;
  };
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <div>
            <IconButton
              color="inherit"
              {...bindTrigger(popupState)}
              ref={menuRef}
            >
              <NotiIcon />
            </IconButton>
          </div>
          <Menu
            {...bindMenu(popupState)}
            PaperProps={{
              sx: {
                width: width,
              },
            }}
            classes={{
              paper: classes.menuHT,
            }}
            onClose={(event) => handleClose(popupState)}
          >
            <MenuList>
              {allLeaveNotifications && allLeaveNotifications.length === 0 && (
                <>
                  <MenuItem
                    style={{
                      whiteSpace: "normal",
                    }}
                  >
                    <ListItemIcon sx={{ color: "success.main" }}>
                      <NotificationsNoneIcon
                        fontSize="small"
                        style={{ color: "#0097a7" }}
                      />
                    </ListItemIcon>
                    <ListItemText>No Notifications!</ListItemText>
                  </MenuItem>
                </>
              )}
              {allLeaveNotifications &&
                allLeaveNotifications.length > 0 &&
                allLeaveNotifications.map((notification, index) => {
                  if (notification.Viewed === 0) {
                    return (
                      <>
                        <MenuItem style={{ whiteSpace: "normal" }}>
                          <ListItemIcon sx={{ color: "success.main" }}>
                            <FiberManualRecordIcon
                              fontSize="small"
                              style={{ color: "#0097a7" }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            {NotiDescription(notification)}
                          </ListItemText>
                        </MenuItem>
                        <TypographyText notification={notification} />
                        <Divider />
                      </>
                    );
                  }
                  return (
                    <>
                      <MenuItem
                        sx={
                          index !== allLeaveNotifications.length - 1
                            ? menuItemBorderBottom
                            : {}
                        }
                        style={{ whiteSpace: "normal" }}
                      >
                        <ListItemText>
                          {NotiDescription(notification)}
                        </ListItemText>
                      </MenuItem>
                      <TypographyText notification={notification} />
                      <Divider />
                    </>
                  );
                })}
            </MenuList>
          </Menu>
        </>
      )}
    </PopupState>
  );
}
