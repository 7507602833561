import React, { Component } from "react";
import Const from "../common/constant";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { NotificationManager } from "react-notifications";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CardActions from "@material-ui/core/CardActions";
import Footer from "../common/footer";
import Slide from "@material-ui/core/Slide";
import "react-dates/lib/css/_datepicker.css";
import CryptoJS from "crypto-js";

let controller;
let signal;
let moment = require("moment");
const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  cardHover: {
    transition: "transform .5s",
    boxShadow: "0 10px 40px 0 rgb(0 0 0 / 12%)",
    overflow: "hidden",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: "0 10px 40px 0 rgb(0 0 0 / 26%)",
    },
  },
  overflowtext: {
    whiteSpace: "initial",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "3",
  },
  table: {
    minWidth: 700,
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  action: {
    cursor: "pointer",
    margin: 2,
  },
  deleteAction: {
    color: "#666",
  },
  editAction: {
    color: "#666",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  button: {
    backgroundColor: "#fb7813 !important",
  },
  di: {
    display: "inline",
    float: "left",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: "100%",
  },
  custFlex: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  alignItemsCenter: { alignItems: "center" },
  pos: {
    fontSize: 13,
    fontWeight: "500",
    color: "#999",
  },
  nm: { margin: "0 !important" },
  values: { position: "relative", marginTop: "10px" },
  custCardAction: {
    padding: "0 !important",
    borderTop: "1px solid #0097a7",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 6,
  },
  custCardActionButton: {
    width: "50% !important",
    borderRadius: 0,
    margin: 0,
    color: "#444",
  },
  rightSeprator: {
    position: "absolute",
    width: 2,
    height: 50,
    background: "#c0c0c0",
    top: "50%",
    transform: "translateY(-50%)",
  },
  selectorElement: { position: "relative" },
  heightFix: { minHeight: 180 },
  empDate1: {
    color: " #000000",
    display: "flex",
    flexDirection: "column",
    marginRight: "30px",
  },
  bgroupImg: {
    height: "auto",
  },
  textDecor: {
    color: " #0097a7",
    fontWeight: 500,
    marginBottom: 8,
    textTransform: "capitalize",
    "&:hover": {
      fontWeight: 500,
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  dateContainer: {
    marginBottom: 10,
    fontWeight: 400,
    color: "#444",
    width: "100%",
    border: "1px solid transparent",
    height: "100%",
    position: "relative",
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "initial",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
  },
});
class LiveNewsUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LiveNewsUpdatesId: "",
      AllLiveNewsUpdates: [],
      LiveNewsUpdates: [],
      columns: [],
      liveNewsUpdatesIdForDelete: "",
      confirmDeleteDialog: false,
      listView: false,
      isComponentUpdating: false,
      allBannerImage: {},
    };
  }
  getDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  componentWillUnmount() {
    controller.abort();
  }
  async componentDidMount() {
    controller = new AbortController();
    signal = controller.signal;
    this.props.loader(true);
    this.props.headerTitle("News Updates", 9);
    let body = { defaultColumns: true };
    const response = await fetch(Const.API_ROOT + Const.GET_LIVE_NEWSUPDATES, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    });
    const responseJson = await response.json();
    let roleId = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).RoleId;
    let dataLiveNewsUpdates =
      roleId === 1
        ? responseJson.liveNewsUpdates
        : responseJson.liveNewsUpdates.filter((x) => x.Status === true);
    const local_banner_pictures = {};
    await Promise.all(
      dataLiveNewsUpdates.map(async (news) => {
        const image_url = `${Const.API_ROOT}${Const.GET_BANNER_IMAGE}/${news.id}/${news.id}.jpeg`;
        const banner_pictures_response = await fetch(image_url);
        local_banner_pictures[news.id] =
          banner_pictures_response.status === 200 && image_url;
      })
    );
    this.setState({
      allBannerImage: local_banner_pictures,
      LiveNewsUpdates: dataLiveNewsUpdates,
      AllLiveNewsUpdates: dataLiveNewsUpdates,
      LiveNewsUpdatesId: responseJson.id,
      columns: responseJson.defaultColumns,
    });
    this.props.loader(false);
  }
  transition(props) {
    return <Slide direction="up" {...props} />;
  }
  editLiveNewsUpdates = (id) => {
    this.props.history.push("/dashboard/AddLiveNewsUpdates/" + id);
  };
  addLiveNewsUpdates() {
    this.props.history.push("/dashboard/AddLiveNewsUpdates");
  }
  confirmDelete = (id, index) => {
    this.setState({
      confirmDeleteDialog: true,
      liveNewsUpdatesIdForDelete: id,
      indexLiveNewsUpdatesIdForDelete: index,
    });
  };
  handleClose = () => {
    this.setState({ confirmDeleteDialog: false });
  };
  deleteliveNewsUpdates = () => {
    const {
      LiveNewsUpdates,
      indexLiveNewsUpdatesIdForDelete,
      liveNewsUpdatesIdForDelete,
    } = this.state;
    LiveNewsUpdates.splice(indexLiveNewsUpdatesIdForDelete, 1);
    let body = {
      deleteId: liveNewsUpdatesIdForDelete,
    };
    fetch(Const.API_ROOT + Const.DELETE_LIVE_NEWSUPDATES, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
      signal: signal,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.rowsAffected) {
          this.setState({ confirmDeleteDialog: false });
          NotificationManager.success(
            "LiveNews  deleted successfully",
            "Success",
            2000
          );
        } else {
          NotificationManager.error(
            "Error",
            "Error in delete live newsUpdate."
          );
        }
      })
      .catch(() => {});
  };

  status = (data, column) => {
    let val = data[column];
    if (column === "DateOfEntry") return this.getDate(val.split("T")[0]);
    if (column === "Description") return val.replace(/&nbsp;|(<[^>]+>)/g, "");
    return val;
  };

  handleLiveNewsUpdates = (id) => {
    this.props.history.push("/dashboard/LiveNewsUpdatesDetails/" + id);
  };

  render() {
    const { classes } = this.props;
    let roleId = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).RoleId;
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            display="flex"
            justify="space-between"
            style={{ justifyContent: "end" }}
          >
            <Grid item xs={6} sm={8} md={4} className={classes.custFlex}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.listView}
                    onChange={(e) =>
                      this.setState({
                        listView: !this.state.listView,
                      })
                    }
                    value={this.state.listView}
                  />
                }
                label={!this.state.listView ? "Tiles View" : "List View"}
              />
              {roleId === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.addLiveNewsUpdates()}
                >
                  <i className={`${classes.leftIcon} material-icons `}>
                    newspaper
                  </i>{" "}
                  +Add News Updates
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>

        {this.state.listView ? (
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.state.columns.map((col, i) => {
                    return (
                      <TableCell align="right" key={i}>
                        {col.ColumnName}
                      </TableCell>
                    );
                  })}

                  {roleId === 1 && <TableCell align="right">Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.LiveNewsUpdates.map((n, index) => {
                  return (
                    <TableRow className={classes.tableRowHover} key={n.id}>
                      {this.state.columns.map((col, i) => {
                        return (
                          <TableCell align="right" key={i}>
                            {this.status(n, col.ColumnName)}
                          </TableCell>
                        );
                      })}
                      {roleId === 1 && (
                        <TableCell align="right">
                          <span
                            className={classes.action}
                            onClick={() => this.editLiveNewsUpdates(n.id)}
                          >
                            <i
                              className={`${classes.editAction} material-icons`}
                              aria-hidden="true"
                            >
                              edit
                            </i>
                          </span>
                          <span className={classes.action}>
                            <i
                              className={`${classes.deleteAction} material-icons`}
                              onClick={() => this.confirmDelete(n.id, index)}
                              aria-hidden="false"
                            >
                              delete
                            </i>
                          </span>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Grid container spacing={2}>
            {this.state.LiveNewsUpdates.map((data, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={i}
                  className={classes.selectorElement}
                  style={{ padding: 20 }}
                >
                  <Typography
                    className={`${classes.dateContainer} ${classes.cardHover} `}
                    style={{ background: "#fff" }}
                  >
                    <Typography
                      className={`${classes.empDate1} `}
                      style={{ marginRight: "0" }}
                      variant="subtitle1"
                    >
                      {this.state.allBannerImage[data.id] ? (
                        <img
                          alt=""
                          className={classes.bgroupImg}
                          src={this.state.allBannerImage[data.id]}
                          style={{ width: "100%" }}
                          onClick={() => this.handleLiveNewsUpdates(data.id)}
                        />
                      ) : (
                        <img
                          alt=""
                          className={classes.bgroupImg}
                          src={require("../images/noImage.jpeg")}
                          style={{ width: "100%" }}
                          onClick={() => this.handleLiveNewsUpdates(data.id)}
                        />
                      )}
                      <Card
                        className={`${classes.card}  ${classes.heightFix}`}
                        style={{
                          width: "100%",
                          boxShadow: "unset",
                          borderRadius: "0",
                        }}
                      >
                        <CardContent
                          onClick={() => this.handleLiveNewsUpdates(data.id)}
                          style={{ paddingBottom: 50 }}
                        >
                          <Grid container spacing={2}>
                            <Typography
                              className={` ${classes.textDecor} ${classes.overflowtext} `}
                            >
                              {data.Title}
                            </Typography>
                            <Typography className={`${classes.dateContainer} `}>
                              {this.getDate(data.DateOfEntry)}
                            </Typography>
                            <Typography
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                              }}
                              className={`${classes.dateContainer} ${classes.description} `}
                            >
                              {data.Description.replace(
                                /&nbsp;|(<[^>]+>)/g,
                                ""
                              )}
                            </Typography>
                          </Grid>
                        </CardContent>
                        {roleId === 1 && (
                          <CardActions className={classes.custCardAction}>
                            <Button
                              className={classes.custCardActionButton}
                              size="small"
                              color="primary"
                              onClick={() => this.editLiveNewsUpdates(data.id)}
                            >
                              <i
                                className={`${classes.editAction} material-icons`}
                                aria-hidden="true"
                              >
                                edit
                              </i>
                              &nbsp;Edit
                            </Button>
                            <Button
                              className={classes.custCardActionButton}
                              size="small"
                              color="primary"
                              onClick={() => this.confirmDelete(data.id, i)}
                            >
                              <i
                                className={`${classes.deleteAction} material-icons`}
                                aria-hidden="true"
                              >
                                delete
                              </i>
                              &nbsp;Delete
                            </Button>
                          </CardActions>
                        )}
                      </Card>
                    </Typography>
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Dialog
          open={this.state.confirmDeleteDialog}
          onClose={this.handleClose}
          transitionComponent={this.transition}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this News?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.deleteliveNewsUpdates}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    );
  }
}
export default withStyles(styles)(LiveNewsUpdates);
