import React, { Component } from "react";
import Const from "../common/constant";
import { withStyles } from "@material-ui/core/styles";
import checkIfImageExists from "../../utils/imageExistenceChecker";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Footer from "../common/footer";
import CryptoJS from "crypto-js";
let moment = require("moment");

const styles = (theme) => ({
  w100: {
    width: "100%",
  },
  des: {
    fontSize: 18,
    marginTop: 15,
    paddingBottom: 15,
  },
  attachAction: {
    color: "#fb7813",
    float: "Left",
  },
  pdflink: {
    marginTop: 15,
    fontSize: 16,
    color: "#fb7813",
    paddingBottom: 30,
  },
});
class LiveNewsUpdatesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveNewsUpdatesId: this.props.match.params.id,
      description: "",
      title: "",
      dateOfEntry: "",
      attachment: [],
      selectedFileName: [],
    };
  }
  componentWillUnmount() {}

  componentDidMount() {
    if (this.state.liveNewsUpdatesId) {
      this.setUserDetails();
      this.setUserAttachments();
      this.setBannerImage();
    }
    let index =
      JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).RoleId === 1
        ? 8
        : 0;
    this.props.loader(false);
    this.props.headerTitle("Live NewsUpdates Details", index, true);
  }

  setUserAttachments = () => {
    fetch(
      `${Const.API_ROOT}${Const.GET_NEWS_UPDATE_ATTACHMENTS}/${this.state.liveNewsUpdatesId}`
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.message) {
        } else {
          const uploaded = [];
          response.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
              uploaded.push(file);
            }
          });
          for (const key of Object.keys(response)) {
            this.state.selectedFileName.push({
              name: response[key].name,
              url: response[key].url,
            });
          }
          this.setState({ attachment: uploaded });
        }
      });
  };

  setUserDetails = () => {
    let body = {
      LiveNewsUpdatesId: this.state.liveNewsUpdatesId,
    };
    fetch(Const.API_ROOT + Const.GET_LIVE_NEWSUPDATES_DETAILS, {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson = responseJson.liveNewsUpdatesDetails[0];
        this.setState({
          title: responseJson.Title,
          dateOfEntry: this.getDate(responseJson.DateOfEntry.split("T")[0]),
          description: responseJson.Description,
        });
        console.log(responseJson);
        this.props.loader(false);
        this.props.headerTitle("Live NewsUpdates Details", 9, true);
      })
      .catch((error) => {
        this.props.loader(false);
        this.setState({
          loader: false,
        });
      });
  };

  getDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  setBannerImage = () => {
    checkIfImageExists(
      `${Const.API_ROOT}${Const.GET_BANNER_IMAGE}/${this.state.liveNewsUpdatesId}/${this.state.liveNewsUpdatesId}.jpeg`,
      (exists) => {
        console.log("exists", exists);
        if (exists) {
          this.setState({
            previewimg: `${Const.API_ROOT}${Const.GET_BANNER_IMAGE}/${this.state.liveNewsUpdatesId}/${this.state.liveNewsUpdatesId}.jpeg`,
            isPhotoUploaded: false,
            photoUploaded: "",
            showBannerImageView: true,
          });
        } else {
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { previewimg, description, title, dateOfEntry } = this.state;
    return (
      <div>
        <Grid item xs={12}>
          {previewimg && (
            <img alt="" src={previewimg} className={classes.w100} />
          )}
          <h1 className="h1">{title}</h1>
          <Typography className="head1">
            <b>Date</b> : {dateOfEntry}
          </Typography>

          <Typography
            className={classes.des}
            dangerouslySetInnerHTML={{ __html: description }}
          ></Typography>
          <p>
            {this.state.selectedFileName.map((value, index) => (
              <p>
                <a className={classes.pdflink} href={value.url}>
                  <i
                    className={`${classes.attachAction} material-icons`}
                    aria-hidden="true"
                  >
                    attach_file{" "}
                  </i>
                  {value.name}
                </a>
              </p>
            ))}
          </p>
        </Grid>
        <Footer style={{ padding: 30 }} />
      </div>
    );
  }
}
export default withStyles(styles)(LiveNewsUpdatesDetails);
