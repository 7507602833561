import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { Select, MenuItem, Card } from "@material-ui/core";
import Const from "../../common/constant";
import { NotificationManager } from "react-notifications";
import readXlsxFile from "read-excel-file";
import CryptoJS from "crypto-js";
import tableHeadRow from "../../../utils/salaryTableRow"

const styles = () => ({
  filter_paper: {
    padding: "25px",
  },
  button: {
    backgroundColor: "#fb7813 !important",
  },
  actionText: {
    textDecoration: "underline",
  },
  yearText: {
    fontSize: "20px",
    marginRight: "16px",
  },
  uploadSheet: {
    fontSize: "20px",
  },
  table: {
    borderCollapse: "collapse"
  },
  borderTable: {
    border: "1px solid #ccc",
    padding: "6px",
    minWidth: "80px",
    textAlign: "center"
  }, root: {
    width: "100%",
    padding: 25,
  },
  table: {
    minWidth: 700,
  },
  tableCell: {
    width: "20%",
  },
});

const AddPayRoll = (props) => {
  const { classes } = props;
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [currentMonth, setCurrentMonth] = useState(JSON.stringify({
    id: parseInt(moment().format("M")),
    name: moment().format("MMMM"),
  }));
  const [rows, setrows] = useState(null);
  const [rowData, setrowData] = useState([])
  const [cols, setcols] = useState(null);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [loadSheet, setloadSheet] = useState(false)
  const [fileName, setFileName] = useState("");
  const [attachment, setAttachment] = useState([]);

  useEffect(() => {
    props.headerTitle("PayRoll management", 12, true);
    if (props.location.state) {
      if (props.location.state.currentYear) {
        setCurrentYear(props.location.state.currentYear);
      } else {
        props.loader(true);
        let date = props.location.state.date;
        setCurrentYear(moment(date).year());
        setCurrentMonth(JSON.stringify({
          id: parseInt(moment(date).format("M")),
          name: moment(date).format("MMMM"),
        }));
        getExcelFile(moment(date).year(), parseInt(moment(date).format("M")));
      }
    }
  }, []);

  const fileHandler = (event) => {
    props.loader(true);
    let fileObj = event.target.files[0];
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    setFileName(fileObj.name);
    setAttachment(chosenFiles);
    readXlsxFile(event.target.files[0]).then((rows) => {
      setloadSheet(false)
      setcols(rows[0]);
      setrows(rows.slice(1, rows.length - 1));
      setdataLoaded(true);
      props.loader(false);
    });

  };

  const getYearDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(50), (v, i) => (
      <MenuItem key={i} value={year + i}>
        {year + i}
      </MenuItem>
    ));
  };

  const getMonthDropList = () => {
    const months = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" },
    ];
    return months.map((month, i) => {
      return (
        <MenuItem key={month.id} value={JSON.stringify({
          id: month.id, name: month.name
        })}>
          {month.name}
        </MenuItem>
      );
    });
  };

  const checkUploadedSalary = async () => {
    let body = {
      year: currentYear,
      month: JSON.parse(currentMonth).id
    };
    const res = await fetch(Const.API_ROOT + Const.CHECK_UPLOADED_SALARY_YEAR, { method: 'POST', headers: Const.API_HEADER, body: JSON.stringify(body) });
    const responseJson = await res.json();
    if (responseJson.length !== 0) NotificationManager.error("Error", "PayRoll is Uploaded already with selected year and month.");
    else document.getElementById("selectFile").click();
  };
  const uploadFiles = () => {
    if (props.location.state && !(props.location.state.date)) checkUploadedSalary()
    else document.getElementById("selectFile").click();
  };

  const saveExcelFile = async () => {
    props.loader(true);
    const formData = new FormData();
    try {
      for (const key of Object.keys(attachment)) {
        formData.append("files", attachment[key]);
      }
    } catch (ex) {
      console.log("[EXCEPTION] Formdata: ", ex);
    }
    let requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    await fetch(
      `${Const.API_ROOT}${Const.UPLOAD_SALARY_SHEET}/${currentYear}/${JSON.parse(currentMonth).id}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((responseJson) => {
        props.loader(false);
        if (responseJson) {
          if (props.location.state && props.location.state.date) {
            NotificationManager.success(
              "PayRoll updated successfully",
              "Success",
              2000
            );
          } else {
            NotificationManager.success(
              "PayRoll Added successfully",
              "Success",
              2000
            );
          }
          setTimeout(
            function () {
              props.history.push("/dashboard/PayRollManagement");
            }.bind(this),
            2000
          );
        } else {
          NotificationManager.error("Error", "please try again later.");
        }
      })
      .catch((err) => console.error(err));
  };

  const getExcelFile = async (year, month) => {
    let data = {
      year,
      month
    }
    let requestOptions = {
      method: "POST",
      headers: Const.API_HEADER,
      body: JSON.stringify({
        Param: CryptoJS.AES.encrypt(
          JSON.stringify(data),
          process.env.REACT_APP_SECRET_KEY
        ).toString(),
      }),
      redirect: "follow",
    };
    await fetch(`${Const.API_ROOT}${Const.GET_EXCEL_FILE}`, requestOptions).then((response) => response.json())
      .then((response) => {
        if (response.message) {
          props.loader(false);
        }
        setrowData(response)
        setloadSheet(true);
        props.loader(false);
      })
  }
  return (
    <div>
      <div>
        <div style={{ padding: "40px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "30px",
            }}
            className={classes.selectFields}
          >
            <div>
              <p className={classes.yearText}>Year</p>
              {currentYear && (
                <Select
                  disabled={true}
                  value={currentYear}
                  variant="standard"
                  displayEmpty={true}
                  width="fullWidth"
                  onChange={(e) => setCurrentYear(e.target.value)}
                  style={{ width: "300px", marginRight: "80px" }}
                >
                  {getYearDropList()}
                </Select>
              )}
            </div>
            <div>
              <p className={classes.yearText}>Month</p>
              {currentMonth && (
                <Select
                  disabled={props.location.state && props.location.state.date}
                  variant="standard"
                  displayEmpty={true}
                  width="fullWidth"
                  value={currentMonth}
                  onChange={(e) => {
                    setCurrentMonth(e.target.value);
                  }}
                  style={{ width: "300px" }}
                >
                  {getMonthDropList()}
                </Select>
              )}
            </div>

          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => uploadFiles(e)}
              className={classes.button}
              style={{
                marginRight: "150px",
                padding: "20px",
                borderRadius: "0",
              }}
            >
              <i
                className={`${classes.leftIcon} material-icons `}
                style={{ marginRight: "10px" }}
              >
                upload
              </i>{" "}
              Upload Salary Sheet
            </Button>
            <input
              accept=".xlsx"
              id="selectFile"
              type="file"
              name="file"
              onChange={fileHandler}
              style={{ padding: "10px", display: "none" }}
            />
            {fileName && <a className={classes.uploadSheet}>{fileName}</a>}
          </div>
          <div>
            {(dataLoaded === true || loadSheet === true) && (
              <div>
                <Card
                  body
                  outline
                  color="secondary"
                  className="restrict-card"
                  style={{
                    height: "300px",
                    overflow: "scroll",
                    marginBottom: "20px",
                  }}
                >
                  <table id="emp" className={classes.table}>
                    <thead>
                      {loadSheet === true ? (
                        <tr>
                          {tableHeadRow.map((p, index) => {
                            return <th key={index} className={classes.borderTable}>{p}</th>;
                          })}
                        </tr>
                      ) : <tr>
                        {dataLoaded === true && cols.map((p, index) => {
                          return <th key={index} className={classes.borderTable}>{p}</th>;
                        })}
                      </tr>}

                    </thead>
                    <tbody>
                      {loadSheet === true ? (
                        rowData.map((row, index) => (
                          <tr key={index}>
                            <td className={classes.borderTable}>{index + 1}</td>
                            {Object.values(row).map((value, index1) => (
                              <td key={index1} className={classes.borderTable}>
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))
                      ) : dataLoaded === true && rows.map((p, index) => {
                        return (
                          <tr key={index}>
                            {p.map((i, index1) => {
                              return <td key={index1} className={classes.borderTable}>{i}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            )}
            <div style={{ textAlign: "end" }}>
              {dataLoaded && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) =>
                    attachment.length > 0 ?
                      saveExcelFile() : props.history.push("/dashboard/PayRollManagement")
                  }
                  className={classes.button}
                  style={{
                    borderRadius: "0",
                  }}
                >
                  {props.location.state && props.location.state.date
                    ? "update "
                    : "save"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withStyles(styles)(AddPayRoll);
