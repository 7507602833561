import React, { useState, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Const from "../common/constant";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import moment from "moment";
import {
    Select,
    MenuItem,
  } from "@material-ui/core";
const styles = () => ({
    filter_paper: {
        padding: "25px",
      },
    button: {
        backgroundColor: "#fb7813 !important",
      },
      actionText: {
        textDecoration: "underline",
        cursor: "pointer",
        '&:hover': {
          color: "red",
        },
      },
    yearText: {
        fontSize: "20px",
        marginRight: "16px"
    }
});

const PayRoll = (props) => {
  const { classes } = props;
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  useEffect(() => {
    getYearsList();
    props.headerTitle("PayRoll management", 12, true);
  }, []);

  useEffect(() => {
    props.loader(true);
    getMonthList();
  }, [currentYear]);

  const getMonthList = async () => {
    let body = {
      currentYear: currentYear,
    };
    const listYear = await fetch(
      Const.API_ROOT + Const.GET_SALARY_SHEET,
      {
        method: 'POST',
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
      }
    );
    const responseJson = await listYear.json();
    setMonths(responseJson);
    props.loader(false);
  };
  const getYearsList = async () => {
    const listYear = await fetch(Const.API_ROOT + Const.GET_UPLOADED_SALARY_YEAR,{method: 'POST',headers: Const.API_HEADER});
    const responseJson = await listYear.json();
    if(responseJson.length !== 0){
      let arrayObj = responseJson;
      if(arrayObj.findIndex(x => x.years ===  moment().year()) === -1) arrayObj.push({'years' : moment().year()})
      setYears(arrayObj)
    }
    else setYears([{'years' : moment().year()}])
  };

  const addLiveNewsUpdates =(date)=>{props.history.push({
    pathname: "/dashboard/AddPayRollManagement",
    search: "",
    state: { currentYear: currentYear },
  });
}
  const getYearDropList = () => {
    return years && years.map((year, i) => {
      return (
        <MenuItem key={i} value={year.years}>
          {year.years}
        </MenuItem>
      );
    });
  };

  const editSalerysheet =(date)=>{props.history.push({
      pathname: "/dashboard/EditPayRollManagement",
      search: "",
      state: { date: date },
    });
  }

  const deleteSalerysheet=async(date)=>{
    props.loader(true);
    let body = {
      year:moment(date).year(),
      month:moment(date).format("M")
    };
    await fetch(
      Const.API_ROOT + Const.DELETE_SALARY_SHEET,
      {
        method: 'POST',
        headers: Const.API_HEADER,
        body: JSON.stringify(body),
      }
    );
    getMonthList()
  }
  return (
    <div>
    <div>
      <Paper className={classes.root} style={{marginBottom: "30px"}}>
        <Paper className={classes.filter_paper}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px"}}>
                    
            <div style={{display: "flex", alignItems: "center"}}>
                <p className={classes.yearText}>Year</p>
                <Select
                    variant="outlined"
                    displayEmpty={true}
                    width="fullWidth"
                    value={currentYear}
                    onChange={(e) =>{
                      setCurrentYear( e.target.value)}}
                    style={{width: "180px"}}>
                      {getYearDropList()}
                </Select>
            </div>
            <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => addLiveNewsUpdates()}
                >
                  <i className={`${classes.leftIcon} material-icons `} style={{marginRight: "10px"}}>
                    newspaper
                  </i>{" "}
                  Add Salary
                </Button>
          </div>
            </Paper>
      </Paper>
      <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      Salary Month
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {months && months.map((data, i) =>
                    <TableRow key={i}>
                    <TableCell component="th" scope="row">
                        {moment(data.x).format("MMMM")}
                    </TableCell>
                    <TableCell align="center">
                        <div className="{classes.actions}">
                            <a className={classes.actionText} style={{marginRight: "20px"}} onClick={() => editSalerysheet(data.x)}>Edit</a>
                            <a className={classes.actionText} onClick={(e) => deleteSalerysheet(data.x)}>Delete</a>
                        </div>
                    </TableCell>
                    </TableRow>
                    )}
                </TableBody>
              </Table>
    </div>
  </div>
  );
};
export default withStyles(styles)(PayRoll);
