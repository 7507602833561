import React, { useState, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import constant from "../common/constant";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { DateRangePicker } from "react-dates";
import { DownloadTableExcel } from "react-export-table-to-excel";
import CryptoJS from "crypto-js";
import moment from "moment";
const styles = () => ({
  root: {
    width: "100%",
    padding: 25,
  },
  filter_paper: {
    width: "100%",
    padding: 15,
  },
  table: {
    minWidth: 700,
  },
  tableCell: {
    width: "20%",
  },
  paper: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  heading: {
    marginTop: 50,
  },
  paper1: {
    marginBottom: 20,
    padding: 20,
    textAlign: "right",
  },
  top: {
    textAlign: "right",
  },
  button: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
    marginTop: "3px",
  },
  markbutton: {
    backgroundColor: "#fb7813 !important",
  },
  downloadButton: {
    margin: 5,
    backgroundColor: "#fb7813 !important",
    marginTop: "20px",
    textAlign: "right",
  },
  select: {
    width: "80%",
  },
  paper2: {
    paddingTop: "15px",
  },
});

const Attendance = (props) => {
  const tableRef = useRef(null);
  const { classes } = props;
  const [allAttendance, setallAttendance] = useState([]);
  const [loggedInUserAttendance, setloggedInUserAttendance] = useState([]);
  const [allEmployees, setallEmployees] = useState([]);
  const [selectedEmployess, setselectedEmployess] = useState([]);
  const [focusInput, setfocusInput] = useState("");
  const [startDate, setstartDate] = useState(moment(new Date()));
  const [endDate, setendDate] = useState(moment(new Date()));
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    props.headerTitle("Attendance", 8, true);
    getAttendance();
  }, []);

  const getAttendance = async () => {
    let arrayOfEmpcode = [];
    let Empcode = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Item"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8)
    ).EmpCode;
    arrayOfEmpcode.push(Empcode);
    let Param = CryptoJS.AES.encrypt(
      JSON.stringify({
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        empCode:
          JSON.parse(
            CryptoJS.AES.decrypt(
              localStorage.getItem("Item"),
              process.env.REACT_APP_SECRET_KEY
            ).toString(CryptoJS.enc.Utf8)
          ).RoleId === 1
            ? selectedEmployess
                .filter((e) => e.EmpCode)
                .map((item) => {
                  return item.EmpCode;
                })
            : arrayOfEmpcode,
        orgID: JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("Item"),
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8)
        ).OrganizationID,
      }),
      process.env.REACT_APP_SECRET_KEY
    ).toString();
    const body = { Param: Param };
    const getFilteredAttendance = await fetch(
      constant.API_ROOT + constant.GET_FILTERED_ATTENDANCE,
      {
        method: "POST",
        headers: constant.API_HEADER,
        body: JSON.stringify(body),
      }
    );
    const filterAttendance = await getFilteredAttendance.json();
    if (filterAttendance) {
      const loggedInUserAttendance = filterAttendance.recordsets
        .flat()
        .filter(
          (e) =>
            e.EmpCode ===
            JSON.parse(
              CryptoJS.AES.decrypt(
                localStorage.getItem("Item"),
                process.env.REACT_APP_SECRET_KEY
              ).toString(CryptoJS.enc.Utf8)
            ).EmpCode
        );
      setallAttendance(filterAttendance.recordsets.flat());
      setloggedInUserAttendance(loggedInUserAttendance);
    }
  };

  const handleInputChange = async (event, value) => {
    if (value.length === 0) {
      if (open) setOpen(false);
    } else {
      if (!open) setOpen(true);
    }
    if (value.length !== 0) {
      props.loader(true);
      const body = {
        OrganizationID: JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("Item"),
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8)
        ).OrganizationID,
      };
      fetch(
        `${constant.API_ROOT}` +
          `${constant.GET_AUTOCOMPLETE_USERS}?query=${value}`,
        {
          method: "POST",
          headers: constant.API_HEADER,
          body: JSON.stringify(body),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          let decryptData = CryptoJS.AES.decrypt(
            response,
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8);
          const parseData = JSON.parse(decryptData);
          let filteredEmployees = parseData.employees.filter((emp) => {
            return emp.Name;
          });
          props.loader(false);
          setallEmployees(filteredEmployees);
        })
        .catch((error) => {
          props.loader(false);
          console.error(error);
        });
    }
  };
  const checkAttendance = () => {
    props.history.push(`/dashboard/markAttendance`);
  };

  const datePicker = (propStartDate, propEndDate) => {
    return (
      <DateRangePicker
        startDate={propStartDate}
        startDateId="your_unique_start_date_id"
        endDate={propEndDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          if (startDate === null) {
            startDate = moment().startOf("year");
          }
          if (endDate === null) {
            endDate = moment().endOf("year");
          }
          setstartDate(startDate);
          setendDate(endDate);
        }}
        focusedInput={focusInput}
        onFocusChange={onFocusChangeRangeHandler}
        isOutsideRange={() => false}
      />
    );
  };
  const onFocusChangeRangeHandler = (focusInput) => {
    setfocusInput(focusInput);
  };
  const filterOptions = () =>
    createFilterOptions({
      matchFrom: "start",
      stringify: (option) => option.Name,
    });

  return (
    <div>
      <div>
        <div>
          <Paper className={classes.root}>
            {JSON.parse(
              CryptoJS.AES.decrypt(
                localStorage.getItem("Item"),
                process.env.REACT_APP_SECRET_KEY
              ).toString(CryptoJS.enc.Utf8)
            ).RoleId === 1 ? (
              <Grid item xs={12} className={classes.top}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.markbutton}
                  onClick={(e) => checkAttendance(e)}
                >
                  Mark Attendance
                </Button>
              </Grid>
            ) : (
              ""
            )}
            <Paper className={classes.filter_paper}>
              <Grid container className={classes.heading}>
                {JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).RoleId === 1 ? (
                  <Grid item xs={6} md={4}>
                    <Typography>Employee Name</Typography>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={6} md={4}>
                  <Typography>Date</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={12}>
                {JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).RoleId === 1 ? (
                  <Grid item xs={4}>
                    <Autocomplete
                      filterOptions={filterOptions()}
                      className={classes.select}
                      open={open}
                      disabled={false}
                      multiple
                      value={selectedEmployess}
                      options={allEmployees}
                      onInputChange={handleInputChange}
                      onClose={() => setOpen(false)}
                      getOptionLabel={(option) => option.Name}
                      onChange={(event, newValue) => {
                        setselectedEmployess(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select Employees"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={4}>
                  {datePicker(startDate, endDate)}
                </Grid>
                <Grid item xs={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => getAttendance()}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Paper>

            <Grid container spacing={12} className={classes.top}>
              <Grid item xs={12}>
                {JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).RoleId === 1 ? (
                  <DownloadTableExcel
                    filename={`Attendance - ${startDate.format(
                      "DD-MMM"
                    )} - ${endDate.format("DD-MMM-YYYY")}`}
                    sheet={`Attendance - ${startDate.format(
                      "DD-MMM"
                    )} - ${endDate.format("DD-MMM-YYYY")}`}
                    currentTableRef={tableRef.current}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.downloadButton}
                    >
                      Download Excel
                    </Button>
                  </DownloadTableExcel>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <Paper className={classes.root}>
              <Table className={classes.table} ref={tableRef}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="center">
                      Name
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Date
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Day
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      In Time
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Out Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                {JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).RoleId === 1 ? (
                  <TableBody>
                    {allAttendance.length !== 0
                      ? allAttendance
                          .filter(
                            (item) =>
                              moment(item.InTime).format("YYYY-MM-DD") >=
                                startDate.format("YYYY-MM-DD") &&
                              moment(item.InTime).format("YYYY-MM-DD") <=
                                endDate.format("YYYY-MM-DD")
                          )
                          .map((data) => {
                            return (
                              <TableRow>
                                <TableCell align="center">
                                  {data.EmpName}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(data.InTime).format("DD-MM-YYYY")}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(data.InTime.replace("Z", "")).format(
                                    "dddd"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(data.InTime).format("LT")}
                                </TableCell>
                                <TableCell align="center">
                                  {data.OutTime !== null
                                    ? moment(data.OutTime).format("LT")
                                    : ""}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      : ""}
                  </TableBody>
                ) : (
                  ""
                )}
                {JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem("Item"),
                    process.env.REACT_APP_SECRET_KEY
                  ).toString(CryptoJS.enc.Utf8)
                ).RoleId === 2 ? (
                  <TableBody>
                    {loggedInUserAttendance.length !== 0
                      ? loggedInUserAttendance
                          .filter(
                            (item) =>
                              moment(item.InTime).format("YYYY-MM-DD") >=
                                startDate.format("YYYY-MM-DD") &&
                              moment(item.InTime).format("YYYY-MM-DD") <=
                                endDate.format("YYYY-MM-DD")
                          )
                          .map((data) => {
                            return (
                              <TableRow>
                                <TableCell align="center">
                                  {data.EmpName}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(data.InTime).format("DD-MM-YYYY")}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(data.InTime.replace("Z", "")).format(
                                    "dddd"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(data.InTime).format("LT")}
                                </TableCell>
                                <TableCell align="center">
                                  {data.OutTime !== null
                                    ? moment(data.OutTime).format("LT")
                                    : ""}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      : ""}
                  </TableBody>
                ) : (
                  ""
                )}
              </Table>
            </Paper>
          </Paper>
        </div>
      </div>
    </div>
  );
};
export default withStyles(styles)(Attendance);
