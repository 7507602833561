import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoutes = ({
  Cmp: Cmp,
  RoleId: RoleId,
  exact,
  path,
  protectedRoute,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    render={(props) =>
      !protectedRoute ? (
        <Cmp {...props} {...rest} />
      ) : RoleId === 1 ? (
        <Cmp {...props} {...rest} />
      ) : (
        <Redirect to="/dashboard" />
      )
    }
  />
);

export default ProtectedRoutes;
