import React, { Component } from "react";
import Employees from "../add-employee/index";
import CryptoJS from "crypto-js";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EmployeeId: "",
    };
  }
  componentWillMount() {
    this.props.headerTitle("My Profile", 2, false);
    this.setState({
      EmployeeId: JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("Item"),
          process.env.REACT_APP_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      ).EmployeeId,
    });
  }

  render() {
    return (
      <div>
        <Employees
          EmployeeId={this.state.EmployeeId}
          loader={this.props.loader.bind(this)}
          headerTitle={this.props.headerTitle.bind(this)}
          {...this.props}
        />
      </div>
    );
  }
}
export default Profile;
